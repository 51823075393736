import cx from 'classnames';

interface GaugeContainerProps extends React.HTMLAttributes<HTMLDivElement> {}

export function GaugeContainer({ className, ...rest }: GaugeContainerProps) {
  return (
    <div
      className={cx(
        className,
        'tw-surface-elevated',
        'tw-shadow-30',
        'tw-rounded-10',
        'tw-button-44',
        'tw-px-4',
      )}
      {...rest}
    />
  );
}
