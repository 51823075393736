import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { mapPaginated } from '@sb/feathers-types';
import type { Routine, Unsubscribe } from '@sb/remote-control/types';
import { convertEquipmentResponse } from '@sb/remote-control/util/convertEquipmentResponse';
import { convertRoutineResponse } from '@sb/remote-control/util/convertRoutineResponse';

import { getEquipmentService } from '../equipment/utils';

import { getRoutinesService } from './utils';

export function onGetRoutine(
  routineID: string,
  onSnapshot: (routine: Routine.ConvertedResponse | null) => void,
): Unsubscribe {
  const routine$ = getRoutinesService()
    .watch()
    .find({ query: { id: routineID, $limit: 1 } });

  const equipment$ = getEquipmentService()
    .watch()
    .find({ query: { isEnabled: true, $sort: { updatedAt: -1 } } });

  const subscription = combineLatest([routine$, equipment$])
    .pipe(
      map(([routineResponse, equipmentResponse]) => {
        const equipment = mapPaginated(
          equipmentResponse,
          convertEquipmentResponse,
        );

        const routine = mapPaginated(
          routineResponse,
          convertRoutineResponse,
        )[0];

        if (!routine) {
          return null;
        }

        return {
          ...routine,
          equipment: equipment.map((e) => e.config),
        };
      }),
    )
    .subscribe(onSnapshot);

  return () => subscription.unsubscribe();
}
