import * as zod from 'zod';

export const TCPOffsetOption = zod.union([
  zod.literal('wrist'),
  zod.literal('wristCamera'),
  zod.number().int(), // end effector tcp options are the positive integers
]);

export type TCPOffsetOption = zod.infer<typeof TCPOffsetOption>;

export const DEFAULT_TCP_OFFSET_OPTION: TCPOffsetOption = 'wrist';
