import type { Step, Routine } from '@sb/remote-control/types';
import type { Expression } from '@sb/routine-runner';

type KeysOfUnion<T> = T extends T ? keyof T : never;

export const makeJSExpressionValidator =
  <K extends Step.ConvertedConfiguration['stepKind']>(
    stepKind: K,
    fieldId: KeysOfUnion<NonNullable<Step.ConvertedConfiguration['args']>>,
    name: string,
    allowUndefined: boolean = true,
  ) =>
  (
    _step: Step.ConvertedSummary,
    stepConfiguration: Step.ConvertedConfiguration | undefined,
  ): Routine.ActionRequiredStep | undefined => {
    if (stepConfiguration?.stepKind !== stepKind) return undefined;
    const { args } = stepConfiguration;
    if (!args) return undefined;

    const value = (args as any)[fieldId] as Expression | undefined;

    if (!value) {
      return allowUndefined
        ? undefined
        : {
            kind: 'invalidConfiguration',
            message: `${name} not specified`,
            fieldId,
          };
    }

    if (value.kind !== 'JavaScript') return undefined;

    if (value.errorMessage) {
      return {
        kind: 'invalidConfiguration',
        message: `Error in ${name}: ${value.errorMessage}`,
        fieldId,
      };
    }

    return undefined;
  };
