import { map } from 'rxjs/operators';

import { asPaginated } from '@sb/feathers-types';
import type { Notification, Unsubscribe } from '@sb/remote-control/types';

import { getNotificationsService } from './utils';

export function onGetNotificationsCount(
  userID: string,
  status: Notification.Status,
  onSnapshot: (count: number) => void,
): Unsubscribe {
  const subscription = getNotificationsService()
    .watch()
    .find({
      query: {
        [`statusByUserID.${userID}`]: status === 'new' ? null : status,
        // we only care about the total record count, not the notification data
        // ideally we'd use `$limit: 0` but that doesn't work with `watch`
        $select: ['id'],
      },
    })
    .pipe(
      map((response) => {
        return asPaginated(response).total;
      }),
    )
    .subscribe(onSnapshot);

  return () => subscription.unsubscribe();
}
