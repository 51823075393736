import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';

export const CustomGripperState = zod.object({
  kind: zod.literal('CustomGripper'),
  tooltips: zod.array(
    zod.object({
      name: zod.string(),
      tcpTransform: CartesianPose,
    }),
  ),
});

export type CustomGripperState = zod.infer<typeof CustomGripperState>;
