import cx from 'classnames';

import type { ButtonProps } from '../button';
import { Button } from '../button';

export function NavigationBarButton({ className, ...rest }: ButtonProps) {
  return (
    <Button
      size={44}
      variant="Flat"
      className={cx('tw-pl-8', 'tw-pr-8', 'tw-icon-28', className)}
      {...rest}
    />
  );
}
