import type { Step, Equipment } from '@sb/remote-control/types';
import { NetworkRequestStep } from '@sb/remote-control/types';
import type { RoutineStepConfiguration } from '@sb/routine-runner';

import type { DeviceConfiguration } from '../device';
import { ActuateGripperStepDatabase } from '../gripper-general/steps/ActuateGripper';
import { HaasControlRegionStepDatabase } from '../HaasMill/frontend/HaasControlRegionStepDatabase';
import { HaasRunProgramStepDatabase } from '../HaasMill/frontend/HaasRunProgramStepDatabase';
import { ActuateScrewdriverStepDatabase } from '../OnRobotScrewdriver/steps/ActuateScrewdriver';
import { ActuateVacuumStepDatabase } from '../OnRobotVGP20/steps/ActuateVacuum/Database';

// TODO - the below should either be registered to intercept requests matching on
// installed equipment and step kind. This will allow us to move this logic out of the register file
type Opts = {
  step: Step.ConvertedSummary;
  stepConfiguration: Step.ConvertedConfiguration;
  routineEquipment: DeviceConfiguration[];
  robotEquipment: Equipment.ConvertedResponse[];
};

export function convertIntegrationStepConfigurationToRoutineRunnerFormat({
  stepConfiguration,
  step,
  routineEquipment: equipment,
  robotEquipment,
}: Opts): RoutineStepConfiguration | null {
  const baseStepData = {
    id: step.id,
    description: step.description,
    steps: undefined,
  };

  switch (stepConfiguration.stepKind) {
    case 'ActuateGripper': {
      return {
        ...baseStepData,
        stepKind: 'ActuateGripper',
        args: ActuateGripperStepDatabase.convertActuateGripperConfigurationToRoutineRunner(
          step.id,
          equipment,
          stepConfiguration.args,
        ),
      };
    }

    case 'ActuateScrewdriver': {
      return {
        ...baseStepData,
        stepKind: 'ActuateScrewdriver',
        args: ActuateScrewdriverStepDatabase.convertStepToRoutineRunnerArgs(
          stepConfiguration.args,
          step.id,
        ),
      };
    }

    case 'ActuateVacuum': {
      return {
        ...baseStepData,
        stepKind: 'ActuateVacuum',
        args: ActuateVacuumStepDatabase.convertStepToRoutineRunnerArgs(
          stepConfiguration.args,
          step.id,
        ),
      };
    }

    case 'HaasRunProgram': {
      return {
        ...baseStepData,
        stepKind: 'HaasRunProgram',
        args: HaasRunProgramStepDatabase.convertStepToRoutineRunnerArgs(
          stepConfiguration.args,
          step.id,
        ),
      };
    }

    case 'HaasControlRegion': {
      return {
        ...baseStepData,
        stepKind: 'HaasControlRegion',
        args: HaasControlRegionStepDatabase.convertStepToRoutineRunnerArgs(
          stepConfiguration.args,
          step.id,
        ),
      };
    }

    case 'NetworkRequest': {
      return {
        ...baseStepData,
        stepKind: 'NetworkRequest',
        args: NetworkRequestStep.convertStepToRoutineRunnerArgs(
          stepConfiguration.args,
          step.id,
          robotEquipment,
        ),
      };
    }

    default:
      return null;
  }
}
