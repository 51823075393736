import type { HostCommandData } from '@sb/feathers-types';

import { getService } from '../utils';

const SERVICE_NAME = 'hostCommand';

export async function executeHostCommand(data: HostCommandData): Promise<void> {
  const hostCommandService = getService(SERVICE_NAME)();

  await hostCommandService.create(data);
}
