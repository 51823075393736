import * as zod from 'zod';

import { namespace, warn } from '@sb/log';

const ns = namespace('buildconfig');

export const ActiveBuildData = zod.object({
  buildId: zod.string(),
  name: zod.string(),
});

export type ActiveBuildData = zod.infer<typeof ActiveBuildData>;

export type TargetBuildStatus =
  | 'pending'
  | 'downloading'
  | 'downloaded'
  | 'installing'
  | 'installed';

export const TargetBuildStatus = zod.enum([
  'pending',
  'downloading',
  'downloaded',
  'installing',
  'installed',
]);

const DateSchema = zod.preprocess(
  (arg) => (typeof arg === 'string' ? new Date(arg) : arg),
  zod.date(),
);

export const TargetFirmwareInfo = zod.preprocess(
  (arg) => {
    if (arg && typeof arg === 'object' && !Array.isArray(arg)) {
      // Previously, this data was a map from joint number to binary info.
      // For backward compatibility, we'll attempt to parse the old format.
      // We can remove this handling in the future, so long as we ensure that no
      // robot's target_build.json has the old format in the firmwareInfo field.
      const result = Object.entries(arg).map(([jointNumber, binaryInfo]) => ({
        jointNumber: parseInt(jointNumber, 10),
        binaryInfo,
      }));

      warn(
        ns`parse.oldformat`,
        'Parsed old firmwareInfo format in target_build.json',
      );

      return result;
    }

    return arg;
  },
  zod.array(
    zod.object({
      jointNumber: zod.number(),
      binaryInfo: zod.object({
        checksum: zod.number(),
        size: zod.number(),
      }),
    }),
  ),
);

export type TargetFirmwareInfo = zod.infer<typeof TargetFirmwareInfo>;

export const TargetBuildData = zod.object({
  buildId: zod.string(),
  name: zod.string(),
  imagesToPull: zod.array(
    zod.object({
      name: zod.string(),
      armUrl: zod.string(),
      x86Url: zod.string(),
    }),
  ),
  sbCliNpmVersion: zod.string().optional(),
  strategy: zod.enum([
    'EMERGENCY_IMMEDIATE_SKIP_STOPPING',
    'ON_CLIENT_REQUEST',
  ]),
  status: TargetBuildStatus,
  firmwareInfo: TargetFirmwareInfo.optional(),
  lastErrorAt: DateSchema.optional(),
  lastErrorMessage: zod.string().optional(),
  updatedAt: DateSchema.default(new Date()),
});

export type TargetBuildData = zod.infer<typeof TargetBuildData>;
