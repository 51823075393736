import { clamp } from 'lodash';

import type { UseIntervalOptions } from '../../utility';

export function calculateFraction(
  value: number,
  min: number,
  max: number,
): number {
  return (value - min) / (max - min);
}

export function calculateClampedPercent(
  value: number,
  min: number,
  max: number,
): number {
  const percent = calculateFraction(value, min, max) * 100;

  return clamp(percent, 0, 100);
}

export function calculateClampedRoundedValue(
  value: number,
  min: number,
  max: number,
  step: number,
) {
  let roundedValue: number;

  if (value <= min || value >= max) {
    // avoid rounding at & beyond the limits
    roundedValue = value;
  } else if (step > 1 || step < -1) {
    roundedValue = Math.round(value / step) * step;
  } else if (step === 1 || step === -1) {
    roundedValue = Math.round(value);
  } else {
    // this is more accurate for fractional steps
    // (dividing by 1000 is more accurate than multiplying by 0.001)
    const inverseStep = 1 / step;
    roundedValue = Math.round(value * inverseStep) / inverseStep;
  }

  return clamp(roundedValue, min, max);
}

export const INTERVAL_OPTIONS: UseIntervalOptions = {
  intervalMs: 50,
  delayMs: 250,
  leading: true,
};
