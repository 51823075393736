import { identity } from 'lodash';

import { makeNamespacedLog } from '@sb/log';
import type { RoutineRunnerState, RoutineRunning } from '@sb/routine-runner';
import { RoutineRunnerPacketSender, Routine } from '@sb/routine-runner';

import type { ConnectionStatus } from './ConnectionStatus';

const log = makeNamespacedLog('RoutineRunnerHandle');

const NULL_STATE = Symbol('Null State');

let sbDevToolsMounted = false;

// Events that we want to collect for if/when SB DevTools mounts.
let collectedSBDevToolsEvents: Array<Event> = [];

if (typeof window !== 'undefined') {
  const collectSBDevToolsEvent = (event: Event) => {
    collectedSBDevToolsEvents.push(event);
  };

  // collect events until SB DevTools mounts
  document.body.addEventListener('sbDevToolsMessage', collectSBDevToolsEvent);

  document.body.addEventListener('sbDevToolsMount', () => {
    sbDevToolsMounted = true;

    document.body.removeEventListener(
      'sbDevToolsMessage',
      collectSBDevToolsEvent,
    );

    collectedSBDevToolsEvents.forEach((event) => {
      document.body.dispatchEvent(event);
    });

    collectedSBDevToolsEvents = [];
  });

  // dispatch a load event to prompt SB Dev Tools to emit another sbDevToolsMount
  // if it's already mounted.
  //
  // This is basically necessary because `sbDevToolsMounted` gets reset to
  // false on hot reload when already connected but otherwise nothing changes,
  // so this will get it set `sbDevToolsMounted` back to true in that case.
  document.body.dispatchEvent(new CustomEvent('RoutineRunnerHandleModuleLoad'));
}

/**
 * Base class for [[WebSocketRoutineRunnerHandle]]
 *
 * Mostly specializes the [[RoutineRunnerPacketSender]]
 *
 */
export abstract class RoutineRunnerHandle extends RoutineRunnerPacketSender {
  public referenceCounter: number = 0;

  public constructor() {
    super();

    this.onOpen(() => {
      log.debug(`lifecycle.open`, 'open');
    });

    this.onClose(() => {
      log.debug(`lifecycle.close`, 'close');
    });

    this.handleSBDevToolsEvents();
  }

  private handleSBDevToolsEvents() {
    if (typeof window === 'undefined') {
      return;
    }

    this.emitSBDevToolsEvent({ kind: 'mount', reactive: false });

    document.body.addEventListener('sbDevToolsMount', () => {
      this.emitSBDevToolsEvent({ kind: 'mount', reactive: true });
    });

    this.packets.on('receive', (packet) => {
      this.emitSBDevToolsEvent({
        kind: 'incomingPacket',
        packet: Array.from(packet),
      });
    });
  }

  protected async emitSBDevToolsEvent(details: {
    kind: string;
    collect?: boolean;
    [key: string]: any;
  }) {
    if (!sbDevToolsMounted && !details.collect) {
      return;
    }

    const commonDetails = await this.getSBDevToolsDetails();

    const event = new CustomEvent('sbDevToolsMessage', {
      detail: {
        ...commonDetails,
        ...details,
      },
    });

    document.body.dispatchEvent(event);
  }

  protected abstract getName(): Promise<string>;

  private async getSBDevToolsDetails(): Promise<object> {
    return {
      name: await this.getName(),
    };
  }

  /**
   * Implementations of [[RoutineRunnerHandle]] should keep track of connection
   * state (if relevant) and return this
   */
  public abstract getConnectionStatus(): ConnectionStatus;

  /**
   * Implementations of [[RoutineRunnerHandle]] should call `cb` whenever
   * the connection status changes.
   *
   * @returns a cancelation function
   */
  public abstract onConnectionChange(
    cb: (newStatus: ConnectionStatus) => void,
  ): () => void;

  public onOpen(cb: () => void): () => void {
    return this.lifecycle.on('startPacketHandling', cb);
  }

  public onClose(cb: () => void): () => void {
    return this.lifecycle.on('stopPacketHandling', cb);
  }

  /**
   * All changes that warrant a re-render in components that require this component
   *
   * @return a cancelation function
   */
  public onChange(cb: () => void): () => void {
    const cancelers: Array<() => void> = [];
    // this event triggers when we successfully connect
    cancelers.push(this.onOpen(cb));
    // this event triggers when we disconnect
    cancelers.push(this.onClose(cb));

    return () => {
      cancelers.forEach((canceler) => canceler());
    };
  }

  /**
   * Invokes callback whenever state data changes.
   * @returns A function that deregisters the listener
   */
  public onStateChange<TData>(
    selector: (state: RoutineRunnerState) => TData,
    isEqual: (dataA: TData, dataB: TData) => boolean,
    onChange: (data: TData) => void,
  ): () => void {
    const initialState = this.getState();
    let lastData = initialState ? selector(initialState) : NULL_STATE;
    let isSubscribed = true;

    /**
     * If the state doesn't change after we call `onStateChange`, then
     * this callback would never fire keeping the state `null`.
     *
     * This ensures that we always emit the initial state when
     * `onStateChange` is called.
     */
    if (lastData !== NULL_STATE && isSubscribed) {
      onChange(lastData);
    }

    // When a new state message comes in, check if it's equivalent to the last seen state.
    // If it is, don't update cb. If it is new, call `cb`
    const stopListeningToStateMessages = this.messages.on(
      'state',
      (newState: RoutineRunnerState) => {
        try {
          const newData = selector(newState);

          if (lastData === NULL_STATE || !isEqual(lastData, newData)) {
            lastData = newData;

            if (isSubscribed) {
              onChange(newData);
            }
          }
        } catch (e) {
          log.error(
            `stopListeningToStateMessages.error`,
            'Error stopping listening to state messages',
            { error: e },
          );
        }
      },
    );

    /**
     * When we disconnect and reconnect, we want to call `onStateChange` even if the
     * state has not changed since before disconnection.
     *
     * Therefore, when we disconnected, invalidate `lastState` so the next state
     * update will always appear new and trigger a new call to `cb`
     */
    const stopListeningToInvalidateState = this.lifecycle.on(
      'stopPacketHandling',
      () => {
        log.debug(
          `stopPacketHandling`,
          'Disconnected so invalidating last state change',
        );

        lastData = NULL_STATE;
      },
    );

    return () => {
      isSubscribed = false;
      stopListeningToStateMessages();
      stopListeningToInvalidateState();
    };
  }

  /**
   * Wrapper around loadRoutine to provide more helpful error messages.
   */
  public async loadRoutineFormatted(
    submitted: Routine,
    startConditions?: Pick<RoutineRunning, 'currentStepID' | 'variables'>,
  ): Promise<void> {
    const routine = Routine.parse(submitted);
    const { errors } = await this.loadRoutine(routine, startConditions);

    if (errors.length > 0) {
      log.error(
        `loadRoutine.validation`,
        `${errors.length} validation errors`,
        { errors },
      );

      throw new Error(`${errors.length} validation errors`);
    }
  }

  /**
   * Wait up to `waitMs` milliseconds for state to be `predicate`
   * @returns true if predicate true, false if timed out
   */
  public async waitForState(
    predicate: (state: RoutineRunnerState) => boolean,
    waitMs: number,
  ): Promise<RoutineRunnerState | null> {
    let stateChangeTimeoutId: any = null;

    let unsubscribeFromStateChange = () => {};

    const result = await new Promise<RoutineRunnerState | null>((resolve) => {
      unsubscribeFromStateChange = this.onStateChange<RoutineRunnerState>(
        identity,
        Object.is,
        (state: RoutineRunnerState) => {
          if (predicate(state)) {
            resolve(state);
          }
        },
      );

      stateChangeTimeoutId = setTimeout(() => resolve(null), waitMs);
    });

    unsubscribeFromStateChange();
    clearTimeout(stateChangeTimeoutId);

    return result;
  }
}
