import cx from 'classnames';

import { Icon } from '../../icons';
import type { IconProps } from '../../icons';

export function NavigationListIcon({ className, ...rest }: IconProps) {
  return (
    <div
      className={cx(
        className,
        'tw-rounded-6',
        'tw-w-28',
        'tw-h-28',
        'tw-flex',
        'tw-items-center',
        'tw-justify-center',
        // default color: `:where()` makes low specificity so can be overridden easily
        '[:where(&)]:tw-text-light',
        '[:where(&)]:tw-bg-dark',
        'group-aria-disabled:tw-opacity-40',
        'tw-icon-22',
      )}
    >
      <Icon {...rest} />
    </div>
  );
}
