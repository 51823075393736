import { useCallback } from 'react';

import { useRoutineRunnerHandle } from './useRoutineRunnerHandle';

/**
 * Stop Vizbot or live robot and display a user feedback:
 * i.e. a toast with progress, success, and error messages.
 */
export const useROSControl = () => {
  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot: false });

  return useCallback(
    (enabled: boolean) => {
      routineRunnerHandle.setROSControlEnabled(enabled);
    },
    [routineRunnerHandle],
  );
};
