import * as zod from 'zod';

import type { StepSummary } from '@sb/feathers-types';
import { ActuateGripperStepDatabase } from '@sb/integrations/gripper-general/steps/ActuateGripper/Database';
import { HaasControlRegionStepDatabase } from '@sb/integrations/HaasMill/frontend/HaasControlRegionStepDatabase';
import { HaasRunProgramStepDatabase } from '@sb/integrations/HaasMill/frontend/HaasRunProgramStepDatabase';
import { ActuateScrewdriverStepDatabase } from '@sb/integrations/OnRobotScrewdriver/steps/ActuateScrewdriver/Database';
import { ActuateVacuumStepDatabase } from '@sb/integrations/OnRobotVGP20/steps/ActuateVacuum/Database';
import type { StepKind } from '@sb/routine-runner';

import { AddOffsetStep } from './addOffset';
import { AnticipatePayloadStep } from './anticipatePayload';
import { ClassifyStep } from './classify';
import { IfStep } from './if';
import { LocateStep } from './locate';
import { LoopStep } from './loop';
import { LoopControlStep } from './loopControl';
import { MoveArmToTargetStep } from './moveArmTo';
import { NetworkRequestStep } from './networkRequest';
import { PressButtonStep } from './pressButton';
import { RunInBackgroundStep } from './runInBackground';
import { RunSkillStep } from './runSkill';
import { SetEnvironmentVariableStep } from './setEnvironmentVariable';
import { SetIOStep } from './setIO';
import { WaitStep } from './wait';
import { WaitForConfirmationStep } from './waitForConfirmation';

export namespace Step {
  /**
   * Supported stepKinds for routines. These are the stepKinds
   * currently available in our StepLibrary.
   */
  export type Kind = StepKind;

  /**
   * Supported 'args' by the routine runner schema.
   */
  export type Arguments = ConfigurationByKind['args'] & {};

  export interface Configuration {
    args?: Arguments;
    description?: string;
  }

  /**
   * Makes sure each 'stepKind' has the right configuration.
   * For example, "If" steps receive different arguments than
   * "Loop" steps.
   */
  export const ConfigurationByKind = zod.discriminatedUnion('stepKind', [
    zod.object({
      args: ActuateScrewdriverStepDatabase.Arguments.optional(),
      stepKind: zod.literal('ActuateScrewdriver'),
    }),
    zod.object({
      args: ActuateGripperStepDatabase.Arguments.optional(),
      stepKind: zod.literal('ActuateGripper'),
    }),
    zod.object({
      args: ActuateVacuumStepDatabase.Arguments.optional(),
      stepKind: zod.literal('ActuateVacuum'),
    }),
    zod.object({
      args: AnticipatePayloadStep.Arguments.optional(),
      stepKind: zod.literal('AnticipatePayload'),
    }),
    zod.object({
      args: AddOffsetStep.Arguments.optional(),
      stepKind: zod.literal('AddOffset'),
    }),
    zod.object({
      args: ClassifyStep.Arguments.optional(),
      stepKind: zod.literal('Classify'),
    }),
    zod.object({
      args: LocateStep.Arguments.optional(),
      stepKind: zod.literal('Locate'),
    }),
    zod.object({
      args: HaasControlRegionStepDatabase.Arguments.optional(),
      stepKind: zod.literal('HaasControlRegion'),
    }),
    zod.object({
      args: HaasRunProgramStepDatabase.Arguments.optional(),
      stepKind: zod.literal('HaasRunProgram'),
    }),
    zod.object({
      args: IfStep.Arguments.optional(),
      stepKind: zod.literal('If'),
    }),
    zod.object({
      args: LoopStep.Arguments.optional(),
      stepKind: zod.literal('Loop'),
    }),
    zod.object({
      args: LoopControlStep.Arguments.optional(),
      stepKind: zod.literal('LoopControl'),
    }),
    zod.object({
      args: MoveArmToTargetStep.Arguments.optional(),
      stepKind: zod.literal('MoveArmTo'),
    }),
    zod.object({
      args: NetworkRequestStep.Arguments.optional(),
      stepKind: zod.literal('NetworkRequest'),
    }),
    zod.object({
      args: PressButtonStep.Arguments.optional(),
      stepKind: zod.literal('PressButton'),
    }),
    zod.object({
      args: RunInBackgroundStep.Arguments.optional(),
      stepKind: zod.literal('RunInBackground'),
    }),
    zod.object({
      args: RunSkillStep.Arguments.optional(),
      stepKind: zod.literal('RunSkill'),
    }),
    zod.object({
      args: SetEnvironmentVariableStep.Arguments.optional(),
      stepKind: zod.literal('SetEnvironmentVariable'),
    }),
    zod.object({
      args: SetIOStep.Arguments.optional(),
      stepKind: zod.literal('SetIO'),
    }),
    zod.object({
      args: WaitStep.Arguments.optional(),
      stepKind: zod.literal('Wait'),
    }),
    zod.object({
      args: WaitForConfirmationStep.Arguments.optional(),
      stepKind: zod.literal('WaitForConfirmation'),
    }),
  ]);

  export type ConfigurationByKind = zod.infer<typeof ConfigurationByKind>;

  /** Add custom fields to the step configuration. */
  export type ConvertedConfiguration = ConfigurationByKind & {
    id: string;
    description?: string;
    name: string;
    routineID: string;
    stepKind: Kind;
  };

  /**
   * Contains all fields required for displaying a list of steps
   * in the frontend. It doesn't include robot-specific
   * configuration. This summary is used for storing steps in the `/routines`
   * collection. It's mainly used for the reorder (drag-and-drop) feature.
   */
  export type Summary = StepSummary;

  export type ParentStep = Pick<Summary, 'id' | 'stepKind'> | null;

  /**
   * While "Summary" is the interface used in the database,
   * "ConvertedSummary" contains the format required in the frontend.
   */
  export interface ConvertedSummary extends Omit<Summary, 'steps'> {
    /**
     * When copying a step, this is set to source the config for the new step
     */
    copiedFromStepID?: string;

    name: string;

    /**
     * Some steps require a specific stepKind for the parent step.
     * For example, 'LoopControl' should be inside a 'Loop' step.
     *
     * By keeping track of the parent step when converting a step
     * summary, we can easily validate a step:
     *
     * ```
     * if (step.stepKind === 'LoopControl' && step.parentStep.stepKind !== 'Loop') {
     *   throw new Error('Restart Loop is currently not defined inside a Loop Step');
     * }
     * ```
     */
    parentSteps: ParentStep[];

    /**
     * In some places of the frontend, we need the position of
     * a step in the entire tree. For example:
     *
     * 1. Loop
     *    2. Nested loop step
     *    3. Another nested loop step
     * 4. Another loop step
     *    5. Nested loop step
     *       6. Nested in one more level
     *    7. Another nested step
     */
    stepPosition: number;
    steps: ConvertedSummary[];
  }

  /**
   * Common arguments available for all steps, regardless of their stepKind.
   */
  export interface CommonArguments {
    /**
     * A stepKind can have different kinds of arguments. A "Loop" step, for example,
     * can also have the same arguments as the ones from "If" steps.
     *
     * Using an 'argumentKind' field helps us to improve typing. For example, this works:
     * if (step.argumentKind === 'Loop') console.log(step.times)
     *
     * However, this would give us a build error:
     * if (step.argumentKind === 'Loop') console.log(step.condition)
     *
     * Because 'step.condition' doesn't exist in loops that don't use conditional rules.
     * The same rule applies to conditional steps (i.e. If steps or loop
     * steps using conditional rules):
     *
     * // this works
     * if (step.argumentKind === 'If') console.log(step.condition)
     *
     * // this doesn't work
     * if (step.argumentKind === 'If') console.log(step.times)
     *
     * If we didn't have this 'argumentKind' field, then TypeScript wouldn't
     * be able to correctly identify the arguments because
     * each step can have different index signatures.
     *
     * So, these use cases would give us a build error:
     * console.log(step.times)
     * console.log(step.condition)
     *
     * Because the 'condition' field only exists for conditional steps
     * (i.e. If steps and Loop steps using conditional rules) while
     * the 'times' field only exists in certain loops, for example.
     *
     * Note that this 'argumentKind' field exists in the remote control only
     * and we ARE storing it in the database. However, this field does NOT
     * exist in the routine-runner as the routine-runner has its own dynamic
     * way of generating a schema but we're using a static approach in the
     * remote-control.
     */
    argumentKind: Kind;
  }

  export type InvalidRoutineStepKind = 'decorator' | 'loopControl';
}
