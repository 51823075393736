export enum ModbusFunctionCode {
  Read = 0x03,
  Write = 0x06,
}

export const MODBUS_ADDRESS_DEFAULT = 0x41;

export const TCP_MODBUS_TIMEOUT = 5000;

export const TCP_CONNECTION_TIMEOUT = 1000;

export const TCP_MAX_CONNECTION_RETRIES = 50;

export const TCP_POLL_INTERVAL_MS = 50;

export const TCP_MOBUS_RETRY_MS = 500;

export const defaultWriteTCPModbus = {
  targetAddress: 0,
  registerAddress: 0,
  functionCode: ModbusFunctionCode.Write,
  data: 0,
  targetBoard: 'EndEffector1' as const,
};

export const defaultReadTCPModbus = {
  targetAddress: 0,
  registerAddress: 0,
  functionCode: ModbusFunctionCode.Read,
  data: 0,
  targetBoard: 'EndEffector1',
} as const;
