import { haasRunProgramStepValidators } from '@sb/integrations/HaasMill/frontend/haasRunProgramStepValidator';
import type { Routine, Step } from '@sb/remote-control/types';
import { makeJSExpressionValidator } from '@sb/remote-control/util/expressions/validateJSExpression';

import { makeStringValidator, makeJointValidator } from './simpleValidators';
import { locateValidator } from './steps/locateValidator';
import { actuateGripperValidators } from './steps/validateActuateGripper';
import { addOffsetValidator } from './steps/validateAddOffset';
import { loopControlStepValidator } from './steps/validateLoopControl';
import { moveArmToValidations } from './steps/validateMoveArm';
import { networkRequestValidators } from './steps/validateNetworkRequest';
import { environmentVariablesValidators } from './steps/validateSetEnvironmentVariable';
import { makeConditionalValidator } from './validateConditional';
import { decoratorStepValidator } from './validateDecoratorStep';
import { stepConfiguredValidator } from './validateStepConfigured';

type StepValidator = (
  step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
  routine: Routine.ConvertedResponse,
) => Routine.ActionRequiredStep | undefined;

const validators: StepValidator[] = [
  stepConfiguredValidator,
  decoratorStepValidator,
  addOffsetValidator,
  loopControlStepValidator,
  locateValidator,
  ...haasRunProgramStepValidators,
  ...networkRequestValidators,
  ...environmentVariablesValidators,
  ...actuateGripperValidators,
  ...moveArmToValidations,
  makeJointValidator('PressButton', 'prePressTarget', 'Pre-Press Target', {
    allowUndefined: false,
  }),
  makeJointValidator('PressButton', 'pressTarget', 'Press Target', {
    allowUndefined: false,
  }),
  makeStringValidator('HaasRunProgram', 'programName', 'Program Name', {
    match: /^\d+\.nc$/,
    message: 'Program Name must contain numbers and end in .nc',
  }),
  makeStringValidator('WaitForConfirmation', 'message', 'Message'),
  makeStringValidator('Locate', 'positionListName', 'Position List', {
    message: 'Requires valid Position List',
  }),
  makeStringValidator('Classify', 'fallbackValue', 'Fallback Classification', {
    message: 'Requires valid Fallback Classification',
  }),
  makeConditionalValidator('Loop', 'condition', 'Condition'),
  makeConditionalValidator('If', 'condition', 'Condition'),
  makeConditionalValidator('Wait', 'condition', 'Condition'),
  makeJSExpressionValidator('MoveArmTo', 'expression', 'Position expression'),
  makeJSExpressionValidator('AddOffset', 'translationX', 'X Translation'),
  makeJSExpressionValidator('AddOffset', 'translationY', 'Y Translation'),
  makeJSExpressionValidator('AddOffset', 'translationZ', 'Z Translation'),
  makeJSExpressionValidator('SetEnvironmentVariable', 'newValue', 'New Value'),
];

export function getStepActionRequired(
  step: Step.ConvertedSummary,
  routine: Routine.ConvertedResponse,
): Routine.ActionRequiredStep | undefined {
  for (const stepValidator of validators) {
    const actionRequired = stepValidator(
      step,
      routine.stepConfigurations[step.id],
      routine,
    );

    if (actionRequired) {
      return actionRequired;
    }
  }

  return undefined;
}
