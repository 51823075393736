import cx from 'classnames';

import type { ComponentColor } from '../../utility';

import type { ButtonVariant } from './buttonVariant';

export const BUTTON_COLOR_CLASSES: Record<
  ButtonVariant,
  Record<ComponentColor, string>
> = {
  Filled: {
    Orange: 'tw-surface-orange',
    Red: 'tw-surface-red',
    Green: 'tw-surface-green',
    Blue: 'tw-surface-blue',
  },
  Elevated: {
    Orange: cx('tw-surface-orange', 'tw-surface-elevated'),
    Red: cx('tw-surface-red', 'tw-surface-elevated'),
    Green: cx('tw-surface-green', 'tw-surface-elevated'),
    Blue: cx('tw-surface-blue', 'tw-surface-elevated'),
  },
  Gray: {
    Orange: cx('tw-bg-fill-primary', 'tw-text-orange', 'tw-outline-orange'),
    Red: cx('tw-bg-fill-primary', 'tw-text-red', 'tw-outline-red'),
    Green: cx('tw-bg-fill-primary', 'tw-text-green', 'tw-outline-green'),
    Blue: cx('tw-bg-fill-primary', 'tw-text-blue', 'tw-outline-blue'),
  },
  Flat: {
    Orange: cx('tw-bg-transparent', 'tw-text-orange', 'tw-outline-orange'),
    Red: cx('tw-bg-transparent', 'tw-text-red', 'tw-outline-red'),
    Green: cx('tw-bg-transparent', 'tw-text-green', 'tw-outline-green'),
    Blue: cx('tw-bg-transparent', 'tw-text-blue', 'tw-outline-blue'),
  },
};

export const PULSE_COLOR_CLASSES: Record<ComponentColor, string> = {
  Orange: 'tw-bg-orange',
  Red: 'tw-bg-red',
  Green: 'tw-bg-green',
  Blue: 'tw-bg-blue',
};
