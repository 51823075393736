import {
  OR_3FG15_DEFAULT_FINGER_LENGTH,
  OR_3FG15_OFFSETS,
  OR_3FG15_TO_FINGER_OFFSET,
} from '../constants';
import type {
  OnRobot3FG15Command,
  OnRobot3FG15FingerConfiguration,
} from '../types';

/**
 * Calculates the diameter in meters of an OR3FG15 when in the state
 * described by the parameters.
 */
export function calculateOR3FG15DiameterFromFingerAngle({
  fingerConfiguration,
  angle,
  gripKind,
}: {
  fingerConfiguration: OnRobot3FG15FingerConfiguration;
  // the angle of the fingers
  angle: number;
  gripKind: OnRobot3FG15Command['gripKind'];
}): number {
  // determine offset from finger length and position
  const offset =
    fingerConfiguration.lengthInMeters -
    OR_3FG15_DEFAULT_FINGER_LENGTH +
    OR_3FG15_OFFSETS[fingerConfiguration.mountingPosition];

  // Law of Cosines for SAS triangle
  const cosAngle = Math.cos(Math.PI - angle);

  const rawDiameter =
    2 *
    Math.sqrt(
      offset ** 2 +
        OR_3FG15_TO_FINGER_OFFSET ** 2 -
        2 * offset * OR_3FG15_TO_FINGER_OFFSET * cosAngle,
    );

  if (gripKind === 'inward') {
    // Gripper hardware uses 1/10th of a mm for diameter, so round to that
    return (
      Math.round((rawDiameter - fingerConfiguration.offsetInMeters) * 10_000) /
      10_000
    );
  }

  // Gripper hardware uses 1/10th of a mm for diameter, so round to that
  return (
    Math.round((rawDiameter + fingerConfiguration.offsetInMeters) * 10_000) /
    10_000
  );
}
