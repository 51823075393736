import type { DeviceCommand, DeviceConfiguration } from './device';
import { Device } from './DeviceInterface';
import type { NetworkRequestResponseData } from './ModbusTCPServer/command';

/**
 * A Network Device is the botman-side implementation of equipment that we support.
 * It can be found in the `implementation` directory in each integration.
 */
export abstract class NetworkDevice<
  State extends object,
  Events = {},
> extends Device<State, Events> {
  public abstract sendNetworkRequest(
    command: DeviceCommand,
  ): Promise<NetworkRequestResponseData>;
  public abstract getConfig(): DeviceConfiguration;
}
