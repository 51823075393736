import { Vector3 } from 'three';

import type EquipmentInterface from '../EquipmentInterface';
import type { RoutineRunnerState } from '../RoutineRunnerState';

import { runLocate } from './runLocate';
import type { VisionInterface } from './VisionInterface';
import type {
  RunVisionMethodArgs,
  RunVisionMethodResult,
} from './VisionMethodRunnerTypes';

export class VisionMethodRunner {
  private equipment: EquipmentInterface;

  private vision: VisionInterface;

  private getState: () => RoutineRunnerState;

  public constructor(args: {
    equipment: EquipmentInterface;
    vision: VisionInterface;
    getState: () => RoutineRunnerState;
  }) {
    this.equipment = args.equipment;
    this.vision = args.vision;
    this.getState = args.getState;
  }

  private sortByScore(items: Array<{ score: number }>) {
    items.sort((a, b) => b.score - a.score);
  }

  public async run(args: RunVisionMethodArgs): Promise<RunVisionMethodResult> {
    // Eventually we'll need to get different cameras here
    const camera = this.equipment.getWristCamera();

    if (!camera) {
      throw new Error('Wrist Camera not configured in Equipment');
    }

    switch (args.method) {
      case 'classify': {
        const image = await camera.getColorFrame(args.camera);

        const results = await this.vision.classify(
          args.classes,
          image,
          args.regionOfInterest,
        );

        return { method: 'classify', results };
      }

      case 'detect2DBlobs': {
        const image = await camera.getColorFrame(args.camera);

        const results = await this.vision.detect2DBlobs(
          image,
          args.regionOfInterest,
          args.params,
        );

        this.sortByScore(results);

        return { method: 'detect2DBlobs', results };
      }

      case 'detect2DShapes': {
        const image = await camera.getColorFrame(args.camera);

        const results = await this.vision.detect2DShapes(
          image,
          args.templateImage,
          args.regionOfInterest,
          args.params,
        );

        this.sortByScore(results);

        return { method: 'detect2DShapes', results };
      }

      case 'getChessboardCorners': {
        const image = await camera.getColorFrame(args.camera);

        const results = await this.vision.getChessboardCorners(
          image,
          args.rows,
          args.cols,
        );

        return { method: 'getChessboardCorners', results };
      }

      case 'getCameraChessboardTransform': {
        const image = await camera.getColorFrame(args.camera);
        const intrinsics = await camera.getIntrinsics();

        const results = await this.vision.getCameraChessboardTransform(
          image,
          args.chessboard,
          intrinsics,
        );

        return { method: 'getCameraChessboardTransform', results };
      }

      case 'locate': {
        const [origin, plusX, plusY] = args.plane;

        const results = await runLocate({
          wristCamera: camera,
          camera: args.camera,
          regionOfInterest: args.regionOfInterest,
          method: args.locateMethod,
          plane: {
            origin: new Vector3(origin.x, origin.y, origin.z),
            plusX: new Vector3(plusX.x, plusX.y, plusX.z),
            plusY: new Vector3(plusY.x, plusY.y, plusY.z),
          },
          resultsLimit: args.resultsLimit,
          transform: args.transform,
          accuracyCalibration: args.accuracyCalibration,
          vision: this.vision,
          getState: this.getState,
        });

        return {
          method: 'locate',
          results,
        };
      }

      case 'getIntrinsics': {
        const results = await camera.getIntrinsics();

        return { method: 'getIntrinsics', results };
      }

      case 'calculateIntrinsics': {
        const results = await this.vision.calculateIntrinsics(
          args.calibration,
          args.chessboard,
        );

        return { method: 'calculateIntrinsics', results };
      }

      default:
        // @ts-expect-error [TS2339] args should be `never`
        throw new Error(`Unrecognised vision method ${args?.method}`);
    }
  }
}
