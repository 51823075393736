import { map } from 'rxjs/operators';

import { asPaginated } from '@sb/feathers-types';
import type { SafetySettings, Unsubscribe } from '@sb/remote-control/types';

import {
  convertSafetySettingsResponse,
  getSafetySettingsService,
} from './utils';

export function onGetSafetySettings(
  robotID: string,
  onSnapshot: (routine: SafetySettings.ConvertedResponse) => void,
): Unsubscribe {
  const subscription = getSafetySettingsService()
    .watch()
    .find({ query: { robotID, $limit: 1 } })
    .pipe(
      map((response) => asPaginated(response).data[0]),
      map(convertSafetySettingsResponse),
    )
    .subscribe(onSnapshot);

  return () => subscription.unsubscribe();
}
