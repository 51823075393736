import { useEffect, useState } from 'react';

import type { User } from '@sb/remote-control/types';
import type { ToastState } from '@sb/ui/components';
import { Toast } from '@sb/ui/components';
import { GlobalContext } from '@sbrc/contexts';
import { useAuthState } from '@sbrc/hooks';
import { onGetUser } from '@sbrc/services';

interface AppProviderProps {
  children: React.ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
  const { userID } = useAuthState();

  const [toast, setToast] = useState<ToastState | null>(null);
  const [settings, setSettings] = useState<User.ConvertedResponse | null>();

  const [visualizerChildren, setVisualizerChildren] = useState<
    Record<string, React.ReactNode>
  >({});

  useEffect(() => {
    setSettings(undefined);

    if (!userID) {
      return undefined;
    }

    const unsubscribe = onGetUser(userID, setSettings);

    return () => unsubscribe();
  }, [userID]);

  return (
    <GlobalContext.Provider
      value={{
        settings,
        toast: { state: toast, set: setToast },
        visualizerChildren: {
          state: visualizerChildren,
          set: setVisualizerChildren,
        },
        userID,
      }}
    >
      {children}

      <Toast
        isOpen={Boolean(toast && !toast.isClosed)}
        kind={toast?.kind}
        onClose={() => setToast(toast && { ...toast, isClosed: true })}
      >
        {toast?.message}
      </Toast>
    </GlobalContext.Provider>
  );
};

export default AppProvider;
