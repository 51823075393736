import base62Random from 'base62-random';
import { get } from 'lodash';

import {
  applyCompoundPose,
  tooltipCoordinatesToBaseCoordinates,
  type CartesianPose,
  type CartesianPosition,
} from '@sb/geometry';
import type {
  DeviceCommand,
  NetworkRequestCommand,
} from '@sb/integrations/device';
import type { NetworkRequestCommandResponse } from '@sb/integrations/ModbusTCPServer/command';
import { getCommandSuccessResponse } from '@sb/integrations/ModbusTCPServer/command/util';
import * as log from '@sb/log';
import type {
  ArmAndDeviceMotionPlan,
  ArmJointAccelerations,
  ArmJointLimits,
  ArmJointPositions,
  ArmJointTorques,
  ArmJointVelocities,
  ArmTarget,
  GripperOpenness,
  JerkLimit,
  JointNumber,
  MotionPlan,
  MotionPlannerInterface,
  MotionPlanOptions,
  MotionPlanRequest,
  MotionPlanRequestShape,
  Waypoint,
  SmoothingType,
  TCPOffsetOption,
} from '@sb/motion-planning';
import {
  ABSOLUTE_MAX_JOINT_ACCELERATIONS,
  ABSOLUTE_MAX_JOINT_SPEEDS,
  ABSOLUTE_MAX_TOOLTIP_SPEED,
  ALL_TOOL_MOTIONS_INVALID,
  ANTIGRAVITY_SPEEDS_DEFAULT,
  ArmAndDeviceMotionPlanner,
  DEFAULT_TCP_OFFSET_OPTION,
  forwardKinematics,
  getWaypointArmTargets,
  GraspArmTarget,
  JERK_LIMIT_DEFAULT,
  JOINT_NUMBERS,
  JointArmTarget,
  PoseArmTarget,
  translateArmTarget,
  usesBlendRadius,
} from '@sb/motion-planning';
import { PausedStatePositionSaver } from '@sb/routine-runner/PausedStatePositionSaver';
import { WaypointReachedTracker } from '@sb/routine-runner/WaypointReachedTracker';
import { EventEmitter, six, wait } from '@sb/utilities';

import type CameraInterface from './CameraInterface';
import {
  ACTUATE_DEVICE_RETRY_TIMEOUT_MS,
  payloadMassToMaxAccelerations,
  STILL_GOING_CHECK_INTERVAL_MS,
  VALID_TOOL_DIRECTIONS_CHECK_INTERVAL_MS,
} from './constants';
import type EquipmentInterface from './EquipmentInterface';
import type { FailureDetails } from './FailureDetails';
import {
  getIsRecoverableWithWristButton,
  getRecoveryType,
} from './FailureDetails';
import { FailureKind } from './FailureKind';
import type {
  AccelerationCollisionThresholds,
  JointSafetyLimits,
  LimitsForSpeed,
  RobotInterface,
} from './RobotInterface';
import type {
  LoadedRoutineState,
  RoutineRunnerState,
} from './RoutineRunnerState';
import type { SpeedProfile } from './speed-profile';
import { ABSOLUTE_MAX_SPEED_PROFILE } from './speed-profile';
import type { TaggedRoutineStepVariables } from './Step';
import type {
  ArmPosition,
  Conditional,
  Expression,
  JSRunner,
  SafeguardState,
  Space,
  StepFailure,
} from './types';
import { PAYLOAD_MASS_KG_DEFAULT, PayloadState } from './types';
import type { MotionResult } from './types/MotionResult';
import type { VisionInterface } from './vision/VisionInterface';

const ns = log.namespace('routine.context');

interface RoutineContextEvents {
  variablesChange: { [stepID: string]: any };
  gripperStopped: void;
  asynchronousStepFailure: { stepID: string };
}

const PLAN_ATTEMPTS_MAX = 5;

// When the user specifies to apply arm force in a direction,
// we want to add a target that's "inside" the last target.
// This is the distance we use to "press in".
export const PRESS_IN_DISTANCE_METERS = 0.05;

/**
 * Events `doMotion` emits to give feedback to steps that call it
 */
type MotionEvents = {
  requestingPlan: void;

  // The motion planning server has received a plan request and we're awaiting
  // its response.
  planning: void;

  // The motion planning server has sent back a waypoint. This can be used to
  // determine time from request received to having a completed plan.
  waypoint: void;

  // The arm has reached a waypoint in the plan.
  waypointReached: void;

  // A motion has begun on the robot
  beginMotion: void;

  // A motion has fininished on the robot (possibly from a pause; not necessarily
  // a successful completion)
  endMotion: void;

  // The motion has completed successfully and the robot should now be in the
  // correct place.
  complete: void;

  // A critical failure occurred that resulted in the motion having to be aborted.
  // This should likely be how `play()` resolves, if this is called from a Step.
  failure: {
    failure: FailureDetails;
    failureReason: string;
    error?: Error;
  };
};

/**
 * Events that the caller of `doMotion` can emit to control the motion.
 */
type MotionCommands = {
  // cancels the motion and resolves the method early
  cancel(): void;

  // pauses the motion until resume() is called
  pause(): void;

  // resumes the motion
  resume(): void;
};

/**
 * This is the bundle of dependencies that steps need to perform their tasks
 * and share information.
 *
 * It includes helpers and modular functions that can be
 * used both by the RoutineRunner and the steps.
 *
 * It is owned by the RoutineRunner and passed to
 * the Steps when they are constructed.
 */
export class RoutineContext {
  public robot: RobotInterface;

  public camera: CameraInterface;

  public motionPlanner: MotionPlannerInterface;

  public equipment: EquipmentInterface;

  public jsRunner: JSRunner;

  private events = new EventEmitter<RoutineContextEvents>();

  public pendingWaypoints: Waypoint[] = [];

  public destroyed: boolean = false;

  public vision: VisionInterface;

  /**
   * All steps' variable states
   */
  private variableContext: {
    [stepID: string]: any;
  } = {};

  private seenStepIds = new Set<string>();

  private routineRunnerState: RoutineRunnerState;

  public loadedRoutineState?: LoadedRoutineState;

  private readonly movementTranslation: CartesianPosition = {
    x: 0,
    y: 0,
    z: 0,
  };

  private validToolDirectionsInterval: ReturnType<typeof setTimeout>;

  public constructor({
    robot,
    camera,
    equipment,
    motionPlanner,
    jsRunner,
    vision,
  }: {
    robot: RobotInterface;
    camera: CameraInterface;
    motionPlanner: MotionPlannerInterface;
    equipment: EquipmentInterface;
    jsRunner: JSRunner;
    vision: VisionInterface;
  }) {
    this.robot = robot;
    this.camera = camera;
    this.motionPlanner = motionPlanner;
    this.equipment = equipment;
    this.jsRunner = jsRunner;

    this.vision = vision;

    const { tcpPose, wristPose } = this.getCurrentTCPAndWristPose();

    this.routineRunnerState = {
      kind: 'Idle',
      kinematicState: {
        brakesEngaged: this.robot.isRobotBraked(),
        jointAngles: this.robot.getJointAngles(),
        jointConnectionStates: this.robot.getJointConnectionState(),
        jointVelocities: this.robot.getJointVelocities(),
        jointDisturbances: this.robot.getJointDisturbances(),
        jointBrakesEngaged: this.robot.getJointBrakesEngaged(),
        tooltipPoint: tcpPose,
        wristPose,
        gripperState: null,
        dynamicBaseState: null,
        supportsSafeguardRules: this.robot.supportsSafeguardRules(),
        ioState: this.robot.getIO(),
        jointLimits: this.robot.getJointLimits(),
        safeguardState: this.robot.getSafeguardState(),
        tcpOffsetOption: DEFAULT_TCP_OFFSET_OPTION,
      },
      configuration: {
        payload: { mass: PAYLOAD_MASS_KG_DEFAULT },
        routineSpeedProfile: ABSOLUTE_MAX_SPEED_PROFILE,
        jointSafetyLimits: {
          slow: {
            tooltipSpeed: null,
            jointVelocities: six(null),
            jointTorques: six(null),
            collisionDetectionThreshold: six(null),
          },
          fast: {
            tooltipSpeed: null,
            jointVelocities: six(null),
            jointTorques: six(null),
            collisionDetectionThreshold: six(null),
          },
        },
        antigravitySpeeds: ANTIGRAVITY_SPEEDS_DEFAULT,
        safeguardRules: [],
        accelerationCollisionThresholds: six(null),
        jerkLimit: JERK_LIMIT_DEFAULT,
      },
      failureState: null,
      cannotStartReasons: [],
      validToolDirections: ALL_TOOL_MOTIONS_INVALID,
    };

    this.checkValidToolDirections();

    this.validToolDirectionsInterval = setInterval(() => {
      this.checkValidToolDirections();
    }, VALID_TOOL_DIRECTIONS_CHECK_INTERVAL_MS);
  }

  public destroy(): void {
    clearInterval(this.validToolDirectionsInterval);
    this.stopArmAndGripper();
    this.destroyed = true;
  }

  public getRoutineRunnerState(): Readonly<RoutineRunnerState> {
    return this.routineRunnerState;
  }

  public async stopArmAndGripper(): Promise<void> {
    await Promise.all([this.stopArm(), this.stopGripper()]);
  }

  public async stopArm() {
    try {
      await this.robot.stopArm();
    } catch (error) {
      log.error(ns`stop.failed`, 'Failed to stop arm:', error);
      throw error;
    }
  }

  public async stopGripper() {
    this.events.emit('gripperStopped');

    try {
      await this.equipment.stop();
    } catch (error) {
      log.error(ns`gripStop.failed`, 'Failed to stop gripper:', error);
      throw error;
    }
  }

  /**
   * Stops the current action with a failure.
   *
   * This is exposed on the RoutineContext because steps
   * don't have access to the routine runner, but they may need
   * to fail at some time besides just when `play()` is called.
   */
  public async fail({
    failure,
    failureReason,
    stepID,
    error,
    asynchronous = false,
  }: StepFailure) {
    const state = this.getRoutineRunnerState();

    if (asynchronous) {
      this.events.emit('asynchronousStepFailure', { stepID: stepID! });
    }

    try {
      const stepInfoString = stepID ? ` on step ${stepID}` : '';

      // on first fail call, try to stop the arm
      if (state.kind !== 'Failure') {
        this.stopArmAndGripper().catch(() => {});
      }

      const failureTraceID = base62Random(6);

      log.error(ns`fail`, 'Caught failure', {
        error,
        failureKind: failure.kind,
        stepInfo: stepInfoString,
        traceId: failureTraceID,
      });

      this.setRoutineRunnerState({
        kind: 'Failure',
        kinematicState: state.kinematicState,
        configuration: state.configuration,
        failureState: {
          failureTraceID,
          failureReason,
          failedStep: stepID,
          failure,
          recoveryType: getRecoveryType(failure),
          isRecoverableWithWristButton:
            getIsRecoverableWithWristButton(failure),
        },
      });
    } catch (e) {
      log.error(ns`fail.failed`, 'Failed to fail', { error: e });
    }
  }

  public onAsynchronousStepFailure(
    callback: (failure: { stepID: string }) => void,
  ): () => void {
    return this.events.on('asynchronousStepFailure', callback);
  }

  private fetchedValidToolDirections: boolean = false;

  /**
   * Apply translation to movements, will affect all motion plans executed after
   */
  public addMovementTranslation(movementTranslation: CartesianPosition) {
    this.movementTranslation.x += movementTranslation.x;
    this.movementTranslation.y += movementTranslation.y;
    this.movementTranslation.z += movementTranslation.z;
  }

  /**
   * Reset data before playing a routine
   */
  public resetOnPlayRoutine() {
    this.movementTranslation.x = 0;
    this.movementTranslation.y = 0;
    this.movementTranslation.z = 0;
    this.pendingWaypoints = [];
  }

  /**
   * Plan and accomplish a motion.
   *
   * The response is an event emitter that updates the caller
   * with events that occur during the procedure (planning, moving,
   * stopping, failures, etc.).
   *
   * The caller can also emit events on the EventEmitter to control the
   * motion. See `MotionCommands` to see these events.
   */
  public doMotion(
    // startingJointPositions is optional and gets overwritten on each attempt
    request: Omit<MotionPlanRequestShape, 'startingJointPositions'> &
      Partial<MotionPlanRequestShape>,

    speedProfile: SpeedProfile,
    pushingCollisionThresholds?: ArmJointTorques,
    stepID?: string,
  ): EventEmitter<MotionEvents & MotionCommands> {
    const events = new EventEmitter<MotionEvents & MotionCommands>();
    let canceled = false;

    request.targets.forEach((target) => {
      this.pushWaypoint({
        armTarget: target,
      });
    });

    const targets = getWaypointArmTargets(this) as MotionPlanRequest['targets'];

    if (usesBlendRadius(this)) {
      request.plannerType = 'pilz';
    }

    // Pending waypoints used up
    this.pendingWaypoints = [];

    const pausedStateTracker: PausedStatePositionSaver =
      new PausedStatePositionSaver({ events, routineContext: this });

    const waypointReachedTracker: WaypointReachedTracker =
      new WaypointReachedTracker({ targets, events, routineContext: this });

    events.on('cancel', () => {
      log.info(ns`doMotion.canceled`, 'Motion canceled');
      canceled = true;
    });

    let paused = false;

    events.on('pause', () => {
      log.warn(ns`doMotion.paused`, 'Motion paused');
      paused = true;
    });

    events.on('resume', () => {
      log.info(ns`doMotion.resumed`, 'Motion resumed');
      paused = false;
    });

    const doMotionLoop = async () => {
      let motionAttempts = 0;
      let planAttempts = 0;

      // If the user requested a pushing force,
      // generate a "pushing" target slightly "into" the last target.
      if (pushingCollisionThresholds) {
        const pushTarget = await this.getPushPose(targets);

        if (pushTarget) {
          targets.push({
            motionKind: 'line',
            pose: pushTarget,
          });
        }
      }

      // To start, this will basically just call `attemptMotion` once.
      while (!canceled) {
        motionAttempts += 1;

        const { routineSpeedProfile, jerkLimit } =
          this.routineRunnerState.configuration;

        const limitedSpeedProfile: Required<SpeedProfile> = this.limitSpeeds(
          speedProfile,
          routineSpeedProfile,
        );

        // If `attemptMotion` gets interrupted by `pause()`'s `stopArm`,
        // we want to loop until we've resumed, and we'll call `attemptMotion` again
        // when we resume.
        if (paused) {
          if (motionAttempts % 300 === 0) {
            log.debug(
              ns`doMotion.waiting`,
              'Waiting for motion to be unpaused...',
            );
          }

          await wait(STILL_GOING_CHECK_INTERVAL_MS);

          continue;
        } else {
          log.debug(
            ns`doMotion.attemptingMotion`,
            `Attempting motion #${motionAttempts}`,
            { motionAttempts },
          );
        }

        events.emit('resume');

        let motionPlanResult: ArmAndDeviceMotionPlan<DeviceCommand>;
        let motionPlanTime: number | undefined;

        try {
          const startTime = Date.now();

          motionPlanResult = await this.planMotion(
            {
              ...request,
              targets: waypointReachedTracker.getUpdatedTargets(
                targets,
              ) as MotionPlanRequest['targets'],
              ...this.getMotionPlanOptions(limitedSpeedProfile),
              startingJointPositions: this.getJointAngles(),
              jerkLimit,
            },
            events,
          );

          const rawMotionPlan = motionPlanResult.motionPlan.raw();

          motionPlanTime = rawMotionPlan[rawMotionPlan.length - 1]?.timestamp;

          log.info(
            ns`motionPlan.success`,
            `Motion plan stepID=${stepID} motionPlanTime=${motionPlanTime} motionPlanSize=${rawMotionPlan.length}`,
            {
              stepId: stepID,
              expectedCompletionTime: motionPlanTime,
              planningDuration: Date.now() - startTime,
              motionPlanSize: rawMotionPlan.length,
            },
          );
        } catch (error) {
          // if we paused or canceled while planning, retry after resumed
          if (paused || canceled) {
            continue;
          }

          if (error.message.includes('startPosition is in collision')) {
            log.error(
              ns`motionPlan.failure`,
              `Failed to plan because starting position is in collision`,
              error,
            );

            events.emit('failure', {
              failure: {
                kind: FailureKind.MotionPlannerFailureStartPositionCollision,
              },
              failureReason: `Failed to plan because starting position is in collision: ${error.message}`,
            });

            return;
          }

          if (planAttempts > PLAN_ATTEMPTS_MAX) {
            events.emit('failure', {
              failure: {
                kind: FailureKind.PlanningFailure,
              },
              failureReason: `Failed to plan: ${error.message}`,
            });

            return;
          }

          planAttempts += 1;

          log.warn(
            ns`motionPlan.failure`,
            `Failed to plan: ${error.message}. Attempt #${planAttempts}/${PLAN_ATTEMPTS_MAX}`,
            {
              error,
              planAttempts,
              maxAttempts: PLAN_ATTEMPTS_MAX,
            },
          );

          continue;
        }

        let failure: MotionEvents['failure'] | undefined;

        for (const deviceCommand of motionPlanResult.deviceCommands) {
          if (paused || canceled || failure) {
            break;
          }

          try {
            await this.actuateDevice(deviceCommand);
          } catch (error) {
            failure = {
              failure: {
                kind: FailureKind.GripperFailure,
                message: error.message,
              },
              failureReason: 'Failed to actuate device before motion',
            };

            break;
          }
        }

        // if we paused or canceled while planning or running device commands, don't actually attempt the motion
        if (paused || canceled) {
          continue;
        }

        if (!failure) {
          // if the routine speed changed between planning and execution, replan
          if (
            this.routineRunnerState.configuration.routineSpeedProfile !==
            routineSpeedProfile
          ) {
            continue;
          }

          const startTime = Date.now();

          log.info(
            `doMotion`,
            `doMotion Densifying with ${request.smoothingType}`,
          );

          // Wait for attemptMotion to either complete or fail.
          const motionResult = await this.attemptMotion(
            motionPlanResult.motionPlan,
            events,
            limitedSpeedProfile,
            pushingCollisionThresholds,
            request.smoothingType,
          );

          const attemptMotionTime = (Date.now() - startTime) / 1000;

          log.info(
            ns`motion.attempt.start`,
            `Attempt motion for stepID=${stepID}`,
            {
              attemptMotionTime,
              motionPlanTimeDiff: attemptMotionTime - motionPlanTime,
              motionPlanTime,
            },
          );

          if (motionResult.kind === 'ioStateChanged') {
            const { ioStateChanged } = motionResult;

            if (
              ioStateChanged.oldState?.safeguardState === 'fullSpeed' &&
              ioStateChanged.newState?.safeguardState === 'slowSpeed'
            ) {
              // try again at slower speed
              // TODO: This is hard to keep track of;
              // make sure there are no await boundaries between this and the next limitSpeeds call
              this.routineRunnerState.kinematicState.safeguardState =
                'slowSpeed';

              continue;
            }
          }

          if (
            motionResult.kind !== 'success' &&
            motionResult.kind !== 'pushing'
          ) {
            log.error(
              ns`execution.failure`,
              'Execution failure:',
              motionResult,
            );

            failure = {
              failure: {
                kind: FailureKind.ExecutionFailure,
                motionResult,
              },
              failureReason:
                this.generateMotionResultFailureReason(motionResult),
            };
          }
        }

        // If pause stopped it, we want to keep looping regardless of whether
        // the motion failed (it probably did, because it fails when
        // stopArm is called)
        if (paused) {
          continue;
        }

        // If it errored but it wasn't paused, we want to emit the error
        // and stop looping.
        if (failure) {
          events.emit('failure', failure);
          break;
        }

        if (this.destroyed) {
          events.emit('cancel');
        } else {
          events.emit('complete');
        }

        break;
      }
    };

    const endRoutineGuidedModeMotion = this.beginRoutineGuidedModeMotion();

    doMotionLoop()
      .catch((error) => {
        log.error(ns`doMotion.failure`, 'Internal Failure in doMotion', {
          error,
          request,
          speedProfile,
          pushingCollisionThresholds,
        });

        events.emit('failure', {
          failure: {
            kind: FailureKind.InternalFailure,
          },
          failureReason: 'Unknown error while running motion',
          error,
        });
      })
      .finally(() => {
        endRoutineGuidedModeMotion?.();
        events.removeAllListeners();

        waypointReachedTracker.destroy();
        pausedStateTracker.destroy();
      });

    return events;
  }

  generateMotionResultFailureReason(motionResult: MotionResult): string {
    log.debug(ns`generateMotionResult`, 'generateMotionResult', motionResult);

    switch (motionResult.kind) {
      case 'collision':
        return `robot encountered a collision on J${motionResult.collision.joint}. Disturbance detected: ${motionResult.collision.disturbanceDetected} Nm (threshold set to ${motionResult.collision.disturbanceThreshold} Nm)`;
      case 'eStopTriggered':
        return `E-Stop was triggered`;
      case 'controlSystemEvent':
        return motionResult.controlSystemEvent.message;
      case 'stateError':
        return `Robot cannot move when in state ${motionResult.stateError.previousState}`;
      case 'botmanHeartbeatLost':
        return 'Internal communication failure';
      case 'ioStateChanged':
        return (
          motionResult.ioStateChanged.changes[0] ?? 'Safety IO was triggered'
        );
      case 'torqueLimitExceeded':
        return `Torque limit exceeded. Please reduce payload weight or reduce tooltip distance from base. ${motionResult.torqueLimitExceeded.message}`;
      case 'invalidPlan':
        return `Invalid motion plan: ${motionResult.invalidPlan.validationError}`;
      default:
        return 'Motion failed';
    }
  }

  // If the user requested a pushing force,
  // generate a "pushing" target slightly "into" the last target.
  async getPushPose(
    targets: MotionPlanRequest['targets'],
  ): Promise<CartesianPose | null> {
    const lastTarget = targets[targets.length - 1];

    if (lastTarget.motionKind !== 'line') {
      throw new Error('Cannot plan a pushing motion that is non-linear');
    }

    const endPose = await this.getTargetPose(lastTarget);

    const previousPose =
      targets.length > 1
        ? await this.getTargetPose(targets[targets.length - 2])
        : this.getRoutineRunnerState().kinematicState.tooltipPoint;

    const direction = {
      x: endPose.x - previousPose.x,
      y: endPose.y - previousPose.y,
      z: endPose.z - previousPose.z,
    };

    const totalDistance = Math.sqrt(
      direction.x ** 2 + direction.y ** 2 + direction.z ** 2,
    );

    direction.x /= totalDistance;
    direction.y /= totalDistance;
    direction.z /= totalDistance;

    // check if the arm's current position is too close to the final
    // point to derive a meaningful direction.
    // this only applies if the "previous pose" is the current position,
    // which can vary at runtime.
    if (targets.length === 1 && totalDistance < PRESS_IN_DISTANCE_METERS) {
      log.warn(
        ns`validation.pushPoseTooClose`,
        `Skipping push pose because provided press distance is less than ${PRESS_IN_DISTANCE_METERS} meters away from the target`,
        {
          totalDistance,
        },
      );

      return null;
    }

    log.debug(ns`pressDirection`, 'Pressing in direction', direction);

    return {
      ...endPose,
      x: endPose.x + direction.x * PRESS_IN_DISTANCE_METERS,
      y: endPose.y + direction.y * PRESS_IN_DISTANCE_METERS,
      z: endPose.z + direction.z * PRESS_IN_DISTANCE_METERS,
    };
  }

  /**
   * Put the routine into guided mode if required
   * @returns a cleanup function to run at the end of the motion
   */
  private beginRoutineGuidedModeMotion(): (() => void) | undefined {
    if (
      this.routineRunnerState.kind === 'RoutineRunning' &&
      this.routineRunnerState.shouldNextArmMoveBeGuidedMode
    ) {
      if (!this.routineRunnerState.shouldCheckGuidedMode) {
        this.setRoutineRunnerState({
          ...this.routineRunnerState,
          shouldCheckGuidedMode: true,
        });

        // end guided mode after motion completes
        return () => {
          if (
            this.routineRunnerState.kind === 'RoutineRunning' &&
            this.routineRunnerState.shouldNextArmMoveBeGuidedMode
          ) {
            this.setRoutineRunnerState({
              ...this.routineRunnerState,
              shouldCheckGuidedMode: false,
              shouldNextArmMoveBeGuidedMode: false,
            });
          }
        };
      }
    }

    return undefined;
  }

  public async sendNetworkRequest(
    command: NetworkRequestCommand,
  ): Promise<NetworkRequestCommandResponse> {
    const start = Date.now();

    log.info(ns`network.request.started`, 'Command', {
      startTime: start,
      command,
    });

    try {
      const response = await this.equipment.sendNetworkRequest(command);

      return getCommandSuccessResponse(command.request.kind, response);
    } catch (error) {
      throw new Error(`Error sending network request: ${error.message}`);
    }
  }

  public async actuateDevice(
    deviceCommand: DeviceCommand,
    opts: { timeoutMS?: number; retry?: boolean } = {},
  ) {
    const timeoutMS = opts.timeoutMS ?? ACTUATE_DEVICE_RETRY_TIMEOUT_MS;
    const retry = opts.retry ?? true;
    const start = Date.now();

    log.debug(ns`actuateDevice.start`, 'Starting actuation', {
      start,
      timeoutMS,
    });

    let stopped = false;

    const removeGripperStoppedListener = this.events.once(
      'gripperStopped',
      () => {
        stopped = true;
      },
    );

    let lastError: Error;

    try {
      let retries = 0;

      while (!stopped && Date.now() - start < timeoutMS) {
        try {
          log.debug(ns`actuation.attempt`, 'Actuation attempt...', { retries });
          await this.equipment.actuate(deviceCommand);

          return;
        } catch (error) {
          if (!retry) {
            throw error;
          }

          lastError = error;

          if (stopped) {
            return;
          }

          retries += 1;

          // gripper disconnected while actuating; we probably want to retry
          if (!this.getRoutineRunnerState().kinematicState.gripperState) {
            log.warn(
              ns`gripper.detached`,
              `Gripper appears to have detached (try #${retries}): ${error.message}. Retrying.`,
              {
                error,
                retries,
              },
            );
          } else {
            log.warn(
              ns`actuation.failed`,
              `Failed to actuate gripper (try #${retries}).`,
              { error, retries },
            );
          }

          await wait(100);
        }
      }
    } finally {
      removeGripperStoppedListener();
    }

    throw lastError!;
  }

  /**
   * Do a grasp of an object with a pre-grasp, grasp,
   * and post-grasp position.
   *
   * Uses the grasp motion planner API and actuates the gripper
   * after achieving the grasp pose.
   *
   * As multiple grasps may be provided, the gripper command
   * can be determined from the gripper openness, which corresponds
   * with the gripperOpenness provided in the candidate.
   *
   * If paused, replans from the middle using a linear motion
   * to the same target the grasp API returned.
   */
  public grasp(
    target: GraspArmTarget,
    opennessToCommand: (gripperOpenness: GripperOpenness) => DeviceCommand,
    speedProfile: SpeedProfile,
  ): EventEmitter<MotionEvents & MotionCommands> {
    const events = new EventEmitter<MotionEvents & MotionCommands>();

    let canceled = false;

    events.on('cancel', () => {
      canceled = true;
    });

    let paused = false;

    events.on('pause', () => {
      paused = true;
    });

    events.on('resume', () => {
      paused = false;
    });

    // helper to wait until we're playing, if we're not already
    const untilPlaying = () => {
      if (!paused) {
        return Promise.resolve();
      }

      return events.next('resume');
    };

    // Avoids repetition because we do this twice:
    //
    // - Attempt to do one of the motion plans coming back from the grasp API
    // - If we pause and resume, resume with a linear motion
    //
    // Handles retrying, but if we fail or otherwise should not continue,
    // returns false.
    //
    // Resolves to true on success.
    //
    // Emits failure events when returning false, if they should
    // be emitted.
    const doGraspPlan = async (plan: MotionPlan): Promise<boolean> => {
      let success = false;

      let motionResult: MotionResult;

      try {
        motionResult = await this.attemptMotion(
          plan,
          events,
          this.limitSpeeds(
            speedProfile,
            this.getRoutineRunnerState().configuration.routineSpeedProfile,
          ),
        );

        success =
          motionResult.kind === 'success' || motionResult.kind === 'pushing';
      } catch (error) {
        if (!paused && !canceled) {
          events.emit('failure', {
            failure: {
              kind: FailureKind.ExecutionFailure,
            },
            failureReason: 'Failed to go to grasp',
            error,
          });

          return false;
        }
      }

      await untilPlaying();

      if (canceled) {
        return false;
      }

      // if the way down didn't succeed,
      // doMotion with a linear motion to the grasp
      if (!success) {
        const graspAngles = plan[plan.length - 1].joints.map(
          (moment) => moment.p,
        ) as ArmJointPositions;

        const innerEvents = this.doMotion(
          {
            targets: [{ motionKind: 'line', jointAngles: graspAngles }],
          },
          speedProfile,
        );

        events.on('pause', () => {
          innerEvents.emit('pause');
        });

        events.on('resume', () => {
          innerEvents.emit('resume');
        });

        // forward these events from inner events to outer
        const forwardedEvents = [
          'planning',
          'waypoint',
          'beginMotion',
          'endMotion',
        ] as const;

        for (const eventKind of forwardedEvents) {
          innerEvents.on(eventKind, () => {
            events.emit(eventKind);
          });
        }

        const result = await events.race('complete', 'failure');

        if (result) {
          events.emit('failure', result);

          return false;
        }
      }

      return true;
    };

    (async () => {
      // plan the grasp using the grasp planning API
      const request: MotionPlanRequest = {
        startingJointPositions: this.getJointAngles(),
        targets: [
          {
            motionKind: 'joint',
            ...target,
          },
        ],
        ...this.getMotionPlanOptions(speedProfile),
      };

      let toGraspPlan: MotionPlan;
      let fromGraspPlan: MotionPlan;
      let command: DeviceCommand;

      try {
        const response = this.motionPlanner.planMotion(request);

        const gripperOpennessPromise = response
          .next('gripper')
          .then(({ gripperOpenness }) => opennessToCommand(gripperOpenness));

        [toGraspPlan, fromGraspPlan] = await response.completeAll();
        command = await gripperOpennessPromise;
      } catch (error) {
        events.emit('failure', {
          failure: {
            kind: FailureKind.MotionPlannerFailure,
            request: {
              method: 'planMotion',
              args: [request],
            },
          },
          failureReason: error.message,
          error,
        });

        return;
      }

      const success = await doGraspPlan(toGraspPlan);
      await untilPlaying();

      if (!success || canceled) {
        return;
      }

      try {
        await this.actuateDevice(command);
      } catch (error) {
        events.emit('failure', {
          failure: {
            kind: FailureKind.GripperFailure,
            message: error.message,
          },
          failureReason: error.message,
          error,
        });

        return;
      }

      await untilPlaying();

      if (canceled) {
        return;
      }

      await doGraspPlan(fromGraspPlan);
      events.emit('complete');
    })().finally(() => {
      events.removeAllListeners();
    });

    return events;
  }

  /**
   * Plans and initiates a motion, resolving when the `playMotionPlan` method completes.
   */
  private async attemptMotion(
    motion: MotionPlan,
    events: EventEmitter<MotionEvents & MotionCommands>,
    speedProfile: Required<SpeedProfile>,
    pushingCollisionThresholds?: ArmJointTorques,
    smoothingType?: SmoothingType,
  ): Promise<MotionResult> {
    // If the caller emits a 'pause' or 'cancel' event, the attempt
    // should bail out ASAP
    let stopped = false;

    const offPause = events.on('pause', () => {
      stopped = true;
    });

    const offCancel = events.on('cancel', () => {
      stopped = true;
    });

    events.emit('beginMotion');

    log.debug(ns`attemptMotion.start`, 'Beginning motion');

    log.debug(ns`attemptMotion.start`, `densifying with ${smoothingType}`);

    return this.robot
      .playMotionPlan({
        motionPlan: motion,
        maxJointVelocities: speedProfile.maxJointSpeeds,
        maxJointAccelerations: speedProfile.maxJointAccelerations,
        maxTooltipVelocity: speedProfile.maxTooltipSpeed,
        pushingCollisionThresholds,
        checkTorqueLimitsPremotion: true,
        smoothingType,
      })
      .finally(() => {
        // if some other activity is happening, don't override the state
        if (stopped) {
          return;
        }

        events.emit('endMotion');
        offPause();
        offCancel();
      });
  }

  private async planMotion(
    request: MotionPlanRequest,
    events: EventEmitter<MotionEvents & MotionCommands>,
  ): Promise<ArmAndDeviceMotionPlan<DeviceCommand>> {
    // If the caller emits a 'pause' or 'cancel' event, the attempt
    // should bail out ASAP
    let stopped = false;

    const offPause = events.on('pause', () => {
      stopped = true;
    });

    const offCancel = events.on('cancel', () => {
      stopped = true;
    });

    // if a request was passed in, we need to plan as well.
    events.emit('requestingPlan');

    try {
      const motionPlanResult = await new ArmAndDeviceMotionPlanner({
        motionPlanner: this.motionPlanner,
        request,
        deviceKinematics: this.equipment.getDeviceKinematics(),
        onAcknowledged() {
          if (!stopped) {
            events.emit('planning');
          }
        },
        onWaypoint() {
          if (!stopped) {
            events.emit('waypoint');
          }
        },
        tcpOffsetOption: this.routineRunnerState.kinematicState.tcpOffsetOption,
      }).plan();

      motionPlanResult.motionPlan.forEach((moment, index) => {
        const positions = moment.angles;

        if (this.isOutOfLimits(positions)) {
          throw new Error(
            `Received motion from motion planner with positions that violate limits (moment ${index})`,
          );
        }
      });

      return motionPlanResult;
    } finally {
      offPause();
      offCancel();
    }
  }

  public setRoutineRunnerState(newState: RoutineRunnerState) {
    // check valid tool directions when the state is Idle but we have
    // yet to fetch
    const shouldFetchValidToolDirections = (() => {
      if (newState.kind !== 'Idle') {
        this.fetchedValidToolDirections = false;

        return false;
      }

      return !this.fetchedValidToolDirections;
    })();

    switch (newState.kind) {
      case 'Failure':
        this.robot
          .setInFailureState(
            true,
            newState.failureState.isRecoverableWithWristButton,
          )
          .catch((error) => {
            log.error(
              ns`setRoutineRunnerState.failed`,
              'Failed to update failure state in arm-control-bot',
              { error },
            );
          });

        break;
      case 'Idle':
        this.robot.setInFailureState(false, false).catch((error) => {
          log.error(
            ns`setRoutineRunnerState.failed`,
            'Failed to update failure state in arm-control-bot',
            { error },
          );

          this.fail({
            failure: { kind: FailureKind.ControlSystemFailure },
            failureReason: 'Failed to update state in control system.',
          });
        });

        break;
      default:
      // no-op
    }

    this.routineRunnerState = newState;

    if (shouldFetchValidToolDirections) {
      this.checkValidToolDirections();
    }
  }

  /**
   * Used by routine parsing to determine whether duplicate step IDs have been encountered.
   *
   * @returns true if this step ID is unique/valid; false if it is not
   */
  public validateStepID(id: string): boolean {
    if (this.seenStepIds.has(id)) {
      return false;
    }

    this.seenStepIds.add(id);

    return true;
  }

  /**
   * Set the variables for a step
   */
  public setVariableState(
    stepID: string,
    variableState: TaggedRoutineStepVariables,
  ) {
    this.variableContext[stepID] = variableState;
    this.events.emit('variablesChange', this.variableContext);
  }

  /**
   * Get the variables for a step
   *
   * Within RoutineContext, the state gets type-erased and types must be checked
   * dynamically when retrieved.
   */
  public getVariableState(stepID: string): TaggedRoutineStepVariables {
    if (!(stepID in this.variableContext)) {
      throw new Error(
        `Tried to get variable state for step "${stepID}" which either does not exist or failed to set variables`,
      );
    }

    return this.variableContext[stepID];
  }

  /**
   * Retrieve the full state of all steps.
   *
   * Within RoutineContext, the state gets type-erased and types must be checked
   * dynamically when retrieved.
   */
  public getAllVariables(): { [stepID: string]: any } {
    return this.variableContext;
  }

  /**
   * Reset data before loading a routine - clear all variable state, including the step IDs.
   */
  public resetOnLoadRoutine() {
    this.seenStepIds.clear();
    this.variableContext = {};
    this.events.emit('variablesChange', this.variableContext);
  }

  /**
   * Fail with an execution failure and throw
   */
  private failAndThrow(failureReason: string): never {
    const error = new Error(failureReason);

    const routineRunnerState = this.getRoutineRunnerState();

    const stepID =
      routineRunnerState.kind === 'RoutineRunning'
        ? routineRunnerState.currentStepID
        : undefined;

    this.fail({
      failure: { kind: FailureKind.InvalidRoutineLoadedFailure },
      failureReason,
      error,
      stepID,
    });

    throw error;
  }

  /**
   * Evaluates an expression within the context, using variables that have been registered.
   */
  public async evaluateExpression(expression: Expression): Promise<any> {
    switch (expression.kind) {
      case 'variable': {
        const stepVariables = this.getVariableState(expression.stepID);
        const { name } = expression;

        if (typeof stepVariables !== 'object' || !(name in stepVariables)) {
          return this.failAndThrow(
            `Step "${expression.stepID}" has not set a variable called "${expression.name}"`,
          );
        }

        return stepVariables[name as keyof typeof stepVariables];
      }

      case 'stateVariable': {
        this.updateKinematicState();

        const state = this.getRoutineRunnerState();
        const value = get(state, expression.path);

        return value;
      }

      case 'constant': {
        return expression.value;
      }

      case 'conditional': {
        return this.evaluateConditional(expression);
      }

      case 'JavaScript': {
        try {
          const value = await this.jsRunner.evaluate({
            expression: expression.expression,
            context: {
              kinematicState: {
                ioState: this.routineRunnerState.kinematicState.ioState,
                gripperState:
                  this.routineRunnerState.kinematicState.gripperState,
              },
              variables: this.getAllVariables(),
              routine: {
                positionLists: this.loadedRoutineState?.space
                  ? Object.fromEntries(
                      this.loadedRoutineState.space.map(
                        ({ id: positionListID, positions }) => [
                          positionListID,
                          positions,
                        ],
                      ),
                    )
                  : {},
              },
            },
          });

          return value;
        } catch (error) {
          return this.failAndThrow(`Evaluating expression: ${error?.message}`);
        }
      }
    }
  }

  public async evaluateConditional(condition: Conditional): Promise<boolean> {
    const { operator, leftOperand, rightOperand } = condition;

    const leftValue = await this.evaluateExpression(leftOperand);
    const rightValue = await this.evaluateExpression(rightOperand);

    const checkBothOperandsOfType = (type: string): void => {
      if (typeof leftValue !== type || typeof rightValue !== type) {
        this.failAndThrow(
          `The configuration for a conditional step (If, Loop, or Wait) is incorrect. Check that the configuration variables are up to date.`,
        );
      }
    };

    const checkOperandsAreComparable = (): void => {
      if (leftValue == null || rightValue == null) {
        return;
      }

      const leftType = typeof leftValue;
      const rightType = typeof rightValue;

      if (
        leftType === 'object' ||
        rightType === 'object' ||
        leftType !== rightType
      ) {
        this.failAndThrow(
          `Evaluating “{${leftType}}${operator}{${rightType}}”: operator can only be used when both operands are the same simple type`,
        );
      }
    };

    switch (operator) {
      case '>': {
        checkBothOperandsOfType('number');

        return leftValue > rightValue;
      }

      case '<': {
        checkBothOperandsOfType('number');

        return leftValue < rightValue;
      }

      case '==': {
        checkOperandsAreComparable();

        return leftValue === rightValue;
      }

      case '!=': {
        checkOperandsAreComparable();

        return leftValue !== rightValue;
      }

      case 'AND': {
        checkBothOperandsOfType('boolean');

        return leftValue && rightValue;
      }

      case 'OR': {
        checkBothOperandsOfType('boolean');

        return leftValue || rightValue;
      }

      default: {
        return this.failAndThrow(
          `Conditionals with operator ${operator} are not supported (yet?)`,
        );
      }
    }
  }

  public onVariablesChange(
    cb: (variables: { [stepID: string]: any }) => void,
  ): () => void {
    this.events.on('variablesChange', cb);

    return () => this.events.off('variablesChange', cb);
  }

  /**
   * Calculate a ceiling for accelerations based on the current state of the payload.
   * Optionally takes a set of accelerations by which to further limit.
   */
  public getMaxJointAccelerations(
    accelerations: ArmJointAccelerations = ABSOLUTE_MAX_JOINT_ACCELERATIONS,
  ): ArmJointAccelerations {
    const maxAccelerations: ArmJointAccelerations = six(0);

    const hardwareMaxAccelerations = payloadMassToMaxAccelerations(
      this.getPayload().mass,
    );

    for (let jj = 0; jj < hardwareMaxAccelerations.length; jj += 1) {
      maxAccelerations[jj] = Math.min(
        accelerations[jj],
        hardwareMaxAccelerations[jj],
      );
    }

    return maxAccelerations;
  }

  public async setJointSafetyLimits(limits: JointSafetyLimits) {
    this.routineRunnerState.configuration.jointSafetyLimits = limits;
    await this.robot.setJointSafetyLimits(limits);
  }

  public async setAccelerationCollisionThresholds(
    thresholds: AccelerationCollisionThresholds,
  ) {
    this.routineRunnerState.configuration.accelerationCollisionThresholds =
      thresholds;

    await this.robot.setIMUCollisionThresholds(thresholds);
  }

  public async setModbusPower(enable: boolean) {
    await this.robot.setModbusPower(enable);
  }

  public setJerkLimit(jerkLimit: JerkLimit) {
    this.routineRunnerState.configuration.jerkLimit = jerkLimit;
  }

  /**
   * Get the current arm joint limits
   *
   * @returns The ArmJointLimits to honor when requesting new motion plans
   */
  public getJointLimits(): ArmJointLimits {
    return this.robot.getJointLimits();
  }

  public isOutOfLimits(positions: ArmJointPositions): boolean {
    const limits = this.getJointLimits();

    return (
      positions.findIndex((position, index) => {
        return position < limits[index].min || position > limits[index].max;
      }) !== -1
    );
  }

  /**
   * Set information about the payload for use in dynamics and planning.
   */
  public setPayload(unvalidatedPayload: PayloadState) {
    // check for range bound errors
    const payload = PayloadState.parse(unvalidatedPayload);
    this.routineRunnerState.configuration.payload = payload;
    this.robot.anticipatePayload(payload);
  }

  public getPayload(): PayloadState {
    return this.routineRunnerState.configuration.payload;
  }

  public setRoutineSpeedProfile(speedProfile: SpeedProfile) {
    this.routineRunnerState.configuration.routineSpeedProfile = speedProfile;
  }

  public setAntigravitySpeeds(speeds: ArmJointVelocities): void {
    this.robot.setAntigravitySpeeds(speeds);
    this.routineRunnerState.configuration.antigravitySpeeds = speeds;
  }

  /**
   * Translates a speed profile to options we can send to the motion planner.
   *
   * When the routine context is in the state of `RoutineRunning`, we honor
   * the configuration's routine speed profile.
   */
  public getMotionPlanOptions(speedProfile: SpeedProfile): MotionPlanOptions {
    const speedProfiles: Array<SpeedProfile> = [speedProfile];

    const state = this.getRoutineRunnerState();

    if (state.kind === 'RoutineRunning') {
      speedProfiles.push(state.configuration.routineSpeedProfile);
    }

    const limitedProfile = this.limitSpeeds(...speedProfiles);

    return {
      gripperOpenness: 1,
      maxAccelerations: limitedProfile.maxJointAccelerations,
      maxVelocities: limitedProfile.maxJointSpeeds,
      maxTooltipVelocity: limitedProfile.maxTooltipSpeed,
    };
  }

  public updateKinematicState() {
    this.routineRunnerState.kinematicState.brakesEngaged =
      this.robot.isRobotBraked();

    this.routineRunnerState.kinematicState.ioState = this.robot.getIO();

    this.routineRunnerState.kinematicState.supportsSafeguardRules =
      this.robot.supportsSafeguardRules();

    this.routineRunnerState.kinematicState.jointAngles =
      this.robot.getJointAngles();

    this.routineRunnerState.kinematicState.jointConnectionStates =
      this.robot.getJointConnectionState();

    this.routineRunnerState.kinematicState.jointVelocities =
      this.robot.getJointVelocities();

    this.routineRunnerState.kinematicState.jointDisturbances =
      this.robot.getJointDisturbances();

    this.routineRunnerState.kinematicState.jointBrakesEngaged =
      this.robot.getJointBrakesEngaged();

    const { tcpPose, wristPose } = this.getCurrentTCPAndWristPose();
    this.routineRunnerState.kinematicState.tooltipPoint = tcpPose;
    this.routineRunnerState.kinematicState.wristPose = wristPose;

    this.routineRunnerState.kinematicState.jointLimits =
      this.robot.getJointLimits();

    this.routineRunnerState.kinematicState.gripperState =
      this.equipment.getGripperState();

    this.routineRunnerState.kinematicState.dynamicBaseState =
      this.equipment.getDynamicBaseState() ?? null;

    this.routineRunnerState.kinematicState.safeguardState =
      this.robot.getSafeguardState();
  }

  public getJointAngles(): ArmJointPositions {
    return this.robot.getJointAngles();
  }

  private async checkValidToolDirections(): Promise<void> {
    try {
      if (this.routineRunnerState.kind !== 'Idle') {
        return;
      }

      const jointAngles = this.getJointAngles();

      const validToolDirections =
        await this.motionPlanner.getValidToolDirections(jointAngles);

      if (this.routineRunnerState.kind !== 'Idle') {
        return;
      }

      this.routineRunnerState = {
        ...this.routineRunnerState,
        validToolDirections,
      };

      this.fetchedValidToolDirections = true;
    } catch (e) {
      log.error(
        ns`checkValidToolDirections.fail`,
        'Failed to check valid tool directions',
        { error: e },
      );
    }
  }

  private getSafeguardState(): SafeguardState {
    return this.routineRunnerState.kinematicState.safeguardState;
  }

  private getActiveJointSpeedLimits(): LimitsForSpeed {
    const { jointSafetyLimits } = this.routineRunnerState.configuration;

    return this.getSafeguardState() === 'slowSpeed'
      ? jointSafetyLimits.slow
      : jointSafetyLimits.fast;
  }

  private limitSpeeds(...speeds: Array<SpeedProfile>): SpeedProfile {
    const activeLimits = this.getActiveJointSpeedLimits();

    const maxJointSpeeds = JOINT_NUMBERS.map((index: JointNumber) => {
      return Math.min(
        ...speeds.map((s) => Math.abs(s.maxJointSpeeds[index])),
        activeLimits.jointVelocities[index] ?? Infinity,
        ABSOLUTE_MAX_JOINT_SPEEDS[index],
      );
    }) as ArmJointVelocities;

    const maxTooltipSpeed = Math.min(
      ABSOLUTE_MAX_TOOLTIP_SPEED,
      activeLimits.tooltipSpeed ?? Infinity,
      ...speeds.map((s) => Math.abs(s.maxTooltipSpeed)),
    );

    const accelerationLimit = JOINT_NUMBERS.map((index) => {
      return Math.min(
        ...speeds.map((s) => Math.abs(s.maxJointAccelerations[index])),
        ABSOLUTE_MAX_JOINT_ACCELERATIONS[index],
      );
    }) as ArmJointAccelerations;

    const maxJointAccelerations =
      this.getMaxJointAccelerations(accelerationLimit);

    return {
      maxJointSpeeds,
      maxTooltipSpeed,
      maxJointAccelerations,
    };
  }

  public pushWaypoint(waypoint: Waypoint) {
    log.debug(ns`waypoint.push`, 'pushing waypoint', { waypoint });

    this.pendingWaypoints.push({
      ...waypoint,
      armTarget: translateArmTarget(
        waypoint.armTarget,
        this.movementTranslation,
      ),
    });
  }

  public getPositionListEntry(
    positionListID: string,
    index: number,
  ): ArmPosition {
    const positionList = this.getSpaceItem(positionListID).positions;

    if (positionList.length === 0) {
      throw new Error(`Position list "${positionListID}" is empty`);
    }

    return positionList[index % positionList.length];
  }

  public setPositionListEntries(
    positionListID: string,
    positions: Array<ArmPosition>,
  ) {
    const spaceItem = this.getSpaceItem(positionListID);
    spaceItem.positions = positions;
  }

  public addSpaceItem(item: Space.Item) {
    if (!this.loadedRoutineState) {
      throw new Error('Tried to set space item before a routine was loaded');
    }

    if (!this.loadedRoutineState.space) {
      this.loadedRoutineState.space = [];
    }

    if (this.loadedRoutineState.space.find((i) => i.id === item.id)) {
      throw new Error(`Space item with ID ${item.id} already exists`);
    }

    this.loadedRoutineState.space.push(item);
  }

  public getSpaceItem(id: string) {
    if (!this.loadedRoutineState) {
      throw new Error('Tried to get space item before a routine was loaded');
    }

    const item = this.loadedRoutineState.space?.find((i) => i.id === id);

    if (!item) {
      throw new Error(`Space item with ID ${id} does not exist`);
    }

    return item;
  }

  private getCurrentTCPAndWristPose() {
    const tooltipPoint = forwardKinematics(this.getJointAngles());

    return this.addEquipmentOffset(tooltipPoint);
  }

  public getCurrentPose(): CartesianPose {
    return this.getCurrentTCPAndWristPose().tcpPose;
  }

  public async getTargetPose(target: ArmTarget): Promise<CartesianPose> {
    const jointParsed = JointArmTarget.safeParse(target);

    if (jointParsed.success) {
      const { jointAngles } = jointParsed.data;

      const fkResult = await this.motionPlanner.forwardKinematics({
        jointAngles,
        gripperOpenness: 1,
        checkValidity: false,
      });

      return this.addEquipmentOffset(fkResult.pose).tcpPose;
    }

    return this.getPoseGraspArmTargetPose(target);
  }

  public getTargetPoseSync(target: ArmTarget): CartesianPose {
    const jointParsed = JointArmTarget.safeParse(target);

    if (jointParsed.success) {
      const { jointAngles } = jointParsed.data;

      const jointArmTarget = forwardKinematics(jointAngles);

      return this.addEquipmentOffset(jointArmTarget).tcpPose;
    }

    return this.getPoseGraspArmTargetPose(target);
  }

  private getPoseGraspArmTargetPose(target: ArmTarget): CartesianPose {
    const poseParsed = PoseArmTarget.safeParse(target);

    if (poseParsed.success) {
      return poseParsed.data.pose;
    }

    const grasp = GraspArmTarget.safeParse(target);

    if (grasp.success) {
      return grasp.data.gripperEvent.postGraspPose;
    }

    log.error(`target.parse.failure`, 'Failed to parse target', target);

    throw new Error('Could not properly parse target');
  }

  public setTCPOffsetOption(tcpOffsetOption: TCPOffsetOption) {
    this.routineRunnerState.kinematicState.tcpOffsetOption = tcpOffsetOption;
  }

  private addEquipmentOffset(targetPose: CartesianPose): {
    tcpPose: CartesianPose;
    wristPose: CartesianPose;
  } {
    let tcpPose = targetPose;
    let wristPose = targetPose;

    // method may be called from constructor, where routineRunnerState isn't set yet
    const tcpOption =
      this.routineRunnerState?.kinematicState.tcpOffsetOption ?? 'wrist';

    for (const kinematics of this.equipment.getDeviceKinematics()) {
      const offset = kinematics.getOffset?.({ tcpOption });

      switch (offset?.kind) {
        case 'base': {
          tcpPose = applyCompoundPose(tcpPose, offset.transform);
          wristPose = applyCompoundPose(wristPose, offset.transform);
          break;
        }
        case 'tcp': {
          tcpPose = applyCompoundPose(
            tooltipCoordinatesToBaseCoordinates(offset.transform),
            tcpPose,
          );

          break;
        }
      }
    }

    return { tcpPose, wristPose };
  }
}
