import * as zod from 'zod';

export const WristCamera = zod.object({
  kind: zod.literal('wristCamera'),
  name: zod.string().default('Wrist Camera'),
  // realsense defaults
  brightness: zod.number().default(0),
  contrast: zod.number().default(50),
  exposure: zod.number().default(350),
  sharpness: zod.number().default(50),
  hue: zod.number().default(0),
  whiteBalance: zod.number().default(4600),
  autoWhiteBalance: zod.boolean().default(true),
});

export type WristCamera = zod.infer<typeof WristCamera>;

export const CameraIntegration = zod.union([
  zod.never(), // remove when we have > 1 camera type; it's only here to make a valid union
  WristCamera,
]);

export type CameraIntegration = zod.infer<typeof CameraIntegration>;

export const CameraStatus = zod.object({
  connected: zod.boolean(),
  message: zod.string(),
});

export type CameraStatus = zod.infer<typeof CameraStatus>;

export const CameraIntrinsics = zod.object({
  width: zod.number(), // pixels
  height: zod.number(), // pixels
  // Focal length
  fx: zod.number(),
  fy: zod.number(),
  // Principal point
  ppx: zod.number(),
  ppy: zod.number(),
  error: zod.number().optional(),
});

export type CameraIntrinsics = zod.infer<typeof CameraIntrinsics>;
