import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import type { Equipment } from '@sb/remote-control/types';
import { onGetAllEquipment, onGetAllEnabledEquipment } from '@sbrc/services';

interface UseEquipmentArgs {
  includeDisabled?: boolean;
}

/**
 * returns undefined while loading
 */
export function useEquipment({ includeDisabled }: UseEquipmentArgs = {}):
  | Equipment.ConvertedResponse[]
  | undefined {
  const [equipment, setEquipment] = useState<
    Equipment.ConvertedResponse[] | undefined
  >();

  useEffect(() => {
    let prevEquipment: Equipment.ConvertedResponse[] | undefined;

    if (includeDisabled) {
      return onGetAllEquipment((newEquipment) => {
        if (!isEqual(prevEquipment, newEquipment)) {
          prevEquipment = newEquipment;
          setEquipment(newEquipment);
        }
      });
    }

    return onGetAllEnabledEquipment((newEquipment) => {
      if (!isEqual(prevEquipment, newEquipment)) {
        prevEquipment = newEquipment;
        setEquipment(newEquipment);
      }
    });
  }, [includeDisabled]);

  return equipment;
}
