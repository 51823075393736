import { useEffect, useRef } from 'react';

type ListenEvent = MouseEvent | TouchEvent;
type EventHandler = (event: ListenEvent) => void;

export function useClickOutside(
  refs: Array<HTMLElement | null | React.RefObject<HTMLElement>>,
  handler: EventHandler | undefined,
) {
  // store the element refs and handler in another ref, to avoid event listener teardown/setup on every render
  const ref = useRef({ refs, handler });

  useEffect(() => {
    ref.current = { refs, handler };
  });

  useEffect(() => {
    const listener = (event: PointerEvent) => {
      if (!ref.current.handler) {
        return;
      }

      // check the click wasn't on any of the elements or their descendents
      const isClickAway = ref.current.refs.every((elementOrRef) => {
        const element =
          elementOrRef instanceof HTMLElement
            ? elementOrRef
            : elementOrRef?.current;

        return !element || !element.contains(event.target as Node);
      });

      if (isClickAway) {
        ref.current.handler(event);
      }
    };

    document.addEventListener('pointerdown', listener, {
      capture: true,
      passive: true,
    });

    return () => {
      document.removeEventListener('pointerdown', listener, { capture: true });
    };
  }, []);
}
