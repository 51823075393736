import { useCallback } from 'react';

import { useRoutineRunnerHandle } from './useRoutineRunnerHandle';

export const useClearMotionPlanCache = () => {
  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot: false });

  return useCallback(() => {
    routineRunnerHandle.clearMotionPlanCache();
  }, [routineRunnerHandle]);
};
