import type { NotificationData } from '@sb/feathers-types';
import type { Notification } from '@sb/remote-control/types';

export function convertNotificationResponse(
  userID: string,
): (data: NotificationData) => Notification.ConvertedResponse {
  return (data) => ({
    // `Notification.ConvertedResponse` is a discriminated union of different notification types
    // but that isn't replicated in the Zod `NotificationData` type, which is
    // a plain interface with optional props. So we need to assert-as-any here
    ...(data as any),
    createdAt: new Date(data.createdAt),
    status: data.statusByUserID?.[userID] ?? 'new',
  });
}
