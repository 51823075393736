import type { RoutineData } from '@sb/feathers-types';
import * as log from '@sb/log/log';
import { Step } from '@sb/remote-control/types';

import { STEP_NAME_BY_STEP_KIND } from './constants';
import { listAllSteps } from './listAllSteps';

/**
 * Convert step configurations in the db for use in the front end
 * Ensure there is a step configuration for every step in the structure
 */
export function convertStepConfigurations(
  routineID: string,
  steps: Step.ConvertedSummary[],
  configurations: RoutineData['stepConfigurations'],
): Record<string, Step.ConvertedConfiguration> {
  const allSteps = listAllSteps(steps);

  const convertedStepConfigurations: Record<
    string,
    Step.ConvertedConfiguration
  > = {};

  for (const step of allSteps) {
    const configuration = configurations?.[step.id];

    const parsedConfiguration = Step.ConfigurationByKind.safeParse({
      stepKind: step.stepKind,
      args: configuration?.args,
    });

    if (!parsedConfiguration.success) {
      log.warn(
        'convertStepConfiguration.error',
        'Error parsing step configuration',
        {
          stepID: step.id,
          stepKind: step.stepKind,
          args: configuration?.args,
          error: parsedConfiguration.error.issues,
        },
      );
    }

    convertedStepConfigurations[step.id] = {
      stepKind: step.stepKind,
      ...(parsedConfiguration.success ? parsedConfiguration.data : null),
      description: configuration?.description || step.description,
      id: step.id,
      name: STEP_NAME_BY_STEP_KIND[step.stepKind],
      routineID,
    };
  }

  return convertedStepConfigurations;
}
