import * as zod from 'zod';

export namespace WaitForConfirmationStep {
  export const Arguments = zod.object({
    argumentKind: zod.literal('WaitForConfirmation'),
    delay: zod.number().default(5),
    message: zod.string(),
  });

  export type Arguments = zod.infer<typeof Arguments>;
}
