import { isEqual } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import type { Robot, Routine } from '@sb/remote-control/types';
import { onGetRobot, onGetRoutine } from '@sbrc/services';

/**
 * Get robot and routine data for page.
 * The robotID may be in the URL. If it isn't then get first robot.
 * The routineID may be in the URL. If it isn't then get the routine linked to the robot.
 * When `robot`/`routine` is `null`, it means the data doesn't exist.
 * When `robot`/`routine` is `undefined`, it means the request is still loading.
 */
export default function usePageRobotAndRoutine(): {
  robot: Robot.ConvertedResponse | null | undefined;
  routine: Routine.ConvertedResponse | null | undefined;
} {
  const { query } = useRouter();

  const robotID = typeof query.robotID === 'string' ? query.robotID : undefined;

  const [robot, setRobot] = useState<
    Robot.ConvertedResponse | null | undefined
  >();

  const [routine, setRoutine] = useState<
    Routine.ConvertedResponse | null | undefined
  >();

  useEffect(() => {
    const checkedSetRobot = (robotToCheck: Robot.ConvertedResponse | null) => {
      if (!robotToCheck) {
        setRobot(null);
      } else {
        setRobot((prevRobot) => {
          if (isEqual(prevRobot, robotToCheck)) {
            return prevRobot;
          }

          return robotToCheck;
        });
      }
    };

    return onGetRobot(robotID, checkedSetRobot);
  }, [robotID]);

  const waitingForRobot = robot === undefined;

  const routineID =
    (Array.isArray(query.routineID) ? query.routineID[0] : query.routineID) ??
    robot?.latestRoutineID;

  // get the routine
  useEffect(() => {
    if (waitingForRobot && !routineID) {
      return undefined;
    }

    if (!routineID) {
      setRoutine(null);

      return undefined;
    }

    const unsubscribe = onGetRoutine(routineID, setRoutine);

    return () => unsubscribe();
  }, [waitingForRobot, routineID]);

  return {
    robot,
    routine,
  };
}
