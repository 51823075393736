import type { OR2FG7FingerOrientation } from './constants';
import {
  OR2FG7_FINGER_ORIENTATION,
  OR_2FG7_DIAMETER_METERS_SLIDER_STEP,
} from './constants';
import type { OnRobot2FG7Command, OnRobot2FG7State } from './types';

export function getOR2FG7ActiveWidth(state: OnRobot2FG7State): number {
  return state.gripKind === 'outward' ? state.width.outer : state.width.inner;
}

export function convertFingerOrientationStateToRegister(
  fingerOrientation: OR2FG7FingerOrientation,
): OR2FG7_FINGER_ORIENTATION {
  return fingerOrientation === 'inward'
    ? OR2FG7_FINGER_ORIENTATION.INWARD
    : OR2FG7_FINGER_ORIENTATION.OUTWARD;
}

export function convertFingerOrientationRegisterToState(
  fingerOrientationRegister: OR2FG7_FINGER_ORIENTATION,
): OR2FG7FingerOrientation {
  return fingerOrientationRegister === OR2FG7_FINGER_ORIENTATION.INWARD
    ? 'inward'
    : 'outward';
}

export function areOR2FG7GripperPositionsEqual(
  otherRobotGripperState: OnRobot2FG7State | null,
  targetDiameterMeters: number,
  command: OnRobot2FG7Command,
) {
  if (!otherRobotGripperState) return false;

  const isGripKindTargetSynced =
    otherRobotGripperState?.gripKind === command.gripKind;

  const otherDiameter = getOR2FG7ActiveWidth(otherRobotGripperState);

  const isDiameterTargetSynced =
    Math.abs(otherDiameter - targetDiameterMeters) <
    OR_2FG7_DIAMETER_METERS_SLIDER_STEP;

  return isDiameterTargetSynced && isGripKindTargetSynced;
}
