import {
  OR_3FG15_DEFAULT_FINGER_LENGTH,
  OR_3FG15_OFFSETS,
  OR_3FG15_TO_FINGER_OFFSET,
} from '../constants';
import type {
  OnRobot3FG15Command,
  OnRobot3FG15FingerConfiguration,
} from '../types';

export function calculateOR3FG15FingerAngle({
  fingerConfiguration,
  diameter,
  gripKind,
}: {
  fingerConfiguration: OnRobot3FG15FingerConfiguration;
  // the diameter of the grip in meters
  diameter: number;
  gripKind: OnRobot3FG15Command['gripKind'];
}): number {
  let rawDiameter: number;

  if (gripKind === 'inward') {
    rawDiameter = diameter + fingerConfiguration.offsetInMeters;
  } else {
    rawDiameter = diameter - fingerConfiguration.offsetInMeters;
  }

  // determine offset from finger length and position
  const offset =
    fingerConfiguration.lengthInMeters -
    OR_3FG15_DEFAULT_FINGER_LENGTH +
    OR_3FG15_OFFSETS[fingerConfiguration.mountingPosition];

  const radius = Math.max(0, rawDiameter / 2);

  // Law of Cosines for SSS triangle
  let cosAngle =
    (offset ** 2 + OR_3FG15_TO_FINGER_OFFSET ** 2 - radius ** 2) /
    (2.0 * offset * OR_3FG15_TO_FINGER_OFFSET);

  // Ensure rounding errors aren't slightly outside [-1,1]
  cosAngle = Math.min(1.0, cosAngle);
  cosAngle = Math.max(-1.0, cosAngle);

  let angle = Math.PI - Math.acos(cosAngle);

  if (angle < 0) {
    angle += 2 * Math.PI;
  }

  angle = Math.min(Math.PI, angle);

  return angle;
}
