import { useEffect, useState } from 'react';

import type { Routine } from '@sb/remote-control/types';
import { onGetRoutineSummaries } from '@sbrc/services';

const useRoutineList = () => {
  const [routines, setRoutines] = useState<Routine.Summary[]>();

  useEffect(() => {
    const unsubscribe = onGetRoutineSummaries(setRoutines);

    return () => unsubscribe();
  }, []);

  return routines;
};

export default useRoutineList;
