import * as zod from 'zod';

export const EwellixLiftTLTConfiguration = zod.object({
  kind: zod.literal('EwellixLiftTLT'),
  name: zod.string().default('Lift TLT'),
});

export type EwellixLiftTLTConfiguration = zod.infer<
  typeof EwellixLiftTLTConfiguration
>;
