import { sumBy } from 'lodash';

import {
  invertPose,
  type CartesianPose,
  applyCompoundPose,
  tooltipCoordinatesToBaseCoordinates,
} from '@sb/geometry';
import * as log from '@sb/log';
import {
  cartesianProduct,
  isNotNull,
  isNotUndefined,
  six,
} from '@sb/utilities';

import type { ArmJointLimits } from './ArmJointLimits';
import type { ArmJointPositions } from './ArmJointPositions';
import type { ArmTarget } from './ArmTarget';
import { inverseKinematics } from './inverseKinematics';
import type { MotionPlan } from './MotionPlan';
import type {
  DeviceKinematics,
  DeviceOffsetProposal,
  MotionPlannerInterface,
} from './MotionPlannerInterface';
import type { MotionPlanRequest } from './MotionPlanRequest';
import type { TCPOffsetOption } from './TCPOffsetOption';

interface ConstructorArgs<DeviceCommand> {
  motionPlanner: MotionPlannerInterface;
  request: MotionPlanRequest;
  deviceKinematics: DeviceKinematics<DeviceCommand>[];
  onAcknowledged?: () => void;
  onWaypoint?: () => void;
  tcpOffsetOption: TCPOffsetOption;
}

const ns = log.namespace('ArmAndDeviceMotionPlanner');
export interface ArmAndDeviceMotionPlan<DeviceCommand> {
  deviceCommands: DeviceCommand[];
  motionPlan: MotionPlan;
}

/**
 * Motion planner which takes into account devices which can
 * change the position of the TCP (e.g. grippers) or arm (e.g. vertical lift)
 *
 * Each attached device returns a list of possible positions
 * it can move the arm to ("device offset proposals")
 *
 * The proposals are looped through (in order of estimated duration),
 * the motion plan request is modified to take into account the offset,
 * and we find the first offset where the motion is possible.
 *
 * Return the device command(s) which move the arm to position
 * and the motion plan from the modified request.
 */
export class ArmAndDeviceMotionPlanner<DeviceCommand> {
  private motionPlanner: MotionPlannerInterface;

  private request: MotionPlanRequest;

  private deviceKinematics: DeviceKinematics<DeviceCommand>[];

  private onAcknowledged?: () => void;

  private onWaypoint?: () => void;

  private tcpOffsetOption: TCPOffsetOption;

  public constructor(props: ConstructorArgs<DeviceCommand>) {
    this.motionPlanner = props.motionPlanner;
    this.request = props.request;
    this.deviceKinematics = props.deviceKinematics;
    this.onAcknowledged = props.onAcknowledged;
    this.onWaypoint = props.onWaypoint;
    this.tcpOffsetOption = props.tcpOffsetOption;
  }

  private calculateCombinedDuration(
    proposals: DeviceOffsetProposal<DeviceCommand>[],
  ): number {
    return sumBy(proposals, (p) => p.durationEstimateMS);
  }

  // the real joint limits don't matter too much here, we just filtering
  // before sending to the motion planner
  private static JOINT_LIMITS: ArmJointLimits = six({
    min: -2 * Math.PI,
    max: 2 * Math.PI,
  });

  private isPossiblePose(
    pose: CartesianPose,
    seedAngles: ArmJointPositions,
  ): boolean {
    return (
      inverseKinematics(
        pose,
        ArmAndDeviceMotionPlanner.JOINT_LIMITS,
        seedAngles,
      ).length > 0
    );
  }

  private getBaseOffsetProposals(): DeviceOffsetProposal<DeviceCommand>[][] {
    const hasPoseTarget = this.request.targets.some(
      (target) => 'pose' in target,
    );

    if (!hasPoseTarget) {
      return [];
    }

    return this.deviceKinematics
      .map((kinematics) => {
        const proposals = kinematics.getDeviceOffsetProposals?.();

        if (proposals) {
          return proposals;
        }

        // default proposal is use current device offset
        const deviceOffset = kinematics.getOffset?.({
          tcpOption: this.tcpOffsetOption,
        });

        if (deviceOffset) {
          return [
            {
              deviceOffset,
              command: null,
              durationEstimateMS: 0,
            },
          ];
        }

        return undefined;
      })
      .filter(isNotUndefined);
  }

  public async plan(): Promise<ArmAndDeviceMotionPlan<DeviceCommand>> {
    const proposals = this.getBaseOffsetProposals();

    // for each combination of proposals from attached devices,
    // see if we can plan a motion to the target
    if (proposals.length > 0) {
      const combinedProposals = Array.from(cartesianProduct(...proposals));

      combinedProposals.sort(
        (p1, p2) =>
          this.calculateCombinedDuration(p1) -
          this.calculateCombinedDuration(p2),
      );

      const errorMessages = new Map<string, number>();

      for (const combinedProposal of combinedProposals) {
        try {
          const deviceCommands = combinedProposal
            .map((p) => p.command)
            .filter(isNotNull);

          log.info(ns`motionPlan.attempt`, 'Try to plan motion with', {
            deviceCommands,
          });

          const motionPlan = await this.planMotionWithCombinedProposal(
            this.request,
            combinedProposal,
          );

          return {
            deviceCommands,
            motionPlan,
          };
        } catch (e) {
          errorMessages.set(e.message, (errorMessages.get(e.message) ?? 0) + 1);
        }
      }

      const errorSummary = [...errorMessages]
        .map(([message, count]) => `${count} × ${message}`)
        .join('; ');

      throw new Error(
        `Unable to plan motion from any offset proposals: ${errorSummary}`,
      );
    } else {
      const motionPlan = await this.planMotion(this.request);

      return {
        deviceCommands: [],
        motionPlan,
      };
    }
  }

  private async planMotion(request: MotionPlanRequest): Promise<MotionPlan> {
    const motionPlanResponse = this.motionPlanner.planMotion(request);

    if (this.onAcknowledged) {
      motionPlanResponse.on('acknowledged', this.onAcknowledged);
    }

    if (this.onWaypoint) {
      motionPlanResponse.on('waypoint', this.onWaypoint);
    }

    const motionPlan = await motionPlanResponse.complete();

    return motionPlan;
  }

  private async planMotionWithCombinedProposal(
    request: MotionPlanRequest,
    combinedProposal: DeviceOffsetProposal<DeviceCommand>[],
  ): Promise<MotionPlan> {
    const modifiedTargets = request.targets.map<ArmTarget>((target) => {
      if ('pose' in target) {
        let { pose } = target;

        for (const { deviceOffset } of combinedProposal) {
          switch (deviceOffset.kind) {
            case 'base':
              pose = applyCompoundPose(
                pose,
                invertPose(deviceOffset.transform),
              );

              break;
            case 'tcp':
              pose = applyCompoundPose(
                tooltipCoordinatesToBaseCoordinates(
                  invertPose(deviceOffset.transform),
                ),
                pose,
              );

              break;
            default:
              break;
          }
        }

        if (!this.isPossiblePose(pose, request.startingJointPositions)) {
          log.debug(ns`pose.impossible`, 'Pose not possible', { pose });
          throw Error('No inverse kinematics found for pose');
        }

        return {
          ...target,
          pose,
        };
      }

      return target;
    }) as MotionPlanRequest['targets'];

    const motionPlan = await this.planMotion({
      ...request,
      targets: modifiedTargets,
    });

    log.info(ns`targets.modified`, 'Targets modified', {
      from: request.targets,
      to: modifiedTargets,
    });

    return motionPlan;
  }
}
