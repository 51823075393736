import type { WaitForConfirmationStep } from '@sb/remote-control/types';
import type { WaitForConfirmationStepArguments } from '@sb/routine-runner';

export const convertWaitForConfirmationConfigurationToRoutineRunner = (
  stepID: string,
  configuration?: WaitForConfirmationStep.Arguments,
): WaitForConfirmationStepArguments => {
  if (!configuration) {
    throw new Error(
      `WaitForConfirmation step is not configured. stepID: ${stepID}`,
    );
  }

  return {
    delayMS: configuration.delay * 1000,
    message: configuration.message,
  };
};
