// Mirrored from https://github.com/standardbots/firmware-interface/blob/2a361c0d0eb3fbc086f32e1c326cf96b2776dc13/Result.h#L6
export const FirmwareResultType = {
  NoResultYet: 0,

  // No error occurred
  Success: 1,

  // should be trigger if there is an error called that is not otherwise mapped
  // below
  GenericUnmappedError: 2,
  SystemInitializationError: 3,

  // Generic errors for storage read/write failures
  StorageInitializationError: 4,
  StorageReadError: 5,
  StorageWriteError: 6,

  CANInitializationError: 7,
  CANReceiveError: 8,
  CANSendError: 9,
  CANRxFIFO0FullError: 10,
  CANRxFIFO1FullError: 11,
  CANUnusedError3: 12,
  CANUnusedError4: 13,

  // see drv8323 datasheet pages 53-54 for details on these
  MotorInitializationError: 14,
  MotorVDSOvercurrentFault: 15,
  MotorGateDriveFault: 16,
  MotorUndervoltageLockoutFault: 17,
  MotorVDSFaultHA: 18,
  MotorVDSFaultLA: 19,
  MotorVDSFaultHB: 20,
  MotorVDSFaultLB: 21,
  MotorVDSFaultHC: 22,
  MotorVDSFaultLC: 23,
  MotorOvercurrentSenseA: 24,
  MotorOvercurrentSenseB: 25,
  MotorOvercurrentSenseC: 26,
  MotorOvercurrentWarning: 108,
  MotorOvercurrentShutdown: 109,
  MotorOvertemperatureShutdown: 27,
  MotorOvertemperatureWarning: 28,
  MotorChargePumpUndervoltageFault: 29,
  MotorGateDriveFaultHA: 30,
  MotorGateDriveFaultLA: 31,
  MotorGateDriveFaultHB: 32,
  MotorGateDriveFaultLB: 33,
  MotorGateDriveFaultHC: 34,
  MotorGateDriveFaultLC: 35,
  MotorCurrentReadError: 36,

  EncoderInitializationError: 37,
  // If error bit is set, the position is not valid
  EncoderErrorBit: 38,
  // if warning bit is set, the encoder operational is close to its limits.
  // The position is still valid, but the resolution and/or accuracy might be
  // out of specification
  EncoderWarningBit: 39,
  // Signal amplitude too high. The readhead is too close to the ring or an
  // external magnetic field is present
  EncoderReadheadDistanceSmallWarning: 40,
  // Signal amplitude low - The distance between the readhead and the ring is
  // too large
  EncoderReadheadDistanceLargeWarning: 41,
  // Signal lost - The readhead is out of alignment with the ring or the ring is
  // damaged.
  EncoderReadheadSignalLost: 42,
  // The readhead temperature is out of specified range
  EncoderTemperatureOutOfRange: 43,
  // Power supply error. The readhead power supply voltage is out of specified
  // range.
  EncoderVoltageOutOfRange: 44,
  // Malfunction inside the circuitry or inconsistent calibration data is
  // detected. To reset the System error bit try to cycle the power supply while
  // the rise time is shorter than 20 ms.
  EncoderSystemError: 45,
  // Magnetic pattern error. A stray magnetic field is present or metal
  // particles are present between the readhead and the ring or radial
  // positioning between the readhead and the ring is out of tolerances.
  EncoderMagneticPatternError: 46,
  // Acceleration error. The position data changed too fast. A stray magnetic
  // field is present or metal particles are present between the readhead and
  // the ring
  EncoderAccelerationError: 47,
  EncoderErrorBitThirteen: 78,
  EncoderMagneticSensorNeedsPowerCycle: 79,
  EncoderSensorReadingError: 80,
  EncoderNotConfiguredProperly: 81,
  EncoderRotatedDuringPowerOff: 82,
  EncoderReadheadDistanceSmallError: 83,
  EncoderReadheadDistanceLargeError: 84,
  // if we don't receive a continuous torque response from the encoder as
  // expected, we send this. this could mean that the encoder was off/restarting
  // (e.g. due to the 5V line being out of range).
  EncoderDidntSendAnything: 85,
  // the CRC calculated for the Biss message's data does not match the CRC
  // received in the same message
  EncoderChecksumMismatch: 48,
  EncoderTimedOut: 49,
  EncoderReadInProgress: 50,
  EncoderNotReady: 51,
  EncoderUnexpectedStartBits: 52,

  HallStateTransitionNotFound: 53,
  HallInvalidState: 54,
  HallLockBusy: 55,
  // for any time the brake emergency brakes
  EmergencyBraking: 56,
  // for when no torque has been received within the maximum period
  TorqueNotReceivedWithinTimeout: 57,
  EncoderNotReadSuccessfullyWithinTimeout: 58,
  EncoderAtHardLimit: 59,
  NoJointNumberAssigned: 60,
  OperationNotAllowedWhenBrakeDisengaged: 61,
  FirmwareStartingUp: 62,
  HardwareControlledOvertemperatureShutdown: 63,

  // the firmware running doesn't seem to be intended for this board, so
  // firmware is not allowing the motor to run.
  FirmwareBoardMismatch: 64,

  // the encoder over biss-c has only a 6 bit checksum (and over UART has no
  // checksum) so occasionally if data is bad (e.g. due to noise, or due to an
  // encoder reset, e.g. caused by the clock line varying too much) we could
  // receive a position that is invalid despite the checksum being valid
  // (because there is a 1 in 64 chance of that happening with a 6 bit
  // checksum). So we automatically throw out values that indicate a velocity
  // that is way too high to be reasonable / breaks the laws of physics.
  EncoderValueDiscardedDueToImprobableVelocity: 65,

  WarningTorqueNotReceivedForFiveMs: 66,
  WarningTorqueNotReceivedForSevenMs: 67,
  WarningTorqueNotReceivedForFifteenMs: 68,
  WarningTorqueNotReceivedForThirtyMs: 69,
  WarningTorqueNotReceivedForFiftyMs: 70,
  WarningTorqueNotReceivedForSeventyFiveMs: 71,
  WarningTorqueNotReceivedForOneHundredMs: 72,

  EncoderCalibrationSuccess: 73,
  EncoderCalibrationNotRequestedYet: 74,
  EncoderCalibrationAwaitingResults: 75,
  EncoderCalibrationTimeout: 76,
  EncoderCalibrationInstallationOutOfMechanicalTolerance: 77,
  // reserved ids for the various arm cortex fault types
  SystemHardFault: 86,
  SystemMemManageFault: 87,
  SystemBusFault: 88,
  SystemUsageFault: 89,
  IgnoredNormalDesiresReceivedWhenInAssemblyTestMode: 90,
  IgnoredAssemblyCurrentControlReceivedWhenNotInAssemblyTestMode: 91,
  // NOTE: above IDS are not in order. check all of them to find the next
  // available ID.
  RotorEncoderWarning: 92,
  RotorEncoderError: 93,
  MotorElectricalAngleErrorHigh: 94,

  // the motor board temperature itself (the motor's coil temperature has
  // separate messages above)
  MotorBoardOvertemperatureShutdown: 95,
  MotorBoardOvertemperatureWarning: 96,

  M4PWMInitializationError: 97,
  M4EncoderInitializationError: 98,
  M4HallInitializationError: 99,
  M4Error: 100,
  M4HardFault: 101,
  M4MemManageFault: 102,
  M4SystemBusFault: 103,
  M4SystemUsageFault: 104,
  M4MissedMessagesFromM7: 105,
  M4QuadEncoderMissedIndexPulse: 106,
  M4QuadEncoderMissingTicks: 107,
  M4CoreNotBootedUp: 110,

  // Modbux exception codes. See the protocol:
  // https://modbus.org/docs/Modbus_Application_Protocol_V1_1b.pdf
  ModbusIllegalFunction: 111,
  ModbusIllegalDataAddress: 112,
  ModbusIllegalDataValue: 113,
  ModbusTargetDeviceFailure: 114,
  ModbusAcknowledge: 115,
  ModbusTargetDeviceBusy: 116,
  ModbusMemoryParityError: 117,
  ModbusGatewayPathUnavailable: 118,
  ModbusGatewayFailedToRespond: 119,

  PowerBoardMeanwellError: 120,
  PowerBoardRunningOnBattery: 121,
  PowerBoardCuttingPower: 122,
  ResetWindupTorqueTooHigh: 123,
  WindupDataSent: 124,
  InvalidStateForRequest: 125,

  // More hardware faults
  StackOverflow: 126,
  MallocError: 127,

  // Safety Watchdog Events
  SafetyWatchdogPositionError: 128,
  SafetyWatchdogTorqueError: 129,
  SafetyWatchdogVelocityError: 130,

  IMUTxError: 131,
  IMURxError: 132,

  BrakeOvertemperatureError: 133,

  // Motor board refactor additional errors
  EncoderTXError: 134,
  EncoderRXError: 135,
  EncoderZeroingError: 136,
  EncoderUnlockSequenceError: 137,

  TestBoardInvalidPinForOperation: 138,
};
