export const joints = [
  {
    jointNumber: 0,
    jointName: 'Joint 0',
  },
  {
    jointNumber: 1,
    jointName: 'Joint 1',
  },
  {
    jointNumber: 2,
    jointName: 'Joint 2',
  },
  {
    jointNumber: 3,
    jointName: 'Joint 3',
  },
  {
    jointNumber: 4,
    jointName: 'Joint 4',
  },
  {
    jointNumber: 5,
    jointName: 'Joint 5',
  },
  {
    jointNumber: 6,
    jointName: 'Safety Power Board',
  },
  {
    jointNumber: 10,
    jointName: 'EE Board',
  },
  {
    jointNumber: 12,
    jointName: 'IO Board',
  },
  {
    jointNumber: 13,
    jointName: 'Power Board',
  },
] as const;

export type JointNumber = (typeof joints)[number]['jointNumber'];

export const lookupJoint = (jointNumber: JointNumber) =>
  joints.find((joint) => joint.jointNumber === jointNumber);

// Customer bots have bootloader vulnerability to flashing wrong firmware to io causing a bricked Control Box
export function getAllJointsExceptIOboard() {
  return joints.filter((joint) => joint.jointName !== 'IO Board');
}

export const getAllJointNumbersExceptIOBoard = getAllJointsExceptIOboard().map(
  (joint) => joint.jointNumber,
);
