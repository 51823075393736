import { useEffect, useState } from 'react';

import type { Robot } from '@sb/remote-control/types';
import { onGetRobot } from '@sbrc/services';

/**
 * Hook to listen for robot data.
 *
 * Returns null while data is being fetched.
 *
 * If no robotID is known yet (part of state or optional prop), this will
 * make no request and return null.
 */
export default function useRobotData(
  robotID: string | null,
): Robot.ConvertedResponse | null | undefined {
  /**
   * When 'robotData' is 'null', it means the robot doesn't exist.
   * When 'robotData' is 'undefined', it means the request is still loading.
   */
  const [robotData, setRobotData] = useState<
    Robot.ConvertedResponse | null | undefined
  >();

  useEffect(() => {
    if (!robotID) return () => {};

    const unsubscribe = onGetRobot(robotID, setRobotData);

    return () => unsubscribe();
  }, [robotID]);

  return robotData;
}
