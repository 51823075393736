import * as zod from 'zod';

export const CustomIOConfiguration = zod.object({
  kind: zod.literal('CustomIO'),
  name: zod.string().default('IO Device'),
  ports: zod
    .array(
      zod.object({
        highSignalName: zod.string(),
        kind: zod.union([zod.literal('Input'), zod.literal('Output')]),
        lowSignalName: zod.string(),
        name: zod.string(),
        port: zod.number(),
        label: zod.string().optional(),
      }),
    )
    .default([]),
});

export type CustomIOConfiguration = zod.infer<typeof CustomIOConfiguration>;
