import type { User } from '@sb/remote-control/types';

import { getCurrentUserID } from '../auth';

import { getUsersService } from './utils';

export async function toggleUserDisabled(data: User.Disable) {
  await getUsersService().patch(data.userID, {
    disabledBy: data.isDisabled ? getCurrentUserID() : null,
  });
}
