import { useMemo } from 'react';

import { joints } from '@sb/utilities/src/joints';
import type { softwareUpdate } from '@sbrc/hooks';
import { useRobotIdentity } from '@sbrc/services/feathers-client/robot-identity';

export type JointStatusKind =
  | 'requiresUpdate'
  | 'installing'
  | 'upToDate'
  | 'failed';
type JointNumber = 0 | 1 | 2 | 3 | 10 | 5 | 4 | 6 | 12 | 13;
type JointName =
  | 'Joint 0'
  | 'Joint 1'
  | 'Joint 2'
  | 'Joint 3'
  | 'Joint 4'
  | 'Joint 5'
  | 'Power Board'
  | 'EE Board'
  | 'IO Board'
  | 'Safety Power Board';

export interface JointUpdateStatus {
  jointNumber: JointNumber;
  jointName: JointName;
  status: JointStatusKind;
}

export interface FirmwareBuildData {
  jointUpdateStatus: JointUpdateStatus[];
  jointUpdateStatusExcludingIO: JointUpdateStatus[];
  canUpdateJointFirmware: boolean;
  shouldIndicateFirmwareExcludingIOMismatch: boolean;
  shouldIndicateFirmwareMismatch: boolean;
  isJointInstallInProgress: boolean;
}

export function useFirmwareUpdateStatus(
  buildData: softwareUpdate.SoftwareBuildData,
): FirmwareBuildData {
  const robotIdentity = useRobotIdentity();

  const isRealRobot = robotIdentity?.robotOperationMode === 'live';

  const jointUpdateStatus: JointUpdateStatus[] = useMemo(() => {
    return joints.map(({ jointNumber, jointName }) => {
      const activeFirmware = buildData.activeFirmwareInfo?.find(
        (j) => j.jointNumber === jointNumber,
      );

      const requiresUpdate =
        activeFirmware?.buildInfo?.buildId !== buildData.activeBuildID;

      const installFailed = activeFirmware?.status === 'failed';

      let status: JointStatusKind = 'requiresUpdate';

      if (installFailed) {
        status = 'failed';
      }

      if (activeFirmware?.status === 'installing') {
        status = 'installing';
      } else if (!requiresUpdate && !installFailed) {
        status = 'upToDate';
      }

      return {
        jointNumber,
        jointName,
        status,
      };
    });
  }, [buildData.activeBuildID, buildData.activeFirmwareInfo]);

  const jointUpdateStatusExcludingIO = jointUpdateStatus.filter(
    (joint) => joint.jointName !== 'IO Board',
  );

  const canUpdateJoint = (joint: JointUpdateStatus) =>
    joint.status === 'requiresUpdate' || joint.status === 'failed';

  const canUpdateJointFirmware = useMemo(() => {
    return jointUpdateStatus.some(canUpdateJoint);
  }, [jointUpdateStatus]);

  const canUpdateJointFirmwareExcludingIO = useMemo(() => {
    return jointUpdateStatusExcludingIO.some(canUpdateJoint);
  }, [jointUpdateStatusExcludingIO]);

  const isJointInstallInProgress = jointUpdateStatus.some(
    ({ status }) => status === 'installing',
  );

  const shouldIndicateFirmwareMismatch = isRealRobot && canUpdateJointFirmware;

  const shouldIndicateFirmwareExcludingIOMismatch =
    isRealRobot && canUpdateJointFirmwareExcludingIO;

  return {
    jointUpdateStatus,
    jointUpdateStatusExcludingIO,
    canUpdateJointFirmware,
    shouldIndicateFirmwareMismatch,
    shouldIndicateFirmwareExcludingIOMismatch,
    isJointInstallInProgress,
  };
}
