import cx from 'classnames';

export interface SettingsGroupItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isSeparated?: boolean;
}

export function SettingsGroupItem({
  className,
  isSeparated,
  ...rest
}: SettingsGroupItemProps) {
  return (
    <div
      role={rest.onClick ? 'button' : undefined}
      {...rest}
      className={cx(
        className,
        // the border is inset from the edge of the element by 16px
        !isSeparated &&
          '[border-image:linear-gradient(90deg,transparent_16px,var(--divider-primary)_16px_calc(100%_-_16px),transparent_calc(100%_-_16px))_1_0]',
        isSeparated && 'tw-rounded-10',
        isSeparated && 'tw-surface-elevated',
        isSeparated && 'tw-shadow-30',
        'tw-px-16',
        'tw-flex',
        'tw-flex-row',
        'tw-items-center',
        'tw-min-h-[44px]',
        'tw-text-ellipsis',
        'tw-icon-28',
        'tw-gap-8',
      )}
    />
  );
}
