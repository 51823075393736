import type { OR3FG15GripKind } from '../constants';
import type { OnRobot3FG15FingerConfiguration } from '../types';

import { calculateOR3FG15DiameterFromFingerAngle } from './calculateOR3FG15DiameterFromFingerAngle';

/*
 * The gripper reports the current diameter according to its own
 * current grip kind, which is not the same scale we want to show
 * it on, so we convert to the grip kind of the target gripper form.
 *
 * This uses the defaults because it's less simple to have to provide
 * them every time.
 */
export const calculateOR3FG15DefaultDiameterWithFingerAngle = (
  fingerConfiguration: OnRobot3FG15FingerConfiguration,
  fingerAngle: number,
  gripKind: OR3FG15GripKind,
): number => {
  if (typeof fingerAngle === 'undefined') {
    return NaN;
  }

  return calculateOR3FG15DiameterFromFingerAngle({
    fingerConfiguration,
    angle: fingerAngle,
    gripKind,
  });
};
