import { map } from 'rxjs/operators';

import { mapPaginated } from '@sb/feathers-types';
import type { Routine, Unsubscribe } from '@sb/remote-control/types';
import {
  convertRoutineSummaryWithDefaultDescription,
  ROUTINE_DATA_SUMMARY_FIELDS,
} from '@sb/remote-control/util/convertRoutineSummary';

import { getRoutinesService } from './utils';

export function onGetRoutineSummaries(
  onSnapshot: (routines: Routine.Summary[]) => void,
): Unsubscribe {
  const subscription = getRoutinesService()
    .watch()
    .find({
      query: {
        $sort: { updatedAt: -1 },
        $select: ROUTINE_DATA_SUMMARY_FIELDS,
      },
    })
    .pipe(
      map((response) => {
        const routines = mapPaginated(
          response,
          convertRoutineSummaryWithDefaultDescription,
        );

        return routines;
      }),
    )
    .subscribe(onSnapshot);

  return () => subscription.unsubscribe();
}
