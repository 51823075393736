import * as log from '@sb/log';

import type { GripperRange } from '../../gripper-general';
import type { OR3FG15CommandKind, OR3FG15GripKind } from '../constants';
import type { OnRobot3FG15FingerConfiguration } from '../types';

import { calculateOR3FG15DiameterRange } from './calculateOR3FG15DiameterRange';

const ns = log.namespace('integrations.or3fg15.calculateRange');

const mm = (x: number) => Number((x * 1000).toFixed(1));

interface OR3FG15HWandCalculatedDiameterRange {
  gripKind: OR3FG15GripKind;
  fingerConfiguration: OnRobot3FG15FingerConfiguration;
  rawRangeMeters: GripperRange;
  actuationKind?: OR3FG15CommandKind;
  reason?: string;
}

export function logOR3FG15HWAndCalculatedDiameterRange({
  gripKind,
  fingerConfiguration,
  rawRangeMeters,
  actuationKind = 'grip',
  reason,
}: OR3FG15HWandCalculatedDiameterRange) {
  // calculate what we expect the range to be based on the configuration
  const {
    [gripKind]: { min: calculatedMin, max: calculatedMax },
  } = calculateOR3FG15DiameterRange({
    fingerConfiguration,
    actuationKind,
  });

  const { min: gripperMin, max: gripperMax } = rawRangeMeters;

  const gripperHWMinMM = mm(gripperMin);
  const gripperHWMaxMM = mm(gripperMax);

  const calculatedHWMinMM = mm(calculatedMin);
  const calculatedHWMaxMM = mm(calculatedMax);

  const gripperRangeMessage = `Ranges on hardware and calculated; hardware range: ${gripperHWMinMM}-${gripperHWMaxMM}mm; calculated range: ${calculatedHWMinMM}-${calculatedHWMaxMM}mm.`;

  log.info(ns`calculated.diameter.ranges`, 'OR3FG15 Range Diameter', {
    range: gripperRangeMessage,
    gripKind,
    actuationKind,
    fingerConfiguration,
    reason,
  });
}
