import cx from 'classnames';

import type { GaugeProps } from './Gauge';
import { Gauge } from './Gauge';
import { calculateClampedRoundedValue, calculateFraction } from './util';

export const INTERVAL_OPTIONS = {
  intervalMs: 50,
  delayMs: 250,
  leading: true,
};

export interface GaugeSliderProps extends Omit<GaugeProps, 'onChange'> {
  step: number;
  onChange: (value: number) => void;
  setIsSliding?: (isSliding: boolean) => void;
  isDisabled?: boolean;
}

export function GaugeSlider({
  min,
  max,
  step,
  onChange,
  setIsSliding,
  isDisabled,
  className,
  ...rest
}: GaugeSliderProps) {
  const handlePointer: React.PointerEventHandler<HTMLDivElement> = (event) => {
    const { left, right } = event.currentTarget.getBoundingClientRect();
    const newFraction = calculateFraction(event.clientX, left, right);
    const newValue = min + newFraction * (max - min);

    const newClampedValue = calculateClampedRoundedValue(
      newValue,
      min,
      max,
      step,
    );

    onChange(newClampedValue);
  };

  return (
    <Gauge
      min={min}
      max={max}
      role="slider"
      aria-disabled={isDisabled}
      onPointerDown={(event) => {
        if (!isDisabled && event.buttons === 1) {
          event.currentTarget.setPointerCapture(event.pointerId);
          setIsSliding?.(true);
          handlePointer(event);
        }
      }}
      onPointerMove={(event) => {
        if (
          !isDisabled &&
          event.currentTarget.hasPointerCapture(event.pointerId)
        ) {
          handlePointer(event);
        }
      }}
      onPointerCancel={() => setIsSliding?.(false)}
      onPointerUp={() => setIsSliding?.(false)}
      className={cx(
        className,
        'tw-cursor-pointer',
        'aria-disabled:tw-cursor-not-allowed',
        'aria-disabled:tw-opacity-40',
      )}
      {...rest}
    />
  );
}
