import cx from 'classnames';
import { useState } from 'react';

import type { IconKind } from '../../icons';
import { Icon } from '../../icons';
import type { ComponentColor, PopperProps } from '../../utility';
import { Popper, useComponentColorClassName } from '../../utility';
import { BUTTON_COLOR_CLASSES } from '../button/buttonColor';

interface NavigationBarMoreMenu
  extends Omit<PopperProps, 'isOpen' | 'onClose' | 'onClick'> {
  color?: ComponentColor;
  iconKind?: IconKind;
}

export function NavigationBarMenuButton({
  color,
  iconKind = 'ellipsisCircle',
  ...rest
}: NavigationBarMoreMenu) {
  const [isOpen, setIsOpen] = useState(false);

  const colorClassName = useComponentColorClassName(
    color,
    BUTTON_COLOR_CLASSES.Flat,
  );

  return (
    <Popper
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onClick={() => setIsOpen(!isOpen)}
      placement="bottom-end"
      skidding={3}
      offset={-4}
      {...rest}
    >
      <div className={cx('tw-p-8', colorClassName)}>
        <Icon kind={iconKind} />
      </div>
    </Popper>
  );
}
