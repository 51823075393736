/**
 * State of gripper at the start of planner request
 */
import * as zod from 'zod';

import { CustomGripperState } from '../CustomGripper/types';
import { NoGripperState } from '../NoGripper/types';
import { OnRobot2FG7State } from '../OnRobot2FG7/types';
import { OnRobot3FG15State } from '../OnRobot3FG15/types';
import { OnRobotDualQuickChangerState } from '../OnRobotDualQuickChanger/types';
import { OnRobotScrewdriverState } from '../OnRobotScrewdriver/types';
import { OnRobotVGP20State } from '../OnRobotVGP20/types';

export const GripperStateNonNull = zod.union([
  OnRobot2FG7State,
  OnRobot3FG15State,
  OnRobotScrewdriverState,
  OnRobotDualQuickChangerState,
  OnRobotVGP20State,
  CustomGripperState,
  NoGripperState,
]);

export const GripperState = GripperStateNonNull.nullable();

export type GripperStateNonNull = zod.infer<typeof GripperStateNonNull>;
export type GripperState = zod.infer<typeof GripperState>;
