import * as zod from 'zod';

import { ArmPosition } from '@sb/routine-runner';

import { MotionSpeed } from './motionSpeed';

export namespace PressButtonStep {
  export const Arguments = zod.object({
    argumentKind: zod.literal('PressButton'),
    motionSpeed: MotionSpeed.optional(),
    prePressTarget: ArmPosition.nullable().default(null),
    pressDurationSeconds: zod.number().default(0.3),
    pressTarget: ArmPosition.nullable().default(null),
  });

  export type Arguments = zod.infer<typeof Arguments>;
}
