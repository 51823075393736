import { isEqual } from 'lodash';

import type { ArmJointPositions } from '@sb/motion-planning';
import type { RoutineRunnerState } from '@sb/routine-runner';
import { convertJointAngles } from '@sbrc/utils';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getJointAnglesDegrees(
  state: RoutineRunnerState | null,
): ArmJointPositions | null {
  return state
    ? convertJointAngles.toDegrees(state.kinematicState.jointAngles)
    : null;
}

/**
 * Get the joint angles from the live robot or Vizbot in degrees to 1 decimal place
 */
export function useRobotJointAnglesDegrees(
  args: UseRoutineRunnerHandleArguments,
): ArmJointPositions | null {
  return useRobotState<ArmJointPositions | null>(
    args,
    getJointAnglesDegrees,
    // it is valid to use `isEqual` to compare joint angles because they are rounded to 1dp
    isEqual,
  );
}
