import cx from 'classnames';

import { Icon } from '../../icons';

import type { ButtonProps } from './Button';
import { Button } from './Button';

export interface ButtonCloseProps extends ButtonProps {
  classNameIcon?: string;
}

export const ButtonClose = ({ classNameIcon, ...other }: ButtonCloseProps) => {
  return (
    <Button
      variant="Flat"
      aria-label="close"
      data-testid="close-button"
      className="tw-icon-28 disabled:tw-opacity-60"
      {...other}
    >
      <Icon
        kind="xmarkCircleTransparentFill"
        className={cx('tw-mt-[3px]', 'tw-text-label-primary', classNameIcon)}
      />
    </Button>
  );
};
