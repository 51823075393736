import { calculateOR2FG7DefaultWidthRangeMeters } from './calculateOR2FG7DefaultWidthRangeMeters';
import type { OR2FG7GripKind, OR2FG7FingerOrientation } from './constants';
import {
  INWARD_MOUNT_INWARD_GRIP_WIDTH_RANGE_METERS,
  INWARD_MOUNT_OUTWARD_GRIP_WIDTH_RANGE_METERS,
  OUTWARD_MOUNT_INWARD_GRIP_WIDTH_RANGE_METERS,
  OUTWARD_MOUNT_OUTWARD_GRIP_WIDTH_RANGE_METERS,
} from './constants';

interface FingerPositionWidthRangeCalcProps {
  gripKind: OR2FG7GripKind;
  fingerPosition: OR2FG7FingerOrientation;
}

export function calculateOR2FG7FingerPositionRangeMeters({
  gripKind,
  fingerPosition,
}: FingerPositionWidthRangeCalcProps) {
  const { min, max } = calculateOR2FG7DefaultWidthRangeMeters({
    gripKind,
  });

  if (fingerPosition === 'outward') {
    if (gripKind === 'inward') {
      return OUTWARD_MOUNT_INWARD_GRIP_WIDTH_RANGE_METERS;
    }

    if (gripKind === 'outward') {
      return OUTWARD_MOUNT_OUTWARD_GRIP_WIDTH_RANGE_METERS;
    }
  }

  if (fingerPosition === 'inward') {
    if (gripKind === 'inward') {
      return INWARD_MOUNT_INWARD_GRIP_WIDTH_RANGE_METERS;
    }

    if (gripKind === 'outward') {
      return INWARD_MOUNT_OUTWARD_GRIP_WIDTH_RANGE_METERS;
    }
  }

  return { min, max };
}
