import cx from 'classnames';

export interface NavigationBarProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  contentLeft?: React.ReactNode;
  contentRight?: React.ReactNode;
}

export function NavigationBar({
  className,
  children,
  contentLeft,
  contentRight,
  ...rest
}: NavigationBarProps) {
  return (
    <header
      {...rest}
      className={cx(
        className,
        'tw-grid',
        'tw-flex-none',
        // middle cell (`children`) will be centered as much as possible
        'tw-grid-cols-[minmax(min-content,1fr)_minmax(0,max-content)_minmax(min-content,1fr)]',
        'tw-h-52',
        'tw-items-center',
        'tw-gap-10',
        'tw-text-17',
        'tw-px-16',
      )}
    >
      <div className={cx('tw-flex', 'tw-items-center')}>{contentLeft}</div>
      <h2
        className={cx('tw-font-medium', 'tw-text-center', 'tw-text-ellipsis')}
      >
        {children}
      </h2>
      <div className={cx('tw-flex', 'tw-items-center', 'tw-justify-end')}>
        {contentRight}
      </div>
    </header>
  );
}
