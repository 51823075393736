import type { DeviceKind, AnyDevice, NetworkDeviceRequest } from '../device';
import { NetworkDevice as NetworkDeviceInterface } from '../NetworkDeviceInterface';

const END_EFFECTOR_KINDS: DeviceKind[] = [
  'OnRobot3FG15',
  'OnRobot2FG7',
  'NoGripper',
  'OnRobotDualQuickChanger',
  'OnRobotScrewdriver',
  'OnRobotVGP20',
  'CustomGripper',
];

export const NETWORK_DEVICE: DeviceKind[] = ['ModbusTCPServer'];

export const isEndEffector = (kind: DeviceKind) => {
  return END_EFFECTOR_KINDS.includes(kind);
};

export const isDynamicBase = (kind: DeviceKind) => {
  return kind === 'EwellixLiftTLT';
};

export const getNetworkDevices = (allDevices: AnyDevice[]) => {
  return allDevices.filter(
    (device) => device instanceof NetworkDeviceInterface,
  ) as NetworkDeviceRequest[];
};
