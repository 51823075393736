/* eslint-disable max-classes-per-file */
import * as axiosAPI from './codegen/typescript-axios';

type StandardBotsAPIConfig = {
  url: string;
  token?: string;
  robotKind?: 'live' | 'simulated';
};

class BrakesAPI extends axiosAPI.MovementBrakesApi {
  public brake() {
    return this.setBrakesState({ state: 'engaged' });
  }

  public unbrake() {
    return this.setBrakesState({ state: 'disengaged' });
  }

  public estop(reason: string) {
    return this.engageEmergencyStop({ reason });
  }
}

class RoutinesVariablesAPI extends axiosAPI.RoutineEditorVariablesApi {}

class RoutinesAPI {
  public constructor(private config: axiosAPI.Configuration) {}

  public get routines() {
    return new axiosAPI.RoutineEditorRoutinesApi(this.config);
  }

  public get variables() {
    return new RoutinesVariablesAPI(this.config);
  }
}

export class StandardBotsSDK {
  public constructor(private readonly config: StandardBotsAPIConfig) {}

  private getAxiosConfig(): axiosAPI.Configuration {
    return {
      basePath: this.config.url,
      apiKey: this.config.token,
      isJsonMime: () => true,
      baseOptions: {
        headers: {
          robot_kind: this.config.robotKind ?? 'simulated',
        },
      },
    };
  }

  public get brakes(): BrakesAPI {
    return new BrakesAPI(this.getAxiosConfig());
  }

  public get routines(): RoutinesAPI {
    return new RoutinesAPI(this.getAxiosConfig());
  }
}
