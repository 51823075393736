import { useEffect } from 'react';

import type { Routine } from '@sb/remote-control/types';
import type { RoutineRunnerHandle } from '@sbrc/services';
import { getVizbotRoutineRunnerHandle } from '@sbrc/services';
import { getRobotPosition } from '@sbrc/utils';

import { useLiveRoutineRunnerHandle } from './useLiveRoutineRunnerHandle';

export function useVizbotRoutineRunnerHandle(): RoutineRunnerHandle {
  return getVizbotRoutineRunnerHandle();
}

export const useForceSyncPositionsToVizbot = () => {
  const vizbot = useVizbotRoutineRunnerHandle();
  const liveRunner = useLiveRoutineRunnerHandle();

  useEffect(() => {
    const positions = getRobotPosition(liveRunner)?.jointAngles;

    if (positions) {
      vizbot.vizbotOnlySetJointPositions(positions);
    }
  }, [vizbot, liveRunner]);
};

export const useSyncRoutineEquipmentToVizbot = (
  routine: Routine.ConvertedResponse | null | undefined,
) => {
  const vizbot = useVizbotRoutineRunnerHandle();

  const equipment = routine?.equipment;

  useEffect(() => {
    if (equipment) {
      vizbot.vizbotOnlyResetEquipmentList(equipment);
    }
  }, [vizbot, equipment]);
};
