import { asPaginated } from '@sb/feathers-types';
import type { TaggedRoutineStepVariables } from '@sb/routine-runner';

import {
  getRoutineRunnerVariablesService,
  getRoutineRunnerStateService,
} from './utils';

type Variables = Record<string, TaggedRoutineStepVariables>;

export async function getRoutineRunnerVariables(
  robotID: string,
  stepID: string,
): Promise<Variables | null> {
  const variablesResponse = await getRoutineRunnerVariablesService().find({
    query: { compositeID: `${robotID}:${stepID}`, $limit: 1 },
  });

  const stepVariables = asPaginated(variablesResponse).data[0]?.variables;

  if (stepVariables) {
    return stepVariables as Variables;
  }

  const stateResponse = await getRoutineRunnerStateService().find({
    query: { robotID, $limit: 1 },
  });

  const stateVariables = asPaginated(stateResponse).data[0]?.variables;

  if (stateVariables) {
    return stateVariables as Variables;
  }

  return null;
}
