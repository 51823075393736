import cx from 'classnames';

import type { IconKind } from '../../icons';
import { Icon } from '../../icons';

export interface MenuItemProps extends React.LiHTMLAttributes<HTMLLIElement> {
  iconKind?: IconKind;
  secondaryIconKind?: IconKind;
  iconKindClassName?: string;
  disabled?: boolean;
}

export function MenuItem({
  className,
  iconKind,
  secondaryIconKind,
  iconKindClassName,
  children,
  onClick,
  disabled,
  ...rest
}: MenuItemProps) {
  return (
    <li
      role={onClick ? 'button' : undefined}
      aria-disabled={disabled}
      {...rest}
      onClick={disabled ? undefined : onClick}
      className={cx(
        className,
        'tw-flex',
        'tw-flex-none',
        'tw-gap-8',
        'tw-items-center',
        'tw-h-44',
        'tw-text-17',
        'tw-icon-22',
        'tw-px-16',
        'aria-disabled:tw-cursor-not-allowed',
        'aria-disabled:tw-text-label-quaternary',
      )}
    >
      {secondaryIconKind && (
        <Icon kind={secondaryIconKind} className={iconKindClassName} />
      )}
      <div className={cx('tw-flex-1', 'tw-text-ellipsis')}>{children}</div>
      {iconKind && <Icon kind={iconKind} className={iconKindClassName} />}
    </li>
  );
}
