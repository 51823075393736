import type { GripperRange } from '../gripper-general';

import { calculateOR2FG7FingerPositionRangeMeters } from './calculateOR2FG7FingerPositionRangeMeters';
import { OR_2FG7_FINGERTIP_OFFSET_METERS_DEFAULT } from './constants';
import type {
  OR2FG7GripKind,
  OR2FG7StateFingerConfiguration,
} from './constants';

export function calculateOR2FG7WidthRangeMetersFromDeviceConfig(
  gripKind: OR2FG7GripKind,
  fingerConfig: OR2FG7StateFingerConfiguration,
): GripperRange {
  const { min, max } = calculateOR2FG7FingerPositionRangeMeters({
    gripKind,
    fingerPosition: fingerConfig.fingerPosition,
  });

  const newOffset =
    fingerConfig.fingertipOffset - OR_2FG7_FINGERTIP_OFFSET_METERS_DEFAULT;

  // inward grip (called external in OR specs) - subtract fingertip offset
  // outward grip (called internal in OR specs) - add fingertip offset
  const offset = gripKind === 'inward' ? -newOffset : newOffset;

  // if min less than 0, reset to 0
  const minimum = min + offset < 0 ? 0 : min + offset;
  const maximum = max + offset;

  return { min: minimum, max: maximum };
}
