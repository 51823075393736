import { getUnitStringConverter } from '@sb/utilities';

import type {
  CommandKind,
  CommandSpec,
  OnRobotScrewdriverCommand,
} from './types';

export const OR_SCREWDRIVER_SHORTNAME = 'Screwdriver';

export const torqueStringConvert = getUnitStringConverter('NM', 1);
export const targetForceStringConvert = getUnitStringConverter('N', 0);
export const screwLengthStringConvert = getUnitStringConverter('mm', 0);
export const zPositionStringConvert = getUnitStringConverter('mm', 0);

// available move commands excludes STOP
export const availableCommands = [
  { name: 'Tighten Screw', commandKind: 'TIGHTEN' },
  { name: 'Loosen Screw', commandKind: 'LOOSEN' },
  { name: 'Pick Up Screw', commandKind: 'PICKUP' },
] as CommandSpec[];

// defaults
export const COMMAND_KIND_DEFAULT: CommandKind = 'TIGHTEN';

/** STORED AS Nm 0.1 to 5 */
export const COMMAND_TORQUE_NM_DEFAULT = 3;

/** STORED AS N 18 to 30 N */
export const COMMAND_FORCE_N_DEFAULT = 20;

/** STORED AS MM 0 to 35 */
export const COMMAND_SCREW_LENGTH_MM_DEFAULT = 15;

/**  STORED AS MM 0 to 35 */
export const COMMAND_SHANK_POSITION_MM_DEFAULT = 30;

export const getCommandDefault = (
  overrideDefault?: Partial<OnRobotScrewdriverCommand>,
): OnRobotScrewdriverCommand => {
  return {
    kind: 'OnRobotScrewdriverCommand',
    commandKind: overrideDefault?.commandKind ?? COMMAND_KIND_DEFAULT,
    targetForce: COMMAND_FORCE_N_DEFAULT,
    targetTorque: COMMAND_TORQUE_NM_DEFAULT,
    shankPosition: COMMAND_SHANK_POSITION_MM_DEFAULT,
    screwLength:
      overrideDefault?.screwLength ?? COMMAND_SCREW_LENGTH_MM_DEFAULT,
  };
};

export const COMMAND_DEFAULT: OnRobotScrewdriverCommand = {
  kind: 'OnRobotScrewdriverCommand',
  commandKind: COMMAND_KIND_DEFAULT,
  targetForce: COMMAND_FORCE_N_DEFAULT,
  targetTorque: COMMAND_TORQUE_NM_DEFAULT,
  shankPosition: COMMAND_SHANK_POSITION_MM_DEFAULT,
  screwLength: COMMAND_SCREW_LENGTH_MM_DEFAULT,
};

// range per connectivity guide
export const FORCE_RANGE = {
  min: 18,
  max: 30,
  step: 1,
};

export const TORQUE_RANGE = {
  min: 0.1,
  max: 5,
  step: 0.1,
};

export const SCREW_LENGTH_RANGE = {
  min: 0,
  max: 35,
  step: 1,
};

export const SHANK_POSITION_RANGE = {
  min: 0,
  max: 55,
  step: 1,
};

export const OR_SCREWDRIVER_DEFAULT_TCP_TRANSFORM = {
  // rotate 90° around X, so Z points out of the screwdriver tip
  i: Math.SQRT1_2,
  j: 0,
  k: 0,
  w: Math.SQRT1_2,
  x: 0,
  y: -0.153,
  z: 0.081,
};
