import { capitalize } from 'lodash';
import { useCallback } from 'react';

import type {
  IOLevel,
  RobotToExternalPort,
  ExternalToRobotPort,
} from '@sb/routine-runner';

import { useRoutineRunnerHandle } from './useRoutineRunnerHandle';
import useToast from './useToast';

const useSetIO = ({ isVizbot = false }) => {
  const { setToast } = useToast();

  const routineRunnerHandle = useRoutineRunnerHandle({
    isVizbot,
  });

  const setInputIO = useCallback(
    async (ioPort: number, level: IOLevel) => {
      setToast({ kind: 'progress', message: 'Sending signal...' });

      const ioInputKey = `Input ${ioPort}` as ExternalToRobotPort;

      try {
        await routineRunnerHandle.vizbotOnlySetInputIO(ioInputKey, level);

        setToast({
          kind: 'success',
          message: `${capitalize(
            level,
          )} input signal was successfully sent to Port ${ioPort}`,
        });
      } catch (error) {
        setToast({ kind: 'error', message: error.message });
      }
    },
    [routineRunnerHandle, setToast],
  );

  const setOutputIO = useCallback(
    async (ioPort: number, level: IOLevel) => {
      setToast({ kind: 'progress', message: 'Sending signal...' });

      const ioOutputKey = `Output ${ioPort}` as RobotToExternalPort;

      try {
        await routineRunnerHandle.setOutputIO([{ label: ioOutputKey, level }]);

        setToast({
          kind: 'success',
          message: `${capitalize(
            level,
          )} output signal was successfully sent to Port ${ioPort}`,
        });
      } catch (error) {
        setToast({ kind: 'error', message: error.message });
      }
    },
    [routineRunnerHandle, setToast],
  );

  return { setInputIO, setOutputIO };
};

export default useSetIO;
