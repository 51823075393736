import { mapPaginated } from '@sb/feathers-types';
import type { Equipment } from '@sb/remote-control/types';
import { convertEquipmentResponse } from '@sb/remote-control/util/convertEquipmentResponse';

import { getEquipmentService } from './utils';

export async function getAllEquipment(): Promise<
  Equipment.ConvertedResponse[]
> {
  const response = await getEquipmentService().find({
    query: { $sort: { updatedAt: -1 } },
  });

  return mapPaginated(response, convertEquipmentResponse);
}

export async function getAllEnabledEquipment(): Promise<
  Equipment.ConvertedResponse[]
> {
  return (await getAllEquipment()).filter((item) => item.isEnabled);
}
