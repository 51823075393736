import type * as zod from 'zod';

import * as log from '@sb/log';
import { FailureKind } from '@sb/routine-runner/FailureKind';

import Step from '../Step';
import type { StepPlayArguments } from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

const ns = log.namespace('classifyStep');

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class ClassifyStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    this.variables = {
      latestResult: null,
    };
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    const { camera, classes, confidence, fallbackValue, regionOfInterest } =
      this.args;

    try {
      this.setVariable('latestResult', null);

      if (classes.length === 0) {
        throw new Error('No classes provided');
      }

      const image = await this.routineContext.camera.getColorFrame(camera);

      const results = await this.routineContext.vision.classify(
        classes,
        image,
        regionOfInterest,
      );

      const value =
        results[0]?.score < confidence ? fallbackValue : results[0].name;

      log.info(ns`classify.value`, 'Classify result', value);

      if (await super.shouldStopPlaying()) {
        return;
      }

      this.setVariable('latestResult', value);
    } catch (error) {
      log.error(ns`classify.play`, 'Classify failed', error);

      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'Classify',
        },
        failureReason: `${error.message}`,
        error,
      });
    }
  }
}
