import { camelCase } from 'lodash';

import type { RoutineVariable } from '@sb/remote-control/types';
import type { Space } from '@sb/routine-runner';

export function getVariablesFromRoutineSpace(space: Space.Item[]) {
  return space.map<RoutineVariable.SpaceVariableInformation>((spaceItem) => ({
    kind: 'space',
    displayName: spaceItem.name,
    spaceItem,
    variableKind: 'composite',
    variableName: `routine.positionLists['${spaceItem.id}']`,
    userIdentifier: `Space_${camelCase(spaceItem.name)}`,
  }));
}
