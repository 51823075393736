import * as zod from 'zod';

import { CartesianPose, ZERO_POSE } from '@sb/geometry';

export const CustomGripperTooltipConfiguration = zod.object({
  name: zod.string().default('Tooltip'),
  tcpTransform: CartesianPose.default(ZERO_POSE),
});

export type CustomGripperTooltipConfiguration = zod.infer<
  typeof CustomGripperTooltipConfiguration
>;

export const CustomGripperConfiguration = zod.object({
  kind: zod.literal('CustomGripper'),
  name: zod.string().default('Custom gripper'),
  tooltips: zod
    .array(CustomGripperTooltipConfiguration)
    .default([CustomGripperTooltipConfiguration.parse({})]),
});

export type CustomGripperConfiguration = zod.infer<
  typeof CustomGripperConfiguration
>;
