import type { Step, Routine } from '@sb/remote-control/types';

const checkValuesDefined = (
  _step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
): Routine.ActionRequiredStep | undefined => {
  if (stepConfiguration?.stepKind !== 'SetEnvironmentVariable')
    return undefined;
  const { args } = stepConfiguration;
  if (!args) return undefined;

  if (!args.variableID) {
    return {
      kind: 'invalidConfiguration',
      message: 'Variable ID required',
      fieldId: 'variableID',
    };
  }

  if (!args.newValue) {
    return {
      kind: 'invalidConfiguration',
      message: 'Value is required',
      fieldId: 'newValue',
    };
  }

  return undefined;
};

export const environmentVariablesValidators = [checkValuesDefined];
