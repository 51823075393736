import { useState } from 'react';

import { Button } from '../button';

import { Modal } from './Modal';
import { SimpleModalContent } from './SimpleModalContent';

interface UseConfirmModalProps {
  title?: string;
  body?: React.ReactNode;
  cancelLabel?: React.ReactNode;
  confirmLabel?: React.ReactNode;
  onConfirm?: () => void;
  /**  bypass confirm dialog if true */
  autoConfirm?: boolean;
}

export function useConfirmModal({
  title,
  body,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  onConfirm,
  autoConfirm,
}: UseConfirmModalProps): [JSX.Element, () => void] {
  const [isOpen, setIsOpen] = useState(false);

  return [
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <SimpleModalContent
        title={title}
        buttons={
          <>
            <Button className="tw-rounded-6" onClick={() => setIsOpen(false)}>
              {cancelLabel}
            </Button>
            <Button
              variant="Filled"
              className="tw-rounded-6"
              onClick={() => {
                onConfirm?.();
                setIsOpen(false);
              }}
            >
              {confirmLabel}
            </Button>
          </>
        }
      >
        {body}
      </SimpleModalContent>
    </Modal>,
    () => {
      if (autoConfirm) {
        onConfirm?.();
      } else {
        setIsOpen(true);
      }
    },
  ];
}
