import cx from 'classnames';

export type SimpleModalContentWidth = 320 | 'auto';

const MODAL_WIDTH_CLASSES: Record<SimpleModalContentWidth, string> = {
  320: 'tw-w-320',
  auto: '',
};

export interface SimpleModalContentProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  buttons?: React.ReactNode;
  width?: SimpleModalContentWidth;
}

export function SimpleModalContent({
  title,
  children,
  buttons,
  width = 320,
}: SimpleModalContentProps) {
  return (
    <div className={cx('tw-py-16', 'tw-flex', 'tw-flex-col', 'tw-text-15')}>
      {title && (
        <header
          className={cx(
            'tw-text-17',
            'tw-font-medium',
            'tw-text-ellipsis',
            'tw-text-center',
            'tw-mb-2',
            'tw-mx-auto',
            'tw-px-16',
            MODAL_WIDTH_CLASSES[width],
          )}
        >
          {title}
        </header>
      )}
      <div
        className={cx(
          'tw-flex tw-flex-col tw-text-center tw-mx-auto tw-px-16',
          MODAL_WIDTH_CLASSES[width],
        )}
      >
        {children}
      </div>
      {buttons && (
        <div
          className={cx(
            'tw-grid',
            'tw-grid-flow-col',
            'tw-auto-cols-fr',
            'tw-gap-12',
            'tw-mt-20',
            'tw-px-16',
            /* avoid squeezing buttons horizontally */
            'tw-min-w-max',
          )}
        >
          {buttons}
        </div>
      )}
    </div>
  );
}
