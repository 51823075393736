import cx from 'classnames';

import type { ComponentColorOrDefault } from '../../utility';

import { FilledGauge } from './inner/FilledGauge';
import { NeedleGauge } from './inner/NeedleGauge';

export interface GaugeProps extends React.HTMLAttributes<HTMLDivElement> {
  kind?: 'Needle' | 'Filled';
  color?: ComponentColorOrDefault;
  min: number;
  max: number;
  value: number;
  targetValue?: number;
  valueToString?: (value: number) => string;
}

export function Gauge({
  kind = 'Needle',
  color,
  min,
  max,
  value,
  targetValue,
  valueToString = String,
  className,
  ...rest
}: GaugeProps) {
  const InnerComponent = kind === 'Filled' ? FilledGauge : NeedleGauge;

  return (
    <div
      role="meter"
      aria-valuemax={max}
      aria-valuemin={min}
      aria-valuenow={value}
      {...rest}
      className={cx(
        className,
        'tw-flex',
        'tw-flex-1',
        'tw-self-stretch',
        'tw-relative',
        'tw-select-none',
        'tw-touch-none',
      )}
    >
      <InnerComponent
        min={min}
        max={max}
        value={value}
        targetValue={targetValue}
        valueToString={valueToString}
        color={color}
      />
    </div>
  );
}
