import { Quaternion as ThreeQuaternion, Euler as ThreeEuler } from 'three';

export type Position = { x: number; y: number; z: number };
export type Quaternion = { x: number; y: number; z: number; w: number };

export const IdentityQuaternion = {
  x: 0,
  y: 0,
  z: 0,
  w: 0,
};

export type Euler = { x: number; y: number; z: number };

export const eulerAngleToQuaternion = ({ x, y, z }: Euler): Quaternion => {
  const quaternion = new ThreeQuaternion();
  quaternion.setFromEuler(new ThreeEuler(x, y, z));

  return {
    x: quaternion.x,
    y: quaternion.y,
    z: quaternion.z,
    w: quaternion.w,
  };
};
