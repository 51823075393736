import * as zod from 'zod';

export type Currency = 'USD';

export type Unsubscribe = () => void;

export const DistanceUnit = zod.union([
  zod.literal('meter'),
  zod.literal('millimeter'),
  zod.literal('inch'),
]);

export type DistanceUnit = zod.infer<typeof DistanceUnit>;
