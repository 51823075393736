import { isEqual } from 'lodash';

import type { ArmJointPositions, ArmJointTorques } from '@sb/motion-planning';
import type { RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getJointDisturbances(
  state: RoutineRunnerState | null,
): ArmJointTorques | null {
  return state
    ? (state.kinematicState.jointDisturbances.map((d) =>
        // round away from zero
        d > 0 ? Math.ceil(d) : Math.floor(d),
      ) as ArmJointTorques)
    : null;
}

export function useRobotJointDisturbances(
  args: UseRoutineRunnerHandleArguments,
): ArmJointTorques | null {
  return useRobotState<ArmJointPositions | null>(
    args,
    getJointDisturbances,
    // it is valid to use `isEqual` to compare disturbances because they are rounded
    isEqual,
  );
}
