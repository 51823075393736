import type { CartesianPose } from '@sb/geometry';
import type { RoutineRunnerState } from '@sb/routine-runner';
import { arePosesEqual } from '@sbrc/utils';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getTooltipState(
  state: RoutineRunnerState | null,
): CartesianPose | null {
  return state ? state.kinematicState.tooltipPoint : null;
}

export default function useRobotTooltipState(
  args: UseRoutineRunnerHandleArguments,
): CartesianPose | null {
  return useRobotState(args, getTooltipState, arePosesEqual);
}
