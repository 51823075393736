import { isEqual } from 'lodash';

import type { RoutineRunnerState } from '@sb/routine-runner';
import type { Six } from '@sb/utilities';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getJointBrakesEngaged(
  state: RoutineRunnerState | null,
): Six<boolean> | null {
  if (!state) {
    return null;
  }

  return state.kinematicState.jointBrakesEngaged;
}

/**
 * Get the joint brake statuses
 */
export function useRobotJointBrakesEngaged(
  args: UseRoutineRunnerHandleArguments,
): Six<boolean> | null {
  return useRobotState(args, getJointBrakesEngaged, isEqual);
}
