import cx from 'classnames';

import { Icon } from '../../icons';
import type { ButtonProps } from '../button';
import { Button } from '../button';

export function NavigationBarBackButton({
  className,
  children = 'Back',
  ...rest
}: ButtonProps) {
  return (
    <Button
      size={44}
      variant="Flat"
      className={cx('tw-pl-0', 'tw-pr-8', 'tw-icon-28', 'tw-gap-0', className)}
      {...rest}
    >
      <Icon kind="chevronLeft" className="-tw-ml-4" />
      <span>{children}</span>
    </Button>
  );
}
