import cx from 'classnames';

import { Icon } from '../../../icons';

import type { InputProps } from './Input';
import { Input } from './Input';

export interface TextInputProps extends Omit<InputProps, 'onChange'> {
  value: string;
  onChange: (newValue: string) => void;
}

export function TextInput({ value, onChange, ...rest }: TextInputProps) {
  return (
    <Input
      {...rest}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      contentAfter={
        <>
          {rest.contentAfter}
          <Icon
            kind="xmarkCircleFill"
            className={cx(
              'peer-disabled:tw-hidden',
              value === '' && 'tw-hidden',
            )}
            onClick={() => onChange('')}
          />
        </>
      }
    />
  );
}
