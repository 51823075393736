import type {
  ArmJointPositions,
  MotionPlannerInterface,
} from '@sb/motion-planning';
import type { StepKind } from '@sb/routine-runner';
import type { Six } from '@sb/utilities';

import { FailureKind } from './FailureKind';
import type { RecoveryType } from './RecoveryType';
import type {
  CollisionEvent,
  GeneralControlSystemEvent,
} from './types/ControlSystemEvent';
import type { MotionResult } from './types/MotionResult';

type PlanningFailure = {
  kind: FailureKind.PlanningFailure;
};

type ExecutionFailure = {
  kind: FailureKind.ExecutionFailure;
  motionResult?: MotionResult;
};

type ControlSystemFailure = {
  kind: FailureKind.ControlSystemFailure;
  event?: GeneralControlSystemEvent;
};

type GripperFailure = {
  kind: FailureKind.GripperFailure;
  message: string;
};

type OutOfJointLimitsFailure = {
  kind: FailureKind.OutOfJointLimitsFailure;
  jointAngles: ArmJointPositions;
  limitViolations: Six<number | null>;
  recoverable: boolean;
};

type CollisionFailure = {
  kind: FailureKind.CollisionFailure;
  /**
   * Collisions which may give information but also may not.
   *
   * e.g. if specified as [{ elementNames: ['link_0', 'floor'] }] means link_0 and the floor
   * are colliding.
   */
  collisions: Array<{
    elementNames: [string, string];
  }>;
  jointAngles: ArmJointPositions;
  isBoxedPosition: boolean;
};

type TorqueCollisionFailure = {
  kind: FailureKind.TorqueCollisionFailure;
  event: CollisionEvent;
  isManualRecoveryRequired: boolean;
};

type MotionPlannerFailure<
  M extends keyof MotionPlannerInterface = keyof MotionPlannerInterface,
> = {
  kind: FailureKind.MotionPlannerFailure;
  request: {
    method: M;
    args: Parameters<MotionPlannerInterface[M]>;
  };
};

type MotionPlannerFailureStartPositionCollision = {
  kind: FailureKind.MotionPlannerFailureStartPositionCollision;
};

type InvalidRoutineLoadedFailure = {
  kind: FailureKind.InvalidRoutineLoadedFailure;
};

type IOFailure = {
  kind: FailureKind.IOFailure;
};

type InferenceFailure = {
  kind: FailureKind.InferenceFailure;
};

type CameraFailure = {
  kind: FailureKind.CameraFailure;
};

type HaasFailure = {
  kind: FailureKind.HaasFailure;
};

type InternalFailure = {
  kind: FailureKind.InternalFailure;
};

type StepPlayFailure = {
  kind: FailureKind.StepPlayFailure;
  stepKind: StepKind;
};

type EStopTriggered = {
  kind: FailureKind.EStopTriggered;
  source: string;
};

type BotmanHeartbeatLost = {
  kind: FailureKind.BotmanHeartbeatLost;
};

type PowerBoardCheckFailure = {
  kind: FailureKind.PowerBoardCheckFailure;
};

type UnbrakeFailure = {
  kind: FailureKind.UnbrakeFailure;
};

export type FailureDetails =
  | PlanningFailure
  | ExecutionFailure
  | ControlSystemFailure
  | GripperFailure
  | OutOfJointLimitsFailure
  | CollisionFailure
  | TorqueCollisionFailure
  | MotionPlannerFailure
  | MotionPlannerFailureStartPositionCollision
  | CameraFailure
  | HaasFailure
  | EStopTriggered
  | BotmanHeartbeatLost
  | InvalidRoutineLoadedFailure
  | IOFailure
  | InferenceFailure
  | InternalFailure
  | StepPlayFailure
  | PowerBoardCheckFailure
  | UnbrakeFailure;

export function getRecoveryType(details: FailureDetails): RecoveryType {
  switch (details.kind) {
    case FailureKind.OutOfJointLimitsFailure:
      if (details.recoverable) {
        return 'GuidedMode';
      }

      return 'NotRecoverable';

    case FailureKind.MotionPlannerFailureStartPositionCollision:
    case FailureKind.CollisionFailure:
      return 'GuidedMode';

    case FailureKind.TorqueCollisionFailure:
      if (details.isManualRecoveryRequired) {
        return 'ManualRecoveryMode';
      }

      return 'GuidedMode';

    case FailureKind.InternalFailure:
    case FailureKind.PowerBoardCheckFailure:
      return 'Restart';

    case FailureKind.UnbrakeFailure:
      return 'ManualRecoveryMode';

    default:
      return 'Recoverable';
  }
}

export function getIsRecoverableWithWristButton(
  details?: FailureDetails,
): boolean {
  if (!details) return true;

  switch (details.kind) {
    case FailureKind.CollisionFailure:
    case FailureKind.TorqueCollisionFailure:
      return true;

    default:
      return false;
  }
}
