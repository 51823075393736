import { OR_3FG15_DIAMETER_METERS_SLIDER_STEP } from '../constants';
import type { OnRobot3FG15Command, OnRobot3FG15State } from '../types';

export function areOR3FG15GripperPositionsEqual(
  otherRobotGripperState: OnRobot3FG15State | null,
  targetDiameterMeters: number,
  command: OnRobot3FG15Command,
) {
  if (!otherRobotGripperState) return false;

  const isDiameterTargetSynced =
    Math.abs(otherRobotGripperState?.diameterMeters - targetDiameterMeters) <
    OR_3FG15_DIAMETER_METERS_SLIDER_STEP;

  const isGripKindTargetSynced =
    otherRobotGripperState?.gripKind === command.gripKind;

  return isDiameterTargetSynced && isGripKindTargetSynced;
}
