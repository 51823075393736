import * as zod from 'zod';

import { Expression } from '@sb/routine-runner';

import { DistanceUnit } from '../misc';

export namespace AddOffsetStep {
  export const Arguments = zod.object({
    argumentKind: zod.literal('AddOffset'),
    distanceUnit: DistanceUnit.default('meter'),
    translationX: Expression.optional(),
    translationY: Expression.optional(),
    translationZ: Expression.optional(),
  });

  export type Arguments = zod.infer<typeof Arguments>;
}
