import type { RoutineVariable, Step } from '@sb/remote-control/types';
import type { IOLevel } from '@sb/routine-runner';

/** Message displayed to users when a step hasn't been configured yet. */
export const ACTION_REQUIRED_STEP_MESSAGE_DEFAULT =
  'This step needs to be configured.';

// steps based on integration. Integration steps only show when integration enabled
export const INTEGRATION_STEP_LIST: Step.Kind[] = [
  'ActuateGripper',
  'ActuateScrewdriver',
  'ActuateVacuum',
  'NetworkRequest',
];

export const LOOP_STEP_FIXED_LOOP_MIN = 1;

/**
 * Each variable kind has a different set of operators.
 * We're list here all supported operators for each
 * variable kind.
 */
export const OPERATOR_INFO_LIST_BY_VARIABLE_KIND: Record<
  RoutineVariable.Kind,
  RoutineVariable.OperatorInformation[]
> = {
  boolean: [
    { displayName: 'Is', operator: '==' },
    { displayName: 'Is Not', operator: '!=' },
  ],
  composite: [],
  enum: [
    { displayName: 'Is', operator: '==' },
    { displayName: 'Is Not', operator: '!=' },
  ],
  expression: [{ displayName: 'Is True', operator: '==' }],
  number: [
    { displayName: 'Greater Than', operator: '>' },
    { displayName: 'Equal To', operator: '==' },
    { displayName: 'Less Than', operator: '<' },
  ],
  string: [
    { displayName: 'Is', operator: '==' },
    { displayName: 'Is Not', operator: '!=' },
  ],
};

export const IO_STATE_VARIABLES_DEFAULT: RoutineVariable.EnumValue<IOLevel>[] =
  [
    { displayName: 'High', value: 'high' },
    { displayName: 'Low', value: 'low' },
  ];

export const SET_SPEED_SUPPORTED_STEP_KIND: Step.Kind[] = [
  'MoveArmTo',
  'PressButton',
];
