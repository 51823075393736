import type { JointNumber } from '@sb/motion-planning';

import { useRoutineRunnerHandle } from './useRoutineRunnerHandle';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';
import useToast from './useToast';

export function useUnbrakeJoint(args: UseRoutineRunnerHandleArguments) {
  const routineRunnerHandle = useRoutineRunnerHandle(args);

  const { setToast } = useToast();

  const unbrakeJoint = async (jointNumber: JointNumber): Promise<void> => {
    try {
      setToast({ kind: 'progress', message: 'Unbraking joint...' });

      await routineRunnerHandle.unbrakeJoint(jointNumber);

      setToast({
        kind: 'success',
        message: `Joint ${jointNumber} has successfully unbraked.`,
      });
    } catch (e) {
      setToast({
        kind: 'error',
        message: `$Joint ${jointNumber} could not unbrake. Please try again`,
      });
    }
  };

  return { unbrakeJoint };
}
