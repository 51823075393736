import cx from 'classnames';
import { forwardRef } from 'react';

import type { ComponentSize } from '../../../utility';
import { INPUT_SIZE_DEFAULT, INPUT_SIZE_CLASSES } from '../inputSize';
import type { InputVariant } from '../inputVariant';
import { INPUT_VARIANT_CLASSES, INPUT_VARIANT_DEFAULT } from '../inputVariant';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  size?: ComponentSize;
  variant?: InputVariant;
  alignment?: 'Left' | 'Center' | 'Right';
  contentBefore?: React.ReactNode;
  contentAfter?: React.ReactNode;
  inputClassName?: string;
}

/**
 * Underlying component for more specialized inputs
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      size = INPUT_SIZE_DEFAULT,
      variant = INPUT_VARIANT_DEFAULT,
      alignment,
      autoComplete = 'off',
      spellCheck = 'false',
      contentBefore,
      contentAfter,
      inputClassName,
      ...rest
    },
    ref,
  ) => {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={cx(
          className,
          INPUT_SIZE_CLASSES[size],
          INPUT_VARIANT_CLASSES[variant],
          variant === 'Flat' && 'tw-px-0',
          'tw-inline-flex',
          'tw-flex-row',
          'tw-items-center',
          'tw-gap-10',
          'tw-text-label-quaternary',
          'tw-select-none',
        )}
        onMouseDown={(e) => {
          // clicking anywhere on the surrounding div should focus the input
          const inputElement = e.currentTarget.getElementsByTagName('input')[0];

          if (inputElement && inputElement !== e.target) {
            inputElement.focus();
            e.preventDefault();
          }
        }}
      >
        {contentBefore}
        <input
          className={cx(
            'tw-peer',
            'tw-text-label-primary',
            'tw-flex-1',
            'tw-w-100', // this is a default width, will shrink or grow when container has explicit size
            'tw-text-ellipsis',
            'tw-bg-transparent',
            'placeholder:tw-text-label-quaternary',
            'disabled:tw-text-label-quaternary',
            'tw-select-auto',
            alignment === 'Center' && 'tw-text-center',
            alignment === 'Right' && 'tw-text-right',
            inputClassName,
          )}
          autoComplete={autoComplete}
          spellCheck={spellCheck}
          {...rest}
          ref={ref}
        />
        {contentAfter}
      </div>
    );
  },
);
