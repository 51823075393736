import * as zod from 'zod';

/**
 * Types matching the routine runner schema for "LoopControl" operations.
 */
export namespace LoopControlStep {
  export const Arguments = zod.object({
    argumentKind: zod.literal('LoopControl'),
    kind: zod
      .union([zod.literal('break'), zod.literal('continue')])
      .default('continue'),
  });

  export type Arguments = zod.infer<typeof Arguments>;
}
