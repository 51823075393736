import { useEffect, useState } from 'react';

import type { SoftwareUpdateData } from '@sb/feathers-types';
import { wait } from '@sb/utilities';
import { getSoftwareUpdateData } from '@sbrc/services';

export interface SoftwareBuildData extends Partial<SoftwareUpdateData> {
  timeChecked: Date | null;
}

const INITIAL_BUILD_DATA: SoftwareBuildData = {
  timeChecked: null,
};

export const useSoftwareUpdateData = (
  pollingInterval: number = 5_000,
): SoftwareBuildData => {
  const [buildData, setBuildData] =
    useState<SoftwareBuildData>(INITIAL_BUILD_DATA);

  useEffect(() => {
    let cancelled = false;

    const fetchBuildDataLoop = async () => {
      while (!cancelled) {
        try {
          const softwareUpdateData = await getSoftwareUpdateData();

          if (cancelled) {
            break;
          }

          setBuildData({
            ...INITIAL_BUILD_DATA,
            ...softwareUpdateData,
            timeChecked: new Date(),
          });

          await wait(pollingInterval);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn('Failed to get SoftwareUpdateData', error);
          await wait(2_000);
        }
      }
    };

    fetchBuildDataLoop();

    return () => {
      cancelled = true;
    };
  }, [pollingInterval]);

  return buildData;
};
