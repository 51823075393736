import type { Step, Routine } from '@sb/remote-control/types';

const checkIpAddress = (
  _step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
): Routine.ActionRequiredStep | undefined => {
  if (
    stepConfiguration?.stepKind !== 'HaasRunProgram' &&
    stepConfiguration?.stepKind !== 'HaasControlRegion'
  )
    return undefined;
  const { args } = stepConfiguration;
  if (!args) return undefined;
  if (args.address.kind !== 'HaasEthernet') return undefined;

  if (!args.address.endpoint) {
    return {
      kind: 'invalidConfiguration',
      message: 'Address not specified',
      fieldId: 'address',
    };
  }

  try {
    // eslint-disable-next-line no-new
    new URL(args.address.endpoint);
  } catch (_error) {
    return {
      kind: 'invalidConfiguration',
      message: 'Invalid Ethernet Address (e.g. http://192.168.0.109:5551)',
      fieldId: 'address',
    };
  }

  return undefined;
};

const checkHaasRS232Path = (
  _step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
): Routine.ActionRequiredStep | undefined => {
  if (
    stepConfiguration?.stepKind !== 'HaasRunProgram' &&
    stepConfiguration?.stepKind !== 'HaasControlRegion'
  )
    return undefined;
  const { args } = stepConfiguration;
  if (!args) return undefined;
  if (args.address.kind !== 'HaasRS232') return undefined;

  if (!args.address.path) {
    return {
      kind: 'invalidConfiguration',
      message: 'HaasRS232 Path not specified',
      fieldId: 'address',
    };
  }

  if (/^(\/?[A-Za-z0-9]+)+$/.test(args.address.path)) {
    return undefined;
  }

  return {
    kind: 'invalidConfiguration',
    message: 'Invalid path (example: /dev/ttyUSB0)',
    fieldId: 'address',
  };
};

export const haasRunProgramStepValidators = [
  checkIpAddress,
  checkHaasRS232Path,
];
