import * as zod from 'zod';

export enum FirmwareBuildType {
  Debug = 1,
  Release = 2,
}

export enum FirmwareType {
  IOBoard = 1,
  PowerBoard = 2,
  MotorBoardLegacyShoulder = 3,
  MotorBoardLegacyElbow = 4,
  MotorBoardLegacyWrist = 5,
  EEBoard = 6,
  MotorBoardShoulder = 7,
  MotorBoardElbow = 8,
  MotorBoardWrist = 9,
}

export const FirmwareBuildTypeSchema = zod
  .string()
  .refine((value) => Object.keys(FirmwareBuildType).includes(value), {
    message: 'Invalid firmware build type',
  });

export const FirmwareTypeSchema = zod
  .string()
  .refine((value) => Object.keys(FirmwareType).includes(value), {
    message: 'Invalid firmware type',
  });

export const FirmwareIdentificationSchema = zod.object({
  firmwareType: FirmwareTypeSchema,
  buildType: FirmwareBuildTypeSchema,
  buildId: zod.string(),
  variant: zod.string(),
});

export type FirmwareIdentification = zod.infer<
  typeof FirmwareIdentificationSchema
>;

export const BuildFirmwareIdentifier = zod.object({
  firmwareType: FirmwareTypeSchema,
  variant: zod.string(),
});

export type BuildFirmwareIdentifier = zod.infer<typeof BuildFirmwareIdentifier>;
