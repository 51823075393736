import * as zod from 'zod';

import type {
  RunSkillStep as RunSkillStepRR,
  StepArguments,
} from '@sb/routine-runner';

export namespace RunSkillStep {
  export const Arguments = zod.object({
    argumentKind: zod.literal('RunSkill'),
    skill: zod.string().nullable().default(null),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export function convertToRoutineRunner(
    args?: Arguments,
  ): StepArguments<RunSkillStepRR> {
    if (!args) {
      throw new Error('Run skill step not configured');
    }

    if (!args.skill) {
      throw new Error('Run skill step - skill not specified');
    }

    return {
      skill: args.skill,
    };
  }
}
