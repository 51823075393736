import type { Step } from '@sb/remote-control/types';

export const DECORATOR_STEP_LIST: Step.Kind[] = [
  'AddOffset',
  'RunInBackground',
  'If',
  'Loop',
  'HaasControlRegion',
];

export const STEP_NAME_BY_STEP_KIND: Record<Step.Kind, string> = {
  ActuateGripper: 'Actuate gripper',
  ActuateScrewdriver: 'Actuate screwdriver',
  ActuateVacuum: 'Actuate vacuum',
  AddOffset: 'Add offset',
  AnticipatePayload: 'Set payload',
  Classify: 'Classify',
  Locate: 'Locate',
  RunInBackground: 'Run in background',
  RunSkill: 'Run learned skill',
  HaasRunProgram: 'Run Haas program',
  HaasControlRegion: 'Monitor Haas machine',
  If: 'If',
  Loop: 'Loop',
  LoopControl: 'Restart loop',
  MoveArmTo: 'Move arm',
  NetworkRequest: 'Network request',
  PressButton: 'Press button',
  SetEnvironmentVariable: 'Set variable',
  SetIO: 'Set I/O output',
  Wait: 'Wait',
  WaitForConfirmation: 'Wait for confirmation',
};
