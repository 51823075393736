/* tslint:disable */
/* eslint-disable */
/**
 * Standard Bots RO1 Robotics API
 * Standard Bots RO1 Robotics API.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@standardbots.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export * from "./api";
export * from "./configuration";

