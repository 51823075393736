import * as zod from 'zod';

export namespace WaitStep {
  export const Arguments = zod.object({
    argumentKind: zod.literal('Wait'),
    milliseconds: zod.number().nullable(),
    /**
     * Condition: will override `milliseconds` if non-null
     * The actual type is ConditionalBuilderState[][] but Firestore doesn't support nested
     * arrays, so we need to serialize/desarialize this property when using it.
     */
    condition: zod.string().nullable(),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export function isMillisecondsArguments(
    args: Arguments,
  ): args is Arguments & { milliseconds: number } {
    return typeof args.milliseconds === 'number';
  }

  export function isConditionArguments(
    args: Arguments,
  ): args is Arguments & { condition: string } {
    return typeof args.condition === 'string';
  }
}
