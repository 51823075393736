import type { Placement } from '@popperjs/core';
import cx from 'classnames';
import { useState } from 'react';

import { ArrowDownIcon } from '@sb/ui/icons';

import type { ButtonProps } from './Button';
import Button from './Button';
import Dropdown from './Dropdown';

import styles from './DropdownButton.module.css';

interface DropdownButtonProps extends ButtonProps {
  content: React.ReactNode;
  placement?: Placement | undefined;
  'data-testid'?: string;
}

const DropdownButton = (props: DropdownButtonProps) => {
  const {
    children,
    className,
    content,
    placement,
    disabled,
    onClick,
    style,
    variant = 'primary',
    'data-testid': testID,
    ...other
  } = props;

  const [isOpen, setOpen] = useState<boolean>(false);

  const toggleDropdown = () => setOpen(!isOpen);

  return (
    <Dropdown
      content={content}
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      className={cx(styles.dropdownButton, className)}
      data-testid={testID}
      placement={placement}
    >
      <Button
        className={styles.labelButton}
        disabled={disabled}
        onClick={toggleDropdown}
        variant={variant}
        endIcon={<ArrowDownIcon className={styles.arrowIcon} />}
        data-testid={testID && `${testID}-button`}
        {...other}
      >
        {children}
      </Button>
    </Dropdown>
  );
};

export default DropdownButton;
