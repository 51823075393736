import cx from 'classnames';

import { Icon } from '../../icons';

import type { ButtonProps } from './Button';
import { Button } from './Button';

export interface ToggleButtonProps extends ButtonProps {
  isSelected?: boolean;
}

export const ToggleButton = ({
  children,
  className,
  isSelected,
  ...other
}: ToggleButtonProps) => {
  return (
    <Button
      variant={isSelected ? 'Elevated' : 'Gray'}
      className={cx(className)}
      {...other}
    >
      {isSelected && <Icon kind="checkmark" />}
      <span className={cx({ 'tw-text-label-secondary': !isSelected })}>
        {children}
      </span>
    </Button>
  );
};
