import type { Step, Routine } from '@sb/remote-control/types';

/** Is an Add Offset decorator step that doesn't have a nested Move Arm step. */
export function addOffsetValidator(
  step: Step.ConvertedSummary,
): Routine.ActionRequiredStep | undefined {
  if (step.stepKind !== 'AddOffset') return undefined;

  if (!step.steps.some((nestedStep) => nestedStep.stepKind === 'MoveArmTo')) {
    return {
      kind: 'invalidConfiguration',
      message: 'Add Offset requires a Move Arm step',
    };
  }

  return undefined;
}
