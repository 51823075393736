import { mapPaginated } from '@sb/feathers-types';
import type { Notification } from '@sb/remote-control/types';

import { convertNotificationResponse, getNotificationsService } from './utils';

export async function getNotifications(
  userID: string,
  status: Notification.Status,
): Promise<Notification.ConvertedResponse[]> {
  const result = await getNotificationsService().find({
    query: {
      [`statusByUserID.${userID}`]: status === 'new' ? null : status,
      $sort: { createdAt: -1 },
    },
  });

  return mapPaginated(result, convertNotificationResponse(userID));
}
