import { asPaginated } from '@sb/feathers-types';
import type { SafetySettings } from '@sb/remote-control/types';

import {
  convertSafetySettingsResponse,
  getSafetySettingsService,
} from './utils';

export async function getSafetySettings(
  robotID: string,
): Promise<SafetySettings.ConvertedResponse> {
  const response = await getSafetySettingsService().find({
    query: { robotID, $limit: 1 },
  });

  const dbSettings = asPaginated(response).data[0];

  const settings = convertSafetySettingsResponse(dbSettings);

  return settings;
}
