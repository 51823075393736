import * as zod from 'zod';

export namespace AnticipatePayloadStep {
  export const Arguments = zod.object({
    argumentKind: zod.literal('AnticipatePayload'),
    mass: zod.number().default(0),
  });

  export type Arguments = zod.infer<typeof Arguments>;
}
