import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { mapPaginated } from '@sb/feathers-types';
import type { Robot, Unsubscribe } from '@sb/remote-control/types';
import { convertEquipmentResponse } from '@sb/remote-control/util/convertEquipmentResponse';

import { getEquipmentService } from '../equipment/utils';
import { getSafetySettingsService } from '../safety-settings/utils';
import { cleanQuery } from '../utils';

import { convertRobotResponse, getRobotsService } from './utils';

interface OnGetRobotsArguments {
  robotID?: string;
  isDefault?: true;
  routineID?: string | null;
}

export function onGetRobots(
  { robotID, isDefault, routineID }: OnGetRobotsArguments,
  onSnapshot: (robots: Robot.ConvertedResponse[]) => void,
): Unsubscribe {
  const robot$ = getRobotsService()
    .watch()
    .find({
      query: cleanQuery({
        robotID,
        isDefault,
        latestRoutineID: routineID ?? undefined,
        $sort: { name: 1 },
      }),
    });

  const safeguardRules$ = getSafetySettingsService()
    .watch()
    .find({ $select: ['robotID', 'safeguardRules'] })
    .pipe(
      map((response) => {
        return mapPaginated(response, (r) => r);
      }),
    );

  const equipment$ = getEquipmentService()
    .watch()
    .find({ query: { isEnabled: true, $sort: { updatedAt: -1 } } })
    .pipe(
      map((response) => {
        return mapPaginated(response, convertEquipmentResponse);
      }),
    );

  const subscription = combineLatest([robot$, safeguardRules$, equipment$])
    .pipe(
      map(([robotResponse, safeguardRules, equipment]) => {
        const robots = mapPaginated(
          robotResponse,
          convertRobotResponse(
            (findRobotID) =>
              safeguardRules.find((s) => s.robotID === findRobotID)
                ?.safeguardRules ?? [],
            equipment,
          ),
        );

        return robots;
      }),
    )
    .subscribe(onSnapshot);

  return () => subscription.unsubscribe();
}
