import { useCallback } from 'react';

import type { Robot } from '@sb/remote-control/types';
import type {
  ExternalToRobotPort,
  IOLevel,
  RobotToExternalPort,
  RoutineRunnerState,
  SafetyPort,
} from '@sb/routine-runner';

import useRobotState from './useRobotState';

interface UseIOStateArguments {
  kind: Robot.IOPortKind;
  port: number;
  isVizbot?: boolean;
}

export default function useRobotIOState({
  kind,
  port,
  isVizbot,
}: UseIOStateArguments): IOLevel | null {
  const getIOState = useCallback(
    (state: RoutineRunnerState | null) => {
      const ioKey = `${kind} ${port}` as
        | ExternalToRobotPort
        | RobotToExternalPort
        | SafetyPort;

      return state?.kinematicState.ioState[ioKey] ?? null;
    },
    [kind, port],
  );

  return useRobotState({ isVizbot }, getIOState);
}
