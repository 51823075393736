import { useCallback, useEffect, useState } from 'react';

import type { RoutineRunnerHandle } from '@sbrc/services';
import { getLiveRoutineRunnerHandle } from '@sbrc/services';

/**
 * Connect to a live running robot.
 * Re-renders when connection state changes
 *
 * @returns A RoutineRunnerHandle
 */
export function useLiveRoutineRunnerHandle(): RoutineRunnerHandle {
  const handle = getLiveRoutineRunnerHandle();

  // used to force a re-render when the routine runner handle changes
  const [, updateToken] = useState({});
  const rerender = useCallback(() => updateToken({}), []);

  // whenever the handle has a change that requires a re-render, re-render
  useEffect(() => {
    return handle.onChange(rerender);
  }, [handle, rerender]);

  return handle;
}
