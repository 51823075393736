import type { RoutineData } from '@sb/feathers-types';
import { makeNamespacedLog } from '@sb/log';
import type { Routine } from '@sb/remote-control/types';
import { Space } from '@sb/routine-runner';

import { convertRoutineSummary } from './convertRoutineSummary';
import { convertStepConfigurations } from './convertStepConfigurations';
import { convertStepSummary } from './convertStepSummary';
import { listAllSteps } from './listAllSteps';
import { getStepActionRequired } from './step-validation';
import { getVirtualSpaceItems } from './virtualSpaceItems';

const log = makeNamespacedLog('convertRoutineResponse');

export function convertRoutineResponse(
  data: RoutineData,
): Routine.ConvertedResponse {
  const summary = convertRoutineSummary(data);

  const steps = convertStepSummary(data.steps);

  const stepConfigurations = convertStepConfigurations(
    data.id,
    steps,
    data.stepConfigurations,
  );

  const space: Space.Item[] = [];

  if (data.space) {
    for (const spaceItem of data.space) {
      const parsedItem = Space.Item.safeParse(spaceItem);

      if (parsedItem.success) {
        space.push(parsedItem.data);
      } else {
        log.warn(
          'spaceItem.parse.error',
          'Failed to parse space item, skipping',
          {
            error: parsedItem.error,
            spaceItem,
          },
        );
      }
    }
  }

  space.push(...getVirtualSpaceItems(stepConfigurations));

  const routine: Routine.ConvertedResponse = {
    ...summary,
    configurationUpdatedAt: new Date(
      data.configurationUpdatedAt ?? data.createdAt,
    ),
    currentEditorID: null,
    environmentVariables: data.environmentVariables ?? [],
    equipment: [], // will be merged in from equipment service
    mainLoopStepID: data.mainLoopStepID ?? null,
    space,
    steps,
    stepConfigurations,
    // calculated below..
    actionRequiredByStepID: {},
    isActionRequired: false,
  };

  for (const step of listAllSteps(steps)) {
    const actionRequired = getStepActionRequired(step, routine);

    if (actionRequired) {
      routine.actionRequiredByStepID[step.id] = actionRequired;
      routine.isActionRequired = true;
    }
  }

  return routine;
}
