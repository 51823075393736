import type { DeviceConfiguration } from '@sb/integrations/device';
import { convertIntegrationStepConfigurationToRoutineRunnerFormat } from '@sb/integrations/frontend/convertIntegrationStepConfigurationToRoutineRunnerFormat';
import {
  RunSkillStep,
  type Equipment,
  type Step,
} from '@sb/remote-control/types';
import {
  RoutineStepConfiguration,
  type SpeedProfile,
} from '@sb/routine-runner';

import { convertAddOffsetConfigurationToRoutineRunner } from './addOffset';
import { convertAnticipatePayloadConfigurationToRoutineRunner } from './anticipatePayload';
import { convertClassifyConfigurationToRoutineRunner } from './classify';
import { convertIfConfigurationToRoutineRunner } from './if';
import { convertLocateConfigurationToRoutineRunner } from './locate';
import { convertLoopConfigurationToRoutineRunner } from './loop';
import { convertLoopControlConfigurationToRoutineRunner } from './loopControl';
import { convertMoveArmToConfigurationToRoutineRunner } from './moveArmTo';
import { convertPressButtonConfigurationToRoutineRunner } from './pressButton';
import { convertSetEnvironmentVariableConfigurationToRoutineRunner } from './setEnvironmentVariable';
import { convertSetIOConfigurationToRoutineRunner } from './setIO';
import { convertWaitConfigurationToRoutineRunner } from './wait';
import { convertWaitForConfirmationConfigurationToRoutineRunner } from './waitForConfirmation';

function convertStepConfigurationToRoutineRunnerFormatSub(
  step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration,
  routineEquipment: DeviceConfiguration[],
  baseSpeedProfile: SpeedProfile,
  robotEquipment: Equipment.ConvertedResponse[],
): RoutineStepConfiguration {
  const stepID = step.id;

  const stepData = {
    id: step.id,
    description: step.description,
    steps: undefined,
  };

  const integrationResponse =
    convertIntegrationStepConfigurationToRoutineRunnerFormat({
      stepConfiguration,
      step,
      routineEquipment,
      robotEquipment,
    });

  if (integrationResponse) return integrationResponse;

  if (stepConfiguration.stepKind === 'AnticipatePayload') {
    return {
      ...stepData,
      stepKind: 'AnticipatePayload',
      args: convertAnticipatePayloadConfigurationToRoutineRunner(
        stepID,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'If') {
    return {
      ...stepData,
      stepKind: 'If',
      args: convertIfConfigurationToRoutineRunner(
        stepID,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'Classify') {
    return {
      ...stepData,
      stepKind: 'Classify',
      args: convertClassifyConfigurationToRoutineRunner(
        stepID,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'Locate') {
    return {
      ...stepData,
      stepKind: 'Locate',
      args: convertLocateConfigurationToRoutineRunner(
        stepID,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'Loop') {
    return {
      ...stepData,
      stepKind: 'Loop',
      args: convertLoopConfigurationToRoutineRunner(
        stepID,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'LoopControl') {
    return {
      ...stepData,
      stepKind: 'LoopControl',
      args: convertLoopControlConfigurationToRoutineRunner(
        stepID,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'MoveArmTo') {
    return {
      ...stepData,
      stepKind: 'MoveArmTo',
      args: convertMoveArmToConfigurationToRoutineRunner(
        stepID,
        baseSpeedProfile,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'PressButton') {
    return {
      ...stepData,
      stepKind: 'PressButton',
      args: convertPressButtonConfigurationToRoutineRunner(
        stepID,
        baseSpeedProfile,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'SetEnvironmentVariable') {
    return {
      ...stepData,
      stepKind: 'SetEnvironmentVariable',
      args: convertSetEnvironmentVariableConfigurationToRoutineRunner(
        stepID,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'SetIO') {
    return {
      ...stepData,
      stepKind: 'SetIO',
      args: convertSetIOConfigurationToRoutineRunner(
        stepID,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'Wait') {
    return {
      ...stepData,
      stepKind: 'Wait',
      args: convertWaitConfigurationToRoutineRunner(
        stepID,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'WaitForConfirmation') {
    return {
      ...stepData,
      stepKind: 'WaitForConfirmation',
      args: convertWaitForConfirmationConfigurationToRoutineRunner(
        stepID,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'AddOffset') {
    return {
      ...stepData,
      stepKind: 'AddOffset',
      args: convertAddOffsetConfigurationToRoutineRunner(
        stepID,
        stepConfiguration.args,
      ),
    };
  }

  if (stepConfiguration.stepKind === 'RunInBackground') {
    return {
      ...stepData,
      stepKind: 'RunInBackground',
      args: {},
    };
  }

  if (stepConfiguration.stepKind === 'RunSkill') {
    return {
      ...stepData,
      stepKind: 'RunSkill',
      args: RunSkillStep.convertToRoutineRunner(stepConfiguration.args),
    };
  }

  throw new Error(`${step.stepKind} steps are not supported yet.`);
}

export function convertStepConfigurationToRoutineRunnerFormat(
  step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration,
  routineEquipment: DeviceConfiguration[],
  baseSpeedProfile: SpeedProfile,
  robotEquipment: Equipment.ConvertedResponse[],
): RoutineStepConfiguration {
  const convertedConfiguration =
    convertStepConfigurationToRoutineRunnerFormatSub(
      step,
      stepConfiguration,
      routineEquipment,
      baseSpeedProfile,
      robotEquipment,
    );

  const parsedConfiguration = RoutineStepConfiguration.safeParse(
    convertedConfiguration,
  );

  if (parsedConfiguration.success) {
    return parsedConfiguration.data;
  }

  throw new TypeError(
    `Invalid configuration in step ${step.stepPosition}. ${
      step.name
    }: ${parsedConfiguration.error.errors.map((e) => e.message).join(';')}`,
  );
}
