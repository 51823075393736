import { useId } from 'react';

import { Switch } from '../form';
import type { SwitchProps } from '../form';

import { SettingsGroupItem } from './SettingsGroupItem';

export interface SettingsGroupSwitchItemProps extends SwitchProps {
  label?: React.ReactNode;
  secondaryLabel?: React.ReactNode;
  isSeparated?: boolean;
}

export function SettingsGroupSwitchItem({
  label,
  secondaryLabel,
  isSeparated,
  className,
  ...rest
}: SettingsGroupSwitchItemProps) {
  const id = useId();

  return (
    <SettingsGroupItem className={className} isSeparated={isSeparated}>
      <label className="tw-flex-1" htmlFor={id}>
        {label}
      </label>

      {secondaryLabel && (
        <span className="tw-text-label-secondary">{secondaryLabel}</span>
      )}

      <Switch id={id} {...rest} />
    </SettingsGroupItem>
  );
}
