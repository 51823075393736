import cx from 'classnames';
import { useId } from 'react';

import { Slider } from '../form';
import type { SliderProps } from '../form';

import { SettingsGroupItem } from './SettingsGroupItem';

export interface SettingsGroupSliderItemProps extends SliderProps {
  label?: React.ReactNode;
  secondaryLabel?: React.ReactNode;
  isSeparated?: boolean;
}

export function SettingsGroupSliderItem({
  label,
  secondaryLabel,
  className,
  disabled,
  isSeparated,
  ...rest
}: SettingsGroupSliderItemProps) {
  const id = useId();

  return (
    <SettingsGroupItem className={className} isSeparated={isSeparated}>
      <label
        className={cx('tw-flex-1', disabled && 'tw-text-label-quaternary')}
        htmlFor={id}
      >
        {label}
      </label>

      {secondaryLabel !== undefined && (
        <span
          className={
            disabled ? 'tw-text-label-quaternary' : 'tw-text-label-secondary'
          }
        >
          {secondaryLabel}
        </span>
      )}

      <Slider
        id={id}
        className="tw-min-w-[50%]"
        disabled={disabled}
        {...rest}
      />
    </SettingsGroupItem>
  );
}
