import cx from 'classnames';

export interface MenuProps extends React.HTMLAttributes<HTMLMenuElement> {}

export function Menu({ className, ...rest }: MenuProps) {
  return (
    <menu
      {...rest}
      className={cx(
        className,
        'tw-flex',
        'tw-flex-col',
        'tw-rounded-10',
        'tw-surface-elevated',
        'tw-shadow-80',
        'tw-min-w-[256px]',
        'tw-divide-y',
        'tw-divide-divider-primary',
        'tw-overflow-auto',
      )}
    />
  );
}
