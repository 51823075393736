import * as zod from 'zod';

import { HaasControlRegionArguments } from '../steps/HaasControlRegion/Arguments';

/**
 * Types matching the routine runner schema for "HaasControlRegion" Steps
 */
export namespace HaasControlRegionStepDatabase {
  /** Arguments to be passed to the routine runner */
  export const Arguments = zod.object({
    argumentKind: zod.literal('HaasControlRegion'),
    ...HaasControlRegionArguments.shape,
    shouldSendCellSafe:
      HaasControlRegionArguments.shape.shouldSendCellSafe.default(true),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const convertStepToRoutineRunnerArgs = (
    configuration: Arguments | void,
    stepID: string,
  ): HaasControlRegionArguments => {
    if (!configuration) {
      throw new Error(
        `HaasControlRegion step is not configured. stepID: ${stepID}`,
      );
    }

    return configuration;
  };
}
