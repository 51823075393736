import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import { CameraIntegration } from '@sb/integrations/camera/types';

import { LocateMethod } from '../../vision/LocateTypes';
import { RegionOfInterest } from '../../vision/VisionInterface';

export default zod.object({
  camera: CameraIntegration,
  regionOfInterest: RegionOfInterest,
  method: LocateMethod,
  positionListID: zod.string(), // The position list to output results to
  filters: zod.object({
    resultsLimit: zod.optional(zod.number()), // The max number of results to output
  }),
  transform: CartesianPose,
  planeID: zod.string(),
});
