import { useEffect, useState } from 'react';

import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';
import { useRoutineRunnerHandle } from './useRoutineRunnerHandle';

export default function useIsRobotConnected(
  args: UseRoutineRunnerHandleArguments,
): boolean {
  const routineRunnerHandle = useRoutineRunnerHandle(args);

  const [isConnected, setIsConnected] = useState(
    routineRunnerHandle.getConnectionStatus().kind === 'connected',
  );

  useEffect(() => {
    const removeConnectionChangeHandler =
      routineRunnerHandle.onConnectionChange((newStatus) => {
        setIsConnected(newStatus.kind === 'connected');
      });

    return () => {
      removeConnectionChangeHandler();
    };
  }, [routineRunnerHandle]);

  return isConnected;
}
