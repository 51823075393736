import { uniq } from 'lodash';

import type { RoutineVariable, Step } from '@sb/remote-control/types';
import type { MoveArmToStepVariables } from '@sb/routine-runner';

const MOVE_ARM_TO_VARIABLE_NAME_BY_VALUE: Record<
  MoveArmToStepVariables['currentActivity'],
  string
> = {
  moving: 'Moving Arm',
  none: 'Not Moving to Position',
  paused: 'Paused',
  planning: 'Planning Motion',
  receivingPlan: 'Receiving Motion Plan',
  requestingPlan: 'Requesting Motion Plan',
};

function toEnumValues<V extends string>(
  byValue: Record<V, string>,
): RoutineVariable.EnumValue<V>[] {
  return Object.entries(byValue).map(([value, displayName]) => ({
    value: value as V,
    displayName: displayName as string,
  }));
}

const MOVE_ARM_VARIABLE_LIST: RoutineVariable.Information[] = [
  {
    variableName: 'currentActivity',
    displayName: 'Current Activity',
    variableKind: 'enum',
    values: toEnumValues(MOVE_ARM_TO_VARIABLE_NAME_BY_VALUE),
  },
];

/**
 * All steps that support output variables.
 */
export function getStepVariableInfo(
  stepConfiguration?: Step.Configuration,
): RoutineVariable.Information[] {
  switch (stepConfiguration?.args?.argumentKind) {
    case 'Classify': {
      const possibleResults = uniq([
        ...stepConfiguration.args.classes.map((c) => c.name),
        stepConfiguration.args.fallbackValue,
      ]);

      return [
        {
          variableName: 'latestResult',
          displayName: 'Latest result',
          variableKind: 'enum',
          values: possibleResults.map((value) => ({
            value,
            displayName: value,
          })),
        },
      ];
    }

    case 'HaasControlRegion': {
      return [
        {
          variableName: 'haasProgram',
          displayName: 'Currently Running Program',
          variableKind: 'string',
        },
        {
          variableName: 'haasStatus',
          displayName: 'Current Haas Machine Status',
          variableKind: 'string',
        },
        {
          variableName: 'haasPartCount',
          displayName: 'Haas Part Count',
          variableKind: 'number',
          minimum: 0,
        },
      ];
    }

    case 'HaasRunProgram': {
      return [
        {
          variableName: 'isProgramComplete',
          displayName: 'Machine Program Complete',
          variableKind: 'boolean',
          values: [
            { value: true, displayName: 'Complete' },
            { value: false, displayName: 'Not Complete' },
          ],
        },
      ];
    }

    case 'Locate': {
      return [
        {
          variableName: 'resultCount',
          displayName: 'Objects located count',
          variableKind: 'number',
        },
      ];
    }

    case 'Loop': {
      return [
        {
          variableName: 'currentIteration',
          displayName: 'Current Iteration',
          variableKind: 'number',
          minimum: 0,
          maximum: Infinity,
        },
      ];
    }

    case 'MoveArmTo': {
      return [
        ...MOVE_ARM_VARIABLE_LIST,
        {
          variableName: 'completedCount',
          displayName: 'Completed Count',
          variableKind: 'number',
        },
      ];
    }

    case 'PressButton':
      return MOVE_ARM_VARIABLE_LIST;

    case 'RunInBackground': {
      return [
        {
          variableName: 'isRunning',
          displayName: 'Current Activity',
          variableKind: 'boolean',
          values: [
            { value: true, displayName: 'Running' },
            { value: false, displayName: 'Not Running' },
          ],
        },
      ];
    }

    case 'WaitForConfirmation': {
      return [
        {
          variableName: 'confirmed',
          displayName: 'Confirmed',
          variableKind: 'boolean',
          values: [
            { value: true, displayName: 'Confirmed' },
            { value: false, displayName: 'Not confirmed' },
          ],
        },
      ];
    }

    default:
      return [];
  }
}
