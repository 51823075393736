import { useEffect, useCallback, useRef } from 'react';

// default to maximum possible interval
const DEFAULT_INTERVAL_MS = 2 ** 31 - 1;

export interface UseIntervalOptions {
  intervalMs?: number;
  /* delay before starting interval */
  delayMs?: number;
  /* invoke callback immediately before starting interval */
  leading?: boolean;
}

export function useInterval(intervalMs = DEFAULT_INTERVAL_MS) {
  const intervalRef = useRef<any>(null);

  const stopInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const startInterval = useCallback(
    (callback: () => void, options: UseIntervalOptions = {}) => {
      stopInterval();

      if (options.leading) {
        callback();
      }

      const start = () => {
        intervalRef.current = setInterval(
          callback,
          options.intervalMs ?? intervalMs,
        );
      };

      if (options.delayMs) {
        intervalRef.current = setTimeout(start, options.delayMs);
      } else {
        start();
      }
    },
    [intervalMs, stopInterval],
  );

  // clear the interval when the component unmounts
  useEffect(() => {
    return () => {
      stopInterval();
    };
  }, [stopInterval]);

  return { startInterval, stopInterval };
}
