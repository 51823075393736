import { useMemo } from 'react';

import type { SafeguardState } from '@sb/routine-runner';

import useIsRobotPaused from './useIsRobotPaused';
import useRobotConnectionStatus from './useRobotConnectionStatus';
import { useRobotSafeguardState } from './useRobotSafeguardState';
import useRobotStateKind from './useRobotStateKind';

export interface RobotBadgeStatus {
  kind:
    | 'antigravity'
    | 'disconnected'
    | 'failed'
    | 'idle'
    | 'loading'
    | 'moving'
    | 'running'
    | 'paused'
    | 'estop';
  label: string;
  labelClassName: string;
  ledClassName: string;
  isConnected: boolean;
  safeguardState: SafeguardState | null;
}

export function useRobotBadgeStatus(): RobotBadgeStatus {
  const routineRunnerStateKind = useRobotStateKind({});

  const safeguardState = useRobotSafeguardState({});

  const isPaused = useIsRobotPaused({});

  const { kind: connectionStatus } = useRobotConnectionStatus({});

  const badgeStatus: RobotBadgeStatus = useMemo(() => {
    const isConnected = connectionStatus === 'connected';

    if (connectionStatus === 'disconnected') {
      return {
        kind: 'disconnected',
        label: 'Disconnected',
        labelClassName: 'tw-text-red',
        ledClassName: 'tw-bg-red',
        isConnected,
        safeguardState,
      };
    }

    if (
      connectionStatus === 'constructing' ||
      connectionStatus === 'connecting'
    ) {
      return {
        kind: 'loading',
        label: 'Loading',
        labelClassName: 'tw-text-label-tertiary',
        ledClassName: 'tw-bg-metal-70',
        isConnected,
        safeguardState,
      };
    }

    if (safeguardState === 'eStop') {
      return {
        kind: 'estop',
        label: 'E-Stop',
        labelClassName: 'tw-text-red',
        ledClassName: 'tw-bg-red',
        isConnected,
        safeguardState,
      };
    }

    if (routineRunnerStateKind === 'Failure') {
      return {
        kind: 'failed',
        label: 'Failed',
        labelClassName: 'tw-text-red',
        ledClassName: 'tw-bg-red',
        isConnected,
        safeguardState,
      };
    }

    if (routineRunnerStateKind === 'RunningAdHocCommand') {
      return {
        kind: 'moving',
        label: 'Moving',
        labelClassName: 'tw-text-label-tertiary',
        ledClassName: 'tw-bg-green',
        isConnected,
        safeguardState,
      };
    }

    if (routineRunnerStateKind === 'Recovering') {
      return {
        kind: 'moving',
        label: 'Recovering',
        labelClassName: 'tw-text-label-tertiary',
        ledClassName: 'tw-bg-green',
        isConnected,
        safeguardState,
      };
    }

    if (routineRunnerStateKind === 'Antigravity') {
      return {
        kind: 'antigravity',
        label: 'Antigravity',
        labelClassName: 'tw-text-label-tertiary',
        ledClassName: 'tw-bg-green',
        isConnected,
        safeguardState,
      };
    }

    if (routineRunnerStateKind === 'AntigravitySlow') {
      return {
        kind: 'antigravity',
        label: 'Antigravity',
        labelClassName: 'tw-text-label-tertiary',
        ledClassName: 'tw-bg-green',
        isConnected,
        safeguardState,
      };
    }

    if (routineRunnerStateKind === 'RoutineRunning' && !isPaused) {
      return {
        kind: 'running',
        label: 'Running',
        labelClassName: 'tw-text-green',
        ledClassName: 'tw-bg-green',
        isConnected,
        safeguardState,
      };
    }

    if (isPaused) {
      return {
        kind: 'paused',
        label: 'Paused',
        labelClassName: 'tw-text-green',
        ledClassName: 'tw-bg-green',
        isConnected,
        safeguardState,
      };
    }

    return {
      kind: 'idle',
      label: 'Idle',
      labelClassName: 'tw-text-label-tertiary',
      ledClassName: 'tw-bg-green',
      isConnected,
      safeguardState,
    };
  }, [connectionStatus, routineRunnerStateKind, isPaused, safeguardState]);

  return badgeStatus;
}
