import * as zod from 'zod';

import { six } from '@sb/utilities';

// A set of torques for each joint in newton-meters
export const ArmJointTorques = zod.tuple([
  zod.number(),
  zod.number(),
  zod.number(),
  zod.number(),
  zod.number(),
  zod.number(),
]);

export type ArmJointTorques = zod.infer<typeof ArmJointTorques>;

export const ABSOLUTE_MAX_JOINT_TORQUES: ArmJointTorques = [
  346, 346, 163, 63, 63, 63,
];

export const COLLABORATIVE_MAX_JOINT_TORQUES: ArmJointTorques = [
  100, 100, 50, 30, 30, 30,
];

export const DEFAULT_MAX_JOINT_TORQUES = ABSOLUTE_MAX_JOINT_TORQUES;

export const ABSOLUTE_MAX_TORQUE_SHOCK_COLLISION_THRESHOLDS: ArmJointTorques =
  six(1_000);

/**
 * The default joint torque thresholds for collision detection.
 * Collision threshold is the torque at which the joint is considered to be
 * in a collision state.
 * The default values are based on iterative testing on physical arm.
 */
export const DEFAULT_TORQUE_SHOCK_COLLISION_THRESHOLDS: ArmJointTorques = [
  120, 120, 100, 45, 45, 45,
];
