import { useEffect, useState } from 'react';

import type { FeatureFlagData, FeatureFlagKey } from '@sb/feathers-types';
import {
  onLoadFeatureFlag,
  updateFeatureFlag,
} from '@sbrc/services/feathers-client';

function getLocalStorageKey(key: string) {
  return `featureFlag:${key}`;
}

export const useSharedFeatureFlag = (key: FeatureFlagKey): boolean | null => {
  const [flag, setFlag] = useState<boolean | null>(null);

  useEffect(() => {
    return onLoadFeatureFlag((flags: FeatureFlagData) => {
      setFlag(flags[key]);
    });
  }, [key]);

  return flag;
};

export function useLocalFeatureFlagValue(key: FeatureFlagKey): string | null {
  const lsKey = getLocalStorageKey(key);

  const [value, setValue] = useState(window.localStorage.getItem(lsKey));

  useEffect(() => {
    const listener = (ev: StorageEvent) => {
      if (ev.storageArea === window.localStorage && ev.key === lsKey) {
        setValue(ev.newValue);
      }
    };

    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [lsKey]);

  return value;
}

export const useLocalFeatureFlag = (key: FeatureFlagKey): boolean =>
  useLocalFeatureFlagValue(key) === 'on';

export function useFeatureFlag(key: FeatureFlagKey): boolean {
  const local = useLocalFeatureFlagValue(key);
  const shared = !!useSharedFeatureFlag(key);

  if (local !== null) return local === 'on';

  return shared;
}

export async function setSharedFeatureFlag(
  key: FeatureFlagKey,
  value: boolean,
) {
  await updateFeatureFlag(key, value);
}

export function setLocalFeatureFlag(key: FeatureFlagKey, value: boolean) {
  const lsKey = getLocalStorageKey(key);

  if (value) {
    window.localStorage.setItem(lsKey, 'on');
  } else {
    window.localStorage.removeItem(lsKey);
  }

  // other browser tabs receive this event automatically,
  // but we need to emit a synthetic event to tell the event listeners in _this_ tab
  window.dispatchEvent(
    new StorageEvent('storage', {
      key: lsKey,
      newValue: value ? 'on' : null,
      storageArea: window.localStorage,
    }),
  );
}
