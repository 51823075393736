import { useCallback, useEffect, useState } from 'react';

import type { DevTokensData } from '@sb/feathers-types';
import { mapPaginated } from '@sb/feathers-types';

import { getService } from '../utils';

export const useDevToken = () => {
  const [token, setToken] = useState<DevTokensData | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const subscription = getService('devTokens')()
      .watch()
      .find({ query: { $limit: 1 } })
      .subscribe((response) => {
        setLoaded(true);
        const newToken = mapPaginated(response, (d) => d)[0] ?? null;
        setToken(newToken);
      });

    return () => subscription.unsubscribe();
  }, []);

  const clearToken = useCallback(async () => {
    setLoaded(false);
    const service = getService('devTokens');

    if (token?.id) {
      await service().remove(token.id);
    }

    setLoaded(true);
  }, [token, setLoaded]);

  const regenerate = useCallback(async () => {
    setLoaded(false);
    const service = getService('devTokens');

    if (token?.id) {
      await service().remove(token.id);
    }

    await service().create({});
    setLoaded(true);
  }, [token, setLoaded]);

  return {
    token: token?.token ?? null,
    regenerate,
    clearToken,
    loaded,
  } as const;
};
