import { useState } from 'react';

import { Icon } from '../../icons';
import { useInterval } from '../../utility';
import { Button, HoldableButton } from '../button';

import { GaugeContainer } from './GaugeContainer';
import type { GaugeSliderProps } from './GaugeSlider';
import { GaugeSlider } from './GaugeSlider';
import { calculateClampedRoundedValue, INTERVAL_OPTIONS } from './util';

export interface GaugeStepperDualValueProps extends GaugeSliderProps {}

export function GaugeStepperDualValue({
  min,
  max,
  value,
  targetValue,
  isDisabled,
  onChange,
  className,
  color,
  children,
  ...rest
}: GaugeStepperDualValueProps) {
  const [isSliding, setIsSliding] = useState(false);
  const { stopInterval, startInterval } = useInterval();

  const handleHold = (step: number) => () => {
    setIsSliding(true);
    let currentValue = targetValue ?? value;

    startInterval(() => {
      currentValue += step;
      onChange(calculateClampedRoundedValue(currentValue, min, max, step));
    }, INTERVAL_OPTIONS);
  };

  const handleRelease = () => {
    setIsSliding(false);
    stopInterval();
  };

  const getTargetValue = () => {
    if (isSliding) {
      return targetValue ?? value;
    }

    return targetValue;
  };

  return (
    <GaugeContainer className={className}>
      <HoldableButton
        className="tw-rounded-6"
        onHold={handleHold(-rest.step)}
        onRelease={handleRelease}
        isPulseDisabled
        disabled={isDisabled || (targetValue ?? value) <= min}
        color={color}
      >
        <Icon kind="minus" />
      </HoldableButton>

      <GaugeSlider
        min={min}
        max={max}
        value={value}
        targetValue={getTargetValue()}
        isDisabled={isDisabled}
        onChange={onChange}
        setIsSliding={setIsSliding}
        color={color}
        {...rest}
      />

      <HoldableButton
        className="tw-rounded-6"
        onHold={handleHold(rest.step)}
        onRelease={handleRelease}
        isPulseDisabled
        disabled={isDisabled || (targetValue ?? value) >= max}
        color={color}
      >
        <Icon kind="plus" />
      </HoldableButton>

      <Button
        className="tw-rounded-6"
        onClick={() => onChange(value)}
        disabled={
          isDisabled || targetValue === undefined || Number.isNaN(value)
        }
        color={color}
      >
        <Icon kind="arrowUturnLeft" />
      </Button>

      {children}
    </GaugeContainer>
  );
}
