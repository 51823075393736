import type { PressButtonStep } from '@sb/remote-control/types';
import type {
  PressButtonStepArguments,
  SpeedProfile,
} from '@sb/routine-runner';

import { calculateSpeedProfileFromStepMotionSpeed } from '../calculateSpeedProfileFromStepMotionSpeed';
import { validateMotionSpeed } from '../validators';

export const convertPressButtonConfigurationToRoutineRunner = (
  stepID: string,
  baseSpeedProfile: SpeedProfile,
  configuration?: PressButtonStep.Arguments,
): PressButtonStepArguments => {
  if (!configuration) {
    throw new Error(`PressButton step is not configured. stepID: ${stepID}`);
  }

  const args = { ...configuration };

  if (!args.prePressTarget?.jointAngles || !args.pressTarget?.jointAngles) {
    throw new Error(
      `PressButton doesn't have a valid configuration. stepID: ${stepID}`,
    );
  }

  validateMotionSpeed('Press Button', args.motionSpeed);

  const speedProfile: SpeedProfile = calculateSpeedProfileFromStepMotionSpeed({
    motionSpeed: args.motionSpeed,
    baseSpeedProfile,
  });

  return {
    prePressTarget: args.prePressTarget.jointAngles,
    pressDurationMS: args.pressDurationSeconds * 1000,
    pressTarget: args.pressTarget.jointAngles,
    speedProfile,
  };
};
