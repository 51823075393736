import {
  COMMAND_KIND_DEFAULT,
  COMMAND_SCREW_LENGTH_MM_DEFAULT,
} from './constants';
import type {
  CommandKind,
  OnRobotScrewdriverCommand,
  OnRobotScrewdriverState,
} from './types';

export function calculateORScrewdriverCommandFromState(
  state: OnRobotScrewdriverState,
  screwLength: number = COMMAND_SCREW_LENGTH_MM_DEFAULT,
  commandKind: CommandKind = COMMAND_KIND_DEFAULT,
): OnRobotScrewdriverCommand {
  const command: OnRobotScrewdriverCommand = {
    kind: 'OnRobotScrewdriverCommand',
    commandKind,
    targetForce: state.targetForce,
    screwLength,
    targetTorque: state.targetTorque,
  };

  return command;
}
