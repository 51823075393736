import * as zod from 'zod';

import type {
  OnRobotVGP20ChannelCommand,
  OnRobotVGP20Command,
} from '../../types';

import type { ActuateVacuumArguments } from './Arguments';

export namespace ActuateVacuumStepDatabase {
  export const ActuationCommandKind = zod.union([
    zod.literal('RELEASE'),
    zod.literal('GRIP'),
    zod.literal('IDLE'),
  ]);

  export const Arguments = zod.object({
    argumentKind: zod.literal('ActuateVacuum'),
    oneCommandKind: ActuationCommandKind.default('IDLE'),
    twoCommandKind: ActuationCommandKind.default('IDLE'),
    threeCommandKind: ActuationCommandKind.default('IDLE'),
    fourCommandKind: ActuationCommandKind.default('IDLE'),
    overallSuctionPercentage: zod.number().default(1),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const convertStepToRoutineRunnerArgs = (
    configuration: Arguments | void,
    stepID: string,
  ): ActuateVacuumArguments => {
    if (!configuration) {
      throw new Error(
        `'ActuateVacuum step is not configured. stepID: ${stepID}'`,
      );
    }

    const getChannelCommand = (
      channel: keyof OnRobotVGP20Command['suctionActions'],
    ): OnRobotVGP20ChannelCommand => {
      const commandKind = configuration[`${channel}CommandKind`];

      return {
        commandKind,
        suctionPercentage:
          commandKind === 'GRIP' ? configuration.overallSuctionPercentage : 0,
      };
    };

    return {
      gripperCommand: {
        kind: 'OnRobotVGP20Command',
        suctionActions: {
          one: getChannelCommand('one'),
          two: getChannelCommand('two'),
          three: getChannelCommand('three'),
          four: getChannelCommand('four'),
        },
      },
    };
  };
}
