import type { Step, Routine } from '@sb/remote-control/types';

const checkStepConfigured = (
  _step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
): Routine.ActionRequiredStep | undefined => {
  if (!stepConfiguration?.args) {
    return {
      kind: 'missingConfiguration',
      message: 'This step needs to be configured.',
    };
  }

  return undefined;
};

export const stepConfiguredValidator = checkStepConfigured;
