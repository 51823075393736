import * as zod from 'zod';

import { HaasRunProgramArguments } from '../steps/HaasRunProgram/Arguments';

/**
 * Types matching the routine runner schema for "HaasRunProgram" Steps
 */
export namespace HaasRunProgramStepDatabase {
  /** Arguments to be passed to the routine runner */
  export const Arguments = zod.object({
    argumentKind: zod.literal('HaasRunProgram'),
    ...HaasRunProgramArguments.shape,
    programName: zod.string().default(''),
    waitUntilIdle: HaasRunProgramArguments.shape.waitUntilIdle.default(true),
    disableCellSafe:
      HaasRunProgramArguments.shape.disableCellSafe.default(false),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const convertStepToRoutineRunnerArgs = (
    configuration: Arguments | void,
    stepID: string,
  ): HaasRunProgramArguments => {
    if (!configuration) {
      throw new Error(
        `HaasRunProgram step is not configured. stepID: ${stepID}`,
      );
    }

    return configuration;
  };
}
