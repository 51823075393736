import type { Placement } from '@popperjs/core';
import cx from 'classnames';
import { useEffect, useId, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { useClickOutside, usePortalContainer } from '../../utility';

import type { TooltipColor } from './tooltipColor';
import { TOOLTIP_COLOR_CLASSES } from './tooltipColor';
import { useTooltipStore } from './useTooltipStore';

export interface TooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  content: React.ReactNode;
  color?: TooltipColor;
  placement?: Placement;
  offset?: number;
  skidding?: number;
  // isOpen is defined for permanent tooltip's opening
  isOpen?: boolean | null;
  // set to 0 to act like a toggle button
  delayMS?: number;
}

export function Tooltip({
  children,
  content,
  color = 'Gray',
  placement = 'top',
  offset = 6,
  skidding = 0,
  delayMS = 1500,
  isOpen = null,
  ...rest
}: TooltipProps) {
  const tooltipContainer = usePortalContainer();

  const tooltipID = useId();
  const lastPointerDownTimeRef = useRef(0);

  const { activeTooltipID, isAnyTooltipOpen, openTooltip, closeTooltip } =
    useTooltipStore();

  const isTooltipOpen = activeTooltipID === tooltipID && isAnyTooltipOpen;

  /** Clickable element used to open the popover. */
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  /** Element responsible for displaying the popover. */
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  /** Element responsible for displaying an arrow. */
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      strategy: 'fixed', // avoid triggering overflow on body
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        { name: 'offset', options: { offset: [skidding, offset] } },
        { name: 'preventOverflow', options: { padding: 5 } },
        { name: 'eventListeners', enabled: isTooltipOpen },
      ],
    },
  );

  // Close the tooltip when we click outside the reference element.
  useClickOutside([referenceElement, popperElement], () => {
    closeTooltip(tooltipID, 0);
  });

  // Close the popover on scroll when it's not scrollable and uncontrolled (without isOpen).
  useEffect(() => {
    if (!isTooltipOpen) {
      return undefined;
    }

    const closePopover = () => closeTooltip(tooltipID, 0);

    window.addEventListener('wheel', closePopover);

    return () => window.removeEventListener('wheel', closePopover);
  }, [isTooltipOpen, tooltipID, closeTooltip]);

  /* eslint-disable jsx-a11y/no-static-element-interactions */

  return (
    <>
      <div
        {...rest}
        ref={setReferenceElement}
        onPointerDown={() => {
          lastPointerDownTimeRef.current = Date.now();

          if (isTooltipOpen) {
            closeTooltip(tooltipID, 10);
          } else {
            openTooltip(tooltipID, delayMS);
          }
        }}
        onPointerUp={() => {
          // avoid opening tooltip if mouse hasn't been held down long enough
          if (!isTooltipOpen) {
            closeTooltip(tooltipID, 10);
          }
        }}
        onPointerEnter={(e) => {
          if (e.pointerType === 'mouse') {
            openTooltip(tooltipID, delayMS);
          }
        }}
        onPointerLeave={(e) => {
          if (e.pointerType === 'mouse') {
            closeTooltip(tooltipID, 500);
          }
        }}
        onFocus={() => {
          // show on focus if focus came via keyboard, not click/tap
          if (Date.now() - lastPointerDownTimeRef.current > 100) {
            openTooltip(tooltipID, delayMS);
          }
        }}
        onBlur={() => closeTooltip(tooltipID, 10)}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            closeTooltip(tooltipID, 10);
          }
        }}
      >
        {children}
      </div>

      {createPortal(
        <div
          ref={setPopperElement}
          className={cx(
            TOOLTIP_COLOR_CLASSES[color],
            'tw-max-w-[200px]',
            'tw-text-center',
            'tw-text-13',
            'tw-px-8',
            'tw-py-4',
            'tw-rounded-6',
            'tw-drop-shadow-60',
            'tw-pointer-events-none',
            // override browser's [hidden] rule so content fades out
            '[&[hidden]]:tw-block',
            '[&[hidden]]:tw-invisible',
            '[&[hidden]]:tw-opacity-0',
            'data-[popper-reference-hidden=true]:tw-invisible',
            'data-[popper-reference-hidden=true]:tw-opacity-0',
            'tw-transition-opacity',
            'tw-z-50',
          )}
          style={popperStyles.popper}
          {...attributes.popper}
          hidden={!(isOpen ?? isTooltipOpen)}
        >
          <div
            ref={setArrowElement}
            className={cx(
              'tw-absolute',
              'tw-w-8',
              'tw-h-8',
              'tw-bg-[inherit]',
              // ◆ diamond shape
              '[clip-path:polygon(50%_0,100%_50%,50%_100%,0_50%)]',
              '[[data-popper-placement^="top"]>&]:-tw-bottom-4',
              '[[data-popper-placement^="bottom"]>&]:-tw-top-4',
              '[[data-popper-placement^="left"]>&]:-tw-right-4',
              '[[data-popper-placement^="right"]>&]:-tw-left-4',
            )}
            style={popperStyles.arrow}
          />
          {content}
        </div>,
        tooltipContainer,
      )}
    </>
  );
}
