import { ModbusFunctionCode } from '@sb/integrations/modbus/constants';
import type { Step, Routine } from '@sb/remote-control/types';

const checkModbusConfig = (
  _step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
): Routine.ActionRequiredStep | undefined => {
  if (stepConfiguration?.stepKind !== 'NetworkRequest') return undefined;
  const { args } = stepConfiguration;
  if (!args) return undefined;
  const { command } = args;

  if (command.kind === 'ModbusTCPServer') {
    const { request } = command;

    if (request.kind === ModbusFunctionCode.Read) {
      if (command.registerId === '') {
        return {
          kind: 'invalidConfiguration',
          message: 'Modbus read request requires a register ID',
          fieldId: 'command',
        };
      }

      if (request.variableIDList.length === 0) {
        return {
          kind: 'invalidConfiguration',
          message: 'Modbus read request requires variables to be set',
          fieldId: 'command',
        };
      }
    }

    if (command.request.kind === ModbusFunctionCode.Write) {
      if (command.registerId.length === 0) {
        return {
          kind: 'invalidConfiguration',
          message: 'Modbus write request requires registerId to be set',
          fieldId: 'command',
        };
      }
    }
  }

  return undefined;
};

export const networkRequestValidators = [checkModbusConfig];
