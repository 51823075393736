import { useCallback } from 'react';

import type { RobotBadgeStatus } from './useRobotBadgeStatus';
import useRobotJointConnectionState from './useRobotJointConnectionState';
import { useRobotSafeguardState } from './useRobotSafeguardState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';
import { useStandardBotsSdk } from './useStandardBotsSdk';
import useToast from './useToast';

const useRobotUnbrake = ({ isVizbot }: UseRoutineRunnerHandleArguments) => {
  const sdk = useStandardBotsSdk(isVizbot ?? false);

  const { setToast } = useToast();

  const displayRobotName = isVizbot ? 'Visualizer' : 'Live Robot';

  const isEstopped = useRobotSafeguardState({ isVizbot }) === 'eStop';

  const connectionStates = useRobotJointConnectionState({ isVizbot });

  const allJointsReady = connectionStates.every(
    (state: string) => state === 'ready',
  );

  const canUnbrake = useCallback(
    (confirmedNoHumans: boolean) => {
      return sdk !== null && confirmedNoHumans && !isEstopped && allJointsReady;
    },
    [sdk, allJointsReady, isEstopped],
  );

  const canBrake = useCallback(
    (status: RobotBadgeStatus) => {
      return sdk !== null && status.kind === 'idle';
    },
    [sdk],
  );

  const brakeRobot = useCallback(async () => {
    if (!sdk) return;

    try {
      setToast({ kind: 'progress', message: 'Braking robot...' });

      await sdk.brakes.brake();

      setToast({
        kind: 'success',
        message: `${displayRobotName} has successfully braked.`,
      });
    } catch (e) {
      setToast({
        kind: 'error',
        message: `${displayRobotName} could not brake. Please try again.`,
      });
    }
  }, [displayRobotName, sdk, setToast]);

  const unbrakeRobot = useCallback(async () => {
    if (!sdk) return;

    try {
      setToast({ kind: 'progress', message: 'Unbraking robot...' });

      await sdk.brakes.unbrake();

      setToast({
        kind: 'success',
        message: `${displayRobotName} has successfully unbraked.`,
      });
    } catch (e) {
      setToast({
        kind: 'error',
        message: `${displayRobotName} could not unbrake. Please try again`,
      });
    }
  }, [displayRobotName, sdk, setToast]);

  return { brakeRobot, unbrakeRobot, canBrake, canUnbrake };
};

export default useRobotUnbrake;
