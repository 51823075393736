import * as zod from 'zod';

import { IOLevel } from '@sb/routine-runner';

export namespace SetIOStep {
  export const OutputConfiguration = zod.object({
    level: IOLevel,
    port: zod.number(),
  });

  export type OutputConfiguration = zod.infer<typeof OutputConfiguration>;

  export const Arguments = zod.object({
    argumentKind: zod.literal('SetIO'),
    outputs: zod.array(OutputConfiguration).default([]),
  });

  export type Arguments = zod.infer<typeof Arguments>;
}
