import cx from 'classnames';

import type { DialogProps } from '../../utility';
import { Dialog } from '../../utility';

export interface ModalProps extends DialogProps {}

export function Modal({ className, onClick, ...rest }: DialogProps) {
  return (
    <Dialog
      className={cx(
        className,
        'tw-surface-secondary',
        'tw-shadow-80',
        'tw-rounded-18',
        'tw-flex',
        'tw-flex-col',
        'tw-max-h-[calc(100dvh_-_60px)]',
        'tw-max-w-[calc(100dvw_-_60px)]',
        'tw-overflow-hidden',
      )}
      onClick={(e) => {
        // avoid clicks bubbling to parent
        e.stopPropagation();
        onClick?.(e);
      }}
      {...rest}
    />
  );
}
