import cx from 'classnames';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { DesignSystemContextProvider } from '../DesignSystemContext';

export interface DialogProps
  extends React.DialogHTMLAttributes<
    Omit<HTMLDialogElement, 'open' | 'hidden'>
  > {
  isOpen: boolean;
  onClose: () => void;
  isBackdropTransparent?: boolean;
}

/**
 * React wrapper around html <dialog> element
 */
export function Dialog({
  isOpen,
  onClose,
  isBackdropTransparent,
  className,
  children,
  ...rest
}: DialogProps) {
  const [isShowing, setIsShowing] = useState(true);

  const [dialogElement, setDialogElement] = useState<HTMLDialogElement | null>(
    null,
  );

  // run open/close commands on the dialog
  useEffect(() => {
    if (isOpen && dialogElement && !dialogElement.open) {
      dialogElement.showModal();
    }

    // this is distinct from `isOpen` so that it updates in the next render
    // which lets the opacity transition work
    setIsShowing(isOpen);

    if (!isOpen && dialogElement) {
      const closeAfterTransitionEnded = (e: TransitionEvent) => {
        if (e.target === dialogElement && !e.pseudoElement) {
          dialogElement.close();
        }
      };

      dialogElement.addEventListener(
        'transitionend',
        closeAfterTransitionEnded,
      );

      return () => {
        dialogElement.removeEventListener(
          'transitionend',
          closeAfterTransitionEnded,
        );
      };
    }

    return undefined;
  }, [isOpen, dialogElement]);

  return createPortal(
    <dialog
      {...rest}
      ref={setDialogElement}
      className={cx(
        /* override browser default stylesheet */
        'tw-overflow-visible',
        'tw-bg-transparent',
        'tw-p-0',
        'tw-max-h-none',
        'tw-max-w-none',
        /* background */
        isBackdropTransparent
          ? 'backdrop:tw-bg-transparent'
          : 'backdrop:tw-bg-black/20',
        /* fade in on open and fade out on close */
        !isShowing && 'tw-opacity-0',
        'tw-transition-opacity',
        !isBackdropTransparent && !isShowing && 'backdrop:tw-opacity-0',
        !isBackdropTransparent && 'backdrop:tw-transition-opacity',
      )}
      onCancel={(e) => {
        // close on press escape
        e.preventDefault();
        onClose();
      }}
      onPointerDown={(e) => {
        // close on click outside
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <DesignSystemContextProvider portalContainer={dialogElement}>
        <div className={className}>{children}</div>
      </DesignSystemContextProvider>
    </dialog>,
    document.body,
  );
}
