import type { Routine, User } from '@sb/remote-control/types';

import { createRoutineDocument, getRoutinesService } from './utils';

export async function createRoutine(
  routine: Partial<Routine.EditableFields>,
  createdBy: User.ConvertedResponse,
): Promise<string> {
  const newRoutine = await createRoutineDocument(routine, createdBy);

  const response = await getRoutinesService().create(newRoutine);

  return response.id;
}
