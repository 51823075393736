import type { LocateStep } from '@sb/remote-control/types';
import type { LocateStepArguments } from '@sb/routine-runner';

export const convertLocateConfigurationToRoutineRunner = (
  stepID: string,
  configuration?: LocateStep.Arguments,
): LocateStepArguments => {
  if (!configuration) {
    throw new Error(`Locate step is not configured. stepID: ${stepID}`);
  }

  return {
    camera: configuration.camera,
    regionOfInterest: configuration.regionOfInterest,
    method: configuration.method,
    positionListID: stepID,
    filters: {
      resultsLimit: configuration.resultsLimit,
    },
    transform: configuration.transform,
    planeID: configuration.planeID!,
  };
};
