import * as zod from 'zod';

import { ModbusFunctionCode } from '@sb/integrations/modbus/constants';

import { ModbusTCPRegisterData } from '../types';

export const ModbusTCPServerReadRequest = zod.object({
  kind: zod.literal(ModbusFunctionCode.Read),
  register: ModbusTCPRegisterData,
  variableIDList: zod.array(zod.string()),
  quantity: zod.number().optional(),
});

export const ModbusTCPServerWriteRequest = zod.object({
  kind: zod.literal(ModbusFunctionCode.Write),
  register: ModbusTCPRegisterData,
  valueList: zod.array(zod.number()),
});

export const ModbusTCPServerRequest = zod.union([
  ModbusTCPServerReadRequest,
  ModbusTCPServerWriteRequest,
]);

export const ModbusTCPServerCommand = zod.object({
  kind: zod.literal('ModbusTCPServer'),
  equipmentId: zod.string(),
  request: ModbusTCPServerRequest,
});

export type ModbusTCPServerRequest = zod.infer<typeof ModbusTCPServerRequest>;

export type ModbusTCPServerReadRequest = zod.infer<
  typeof ModbusTCPServerReadRequest
>;

export type ModbusTCPServerWriteRequest = zod.infer<
  typeof ModbusTCPServerWriteRequest
>;

export type ModbusTCPServerCommand = zod.infer<typeof ModbusTCPServerCommand>;
