import cx from 'classnames';

import type { SettingsGroupItemProps } from './SettingsGroupItem';
import { SettingsGroupItem } from './SettingsGroupItem';

export interface SettingsGroupButtonItemProps extends SettingsGroupItemProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
}

export function SettingsGroupButtonItem({
  children,
  className,
  onClick,
  disabled,
  ...rest
}: SettingsGroupButtonItemProps) {
  return (
    <SettingsGroupItem
      role="button"
      aria-disabled={disabled}
      onClick={disabled ? undefined : onClick}
      className={cx(
        className,
        'tw-select-none',
        'tw-text-orange',
        'aria-disabled:tw-text-label-quaternary',
        'aria-disabled:tw-cursor-not-allowed',
        !disabled && 'active:tw-brightness-90',
        !disabled && 'hover:tw-brightness-110',
      )}
      {...rest}
    >
      {children}
    </SettingsGroupItem>
  );
}
