import { mapPaginated } from '@sb/feathers-types';
import type { Robot } from '@sb/remote-control/types';

import { getAllEnabledEquipment } from '../equipment';
import { getSafetySettings } from '../safety-settings';

import { convertRobotResponse, getRobotsService } from './utils';

export async function getRobot(
  robotID: string,
): Promise<Robot.ConvertedResponse | null> {
  const [response, safetySettings, equipment] = await Promise.all([
    getRobotsService().find({
      query: { robotID, $limit: 1 },
    }),
    getSafetySettings(robotID),
    getAllEnabledEquipment(),
  ]);

  const robot =
    mapPaginated(
      response,
      convertRobotResponse(() => safetySettings.safeguardRules, equipment),
    )[0] ?? null;

  return robot;
}
