import type { Routine } from '@sb/remote-control/types';
import { removeVirtualSpaceItems } from '@sb/remote-control/util/virtualSpaceItems';

import { getRoutinesService } from './utils';

export async function updateRoutine(
  changes: Partial<Routine.EditableFields>,
  routineID: string,
): Promise<void> {
  await getRoutinesService().patch(routineID, {
    ...changes,
    space: removeVirtualSpaceItems(changes.space),
  });
}
