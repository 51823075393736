import type { OR3FG15CommandKind } from '../constants';
import { OR_3FG15_FLEX_GRIP_FORCE_NEWTONS_MAX } from '../constants';

export function calculateTargetForceFromCommandKind(
  commandKind: OR3FG15CommandKind,
  targetForce: number,
  setForce: (max: number) => void,
) {
  if (
    commandKind === 'flexGrip' &&
    targetForce > OR_3FG15_FLEX_GRIP_FORCE_NEWTONS_MAX
  ) {
    setForce(OR_3FG15_FLEX_GRIP_FORCE_NEWTONS_MAX);
  }

  return targetForce;
}
