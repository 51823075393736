import type { FailureState, RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getFailureState(
  routineRunnerState: RoutineRunnerState | null,
): FailureState | null {
  return routineRunnerState?.failureState ?? null;
}

export default function useRobotFailureState(
  args: UseRoutineRunnerHandleArguments,
): FailureState | null {
  return useRobotState(args, getFailureState);
}
