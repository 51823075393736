export enum TestBoardAnalogPin {
  // ADC
  PF5,
  PF6,
  PC0,
  PH2,
  PH3,
  PC3,
  PA1,
  PF8,
  PF9,
  // DAC
  PA4,
  PA5,
}
