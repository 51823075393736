import * as zod from 'zod';

/**
 * Types matching the routine runner schema for "IF" statements.
 */
export namespace IfStep {
  /** Arguments to be passed to the routine runner */
  export const Arguments = zod.object({
    argumentKind: zod.literal('If'),
    /**
     * The actual type is ConditionalBuilderState[][] but Firestore doesn't support nested
     * arrays, so we need to serialize/desarialize this property when using it.
     */
    condition: zod.string(),
  });

  export type Arguments = zod.infer<typeof Arguments>;
}
