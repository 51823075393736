import type { Step, Routine } from '@sb/remote-control/types';

import { DECORATOR_STEP_LIST } from '../../constants';
import { findStep } from '../../findStep';

const checkValidPositionConfig = (
  _step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
): Routine.ActionRequiredStep | undefined => {
  if (stepConfiguration?.stepKind !== 'MoveArmTo') return undefined;
  const { args } = stepConfiguration;
  if (!args) return undefined;

  const hasTarget = !!(args.target?.jointAngles || args.target?.pose);

  if (args.targetKind === 'singlePosition' && !hasTarget) {
    return {
      kind: 'invalidConfiguration',
      message: 'Joint angles or pose must be set',
      fieldId: 'jointAngles',
    };
  }

  if (args.targetKind === 'positionList' && !args.positionListID) {
    return {
      kind: 'invalidConfiguration',
      message: 'Position list must be set',
      fieldId: 'positionListID',
    };
  }

  return undefined;
};

const checkPositionListDefiled = (
  _step: unknown,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
  { space }: Pick<Routine.ConvertedResponse, 'space'>,
): Routine.ActionRequiredStep | undefined => {
  if (
    !stepConfiguration ||
    stepConfiguration.stepKind !== 'MoveArmTo' ||
    !stepConfiguration.args
  ) {
    return undefined;
  }

  if (
    stepConfiguration.args.targetKind === 'positionList' &&
    !space.some((s) => s.id === stepConfiguration.args?.positionListID)
  ) {
    return {
      kind: 'invalidConfiguration',
      message: 'Set the Position list linked to Move Arm step.',
    };
  }

  return undefined;
};

const checkAddOffsetJointAngles = (
  step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
): Routine.ActionRequiredStep | undefined => {
  if (!stepConfiguration || stepConfiguration.stepKind !== 'MoveArmTo') {
    return undefined;
  }

  const hasAddOffsetParentStep = step.parentSteps.some(
    (parentStep) => parentStep?.stepKind === 'AddOffset',
  );

  if (
    hasAddOffsetParentStep &&
    (stepConfiguration.args?.shouldMatchJointAngles ?? false)
  ) {
    return {
      kind: 'invalidConfiguration',
      message:
        'Move Arm steps inside an Add Offset step may not require exact final joint position, as only the tooltip pose can be guaranteed. Edit the path settings in the step to resolve.',
    };
  }

  return undefined;
};

const checkWaypoints = (
  _step: unknown,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
  { steps }: Pick<Routine.ConvertedResponse, 'steps'>,
): Routine.ActionRequiredStep | undefined => {
  if (
    !stepConfiguration ||
    stepConfiguration.stepKind !== 'MoveArmTo' ||
    !stepConfiguration.args?.isWaypoint
  ) {
    return undefined;
  }

  let isWaypointFound = false;

  // find first step after waypoint which isn't a decorator step
  const firstNonDecoratorStepAfterWaypoint = findStep(steps, (step) => {
    if (step.id === stepConfiguration.id) {
      isWaypointFound = true;

      return undefined;
    }

    return isWaypointFound && !DECORATOR_STEP_LIST.includes(step.stepKind);
  });

  if (
    !firstNonDecoratorStepAfterWaypoint ||
    firstNonDecoratorStepAfterWaypoint.stepKind !== 'MoveArmTo'
  ) {
    return {
      kind: 'invalidConfiguration',
      message: 'Waypoint steps must have a terminal Move Arm step.',
    };
  }

  return undefined;
};

export const moveArmToValidations = [
  checkValidPositionConfig,
  checkPositionListDefiled,
  checkAddOffsetJointAngles,
  checkWaypoints,
];
