import { isObject, isUndefined, mapValues, omitBy } from 'lodash';

/**
 * Strips undefined from objects.
 * Helpful for comparing state in Feathers routineRunnerState service
 * which does not store `undefined` values
 */
export function omitUndefined(value: any, recursive: boolean = true): any {
  if (Array.isArray(value)) {
    if (recursive) {
      return value.map((item) => omitUndefined(item, true));
    }

    return value;
  }

  if (isObject(value)) {
    const omitted = omitBy(value, isUndefined);

    if (!recursive) {
      return omitted;
    }

    return mapValues(omitted, (item: object) => omitUndefined(item, true));
  }

  return value;
}
