import cx from 'classnames';

export interface SettingsGroupProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export function SettingsGroup({ className, ...rest }: SettingsGroupProps) {
  return (
    <div
      {...rest}
      className={cx(
        className,
        'tw-flex',
        'tw-flex-col',
        'tw-rounded-10',
        'tw-surface-elevated',
        'tw-shadow-30',
        'tw-divide-y',
        'tw-divide-divider-primary',
      )}
    />
  );
}
