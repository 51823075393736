import type { Robot } from '@sb/remote-control/types';

import { getRobotsService } from './utils';
import { cleanIOPortsForUpdate } from './utils/cleanIOPortsForUpdate';

export async function updateRobot(
  robotID: string,
  robot: Robot.Update,
): Promise<void> {
  await getRobotsService().patch(
    null,
    {
      ioInputs: cleanIOPortsForUpdate(robot.ioInputs),
      ioOutputs: cleanIOPortsForUpdate(robot.ioOutputs),
      latestRoutineID: robot.latestRoutineID,
      name: robot.name,
      speedRestrictionPercentage: robot.speedRestrictionPercentage,
      // there's nothing magic about the string 'now'
      // the backend will translate any non-nil value to the current time
      testRunCompletedAt: robot.isTestRunCompleted ? 'now' : undefined,
    },
    { query: { robotID } },
  );
}
