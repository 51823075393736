export enum TestBoardDigitalPin {
  // INPUTS
  PB7,
  PE4,
  PI9,
  PE3,
  PI13,
  // OUTPUTS
  PF2,
  PI12,
  PF3,
  PH5,
  PC2,
  PA0,
  PF7,
  PF10,
  // OPEN DRAIN
  PE0,
  PE5,
  PC13,
}
