import { Vector3 } from 'three';
import type * as zod from 'zod';

import type { Plane } from '@sb/geometry';
import * as log from '@sb/log';
import type { ArmJointPositions } from '@sb/motion-planning';
import { FailureKind } from '@sb/routine-runner/FailureKind';
import type { ArmPosition } from '@sb/routine-runner/types';
import { runLocate } from '@sb/routine-runner/vision/runLocate';
import { six } from '@sb/utilities';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

const ns = log.namespace('locateStep');

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

/**
 * We save a dummy set of joint angles for each position. Calculation of the joint angles
 * to use to reach the position should be deferred until a move arm step is played
 */
const NULL_JOINT_ANGLES: ArmJointPositions = six(0);

export default class LocateStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    this.variables = {
      latestResult: null,
      resultCount: 0,
    };
  }

  private getPlaneSpaceItem() {
    if (!this.args.planeID) {
      throw new Error('No planeID provided');
    }

    const plane = this.routineContext.getSpaceItem(this.args.planeID);

    if (plane.kind !== 'plane') {
      throw new Error(`SpaceItem ${this.args.planeID} is not a plane`);
    }

    return plane;
  }

  private getPlaneFromSpaceItemID(): Plane {
    const plane = this.getPlaneSpaceItem();

    const planePositions = plane.positions.map(
      (position) =>
        new Vector3(position.pose.x, position.pose.y, position.pose.z),
    );

    return {
      origin: planePositions[0],
      plusX: planePositions[1],
      plusY: planePositions[2],
    };
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    try {
      log.info(ns`locate.play`, 'Playing Locate step');

      const wristCamera = this.routineContext.equipment.getWristCamera();

      if (!wristCamera) {
        throw new Error('No wrist camera found in Equipment');
      }

      const runLocateResults = await runLocate({
        wristCamera,
        camera: this.args.camera,
        regionOfInterest: this.args.regionOfInterest,
        method: this.args.method,
        plane: this.getPlaneFromSpaceItemID(),
        resultsLimit: this.args.filters.resultsLimit,
        transform: this.args.transform,
        accuracyCalibration: this.getPlaneSpaceItem().calibrationEntries,
        vision: this.routineContext.vision,
        getState: () => this.routineContext.getRoutineRunnerState(),
      });

      const positions: ArmPosition[] = runLocateResults.map((result) => {
        return { pose: result.pose, jointAngles: NULL_JOINT_ANGLES };
      });

      this.setVariable('latestResult', positions);
      this.setVariable('resultCount', positions.length);

      this.routineContext.setPositionListEntries(
        this.args.positionListID,
        positions,
      );
    } catch (error) {
      log.error(ns`locate.play`, 'Locate failed', error);

      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'Locate',
        },
        failureReason: `${error.message}`,
        error,
      });
    }
  }
}
