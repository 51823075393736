import cx from 'classnames';

import { Icon } from '../../icons';

import type { NavigationListItemVariant } from './navigationListItemVariant';
import { NAVIGATION_LIST_ITEM_VARIANT_CLASSES } from './navigationListItemVariant';

export interface NavigationListItemProps
  extends React.LiHTMLAttributes<HTMLLIElement> {
  variant?: NavigationListItemVariant;
  disabled?: boolean;
}

export function NavigationListItem({
  variant = 'Flat',
  disabled,
  className,
  children,
  onClick,
  ...rest
}: NavigationListItemProps) {
  return (
    <li
      className={cx(
        className,
        'tw-group',
        'tw-min-h-[60px]',
        'tw-flex-none',
        'tw-flex',
        'tw-items-center',
        'tw-gap-12',
        'tw-px-16',
        NAVIGATION_LIST_ITEM_VARIANT_CLASSES[variant],
        'aria-disabled:tw-cursor-not-allowed',
      )}
      role={onClick ? 'button' : undefined}
      aria-disabled={disabled}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      {children}
      {onClick && (
        <Icon
          kind="chevronRight"
          className={cx(
            'tw-text-label-quaternary',
            'tw-icon-22',
            'tw-mx-[-5px]',
          )}
        />
      )}
    </li>
  );
}
