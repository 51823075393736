import cx from 'classnames';

export interface NavigationListLabelProps {
  children: React.ReactNode;
  subtitleAbove?: React.ReactNode;
  subtitleBelow?: React.ReactNode;
}

export function NavigationListLabel({
  children,
  subtitleAbove,
  subtitleBelow,
}: NavigationListLabelProps) {
  return (
    <div
      className={cx(
        'tw-flex',
        'tw-flex-col',
        'tw-flex-1',
        'tw-text-ellipsis',
        'tw-text-label-primary',
        'group-aria-disabled:tw-text-label-quaternary',
        'tw-text-17',
        'tw-py-8',
      )}
    >
      {subtitleAbove && (
        <span
          className={cx(
            'tw-text-label-secondary',
            'group-aria-disabled:tw-text-label-quaternary',
            'tw-text-13',
          )}
        >
          {subtitleAbove}
        </span>
      )}
      <span>{children}</span>
      {subtitleBelow && (
        <span
          className={cx(
            'tw-text-label-secondary',
            'group-aria-disabled:tw-text-label-quaternary',
            'tw-text-13',
          )}
        >
          {subtitleBelow}
        </span>
      )}
    </div>
  );
}
