import * as zod from 'zod';

// Import from ./types because we don't want to pull in non-Node dependencies.
// E.g. if we pull in `fs`, Remote Control will not compile.
import {
  InstallationInfo,
  RequestFirmwareInstallData,
} from '@sb/firmware-info/types';
import { TargetFirmwareInfo } from '@sb/local-build-data/types';

export const SoftwareUpdateData = zod.object({
  activeBuildID: zod.string(),
  activeBuildName: zod.string(),
  activeFirmwareInfo: zod.array(
    InstallationInfo.extend({
      jointNumber: zod.number(),
    }),
  ),
  targetBuildID: zod.string(),
  targetBuildName: zod.string(),
  targetFirmwareInfo: TargetFirmwareInfo,
  status: zod.enum([
    'pending',
    'downloading',
    'downloaded',
    'installing',
    'installed',
  ]),
  // Indicates a software installation request that has been written by feathers-bot
  // but not yet read (and cleared) by updater-bot
  isSoftwareInstallRequested: zod.boolean(),
  // Indicates a firmware installation request that has been written by feathers-bot
  // but not yet read (and cleared) by updater-bot
  requestFirmwareInstallData: RequestFirmwareInstallData.optional(),
  lastErrorAt: zod.string().optional(),
  lastErrorMessage: zod.string().optional(),
  strategy: zod.string(),
  updatedAt: zod.string(),
});

export type SoftwareUpdateData = zod.infer<typeof SoftwareUpdateData>;
