import { useState, useEffect, useRef } from 'react';

export const useTimer = (interval: number) => {
  const intervalRef = useRef<any>(null);
  const [data, setData] = useState(Date.now());

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setData(Date.now());
    }, interval);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [interval]);

  return data;
};
