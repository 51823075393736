import * as zod from 'zod';

import { Expression } from '@sb/routine-runner';

export namespace SetEnvironmentVariableStep {
  export const Arguments = zod.object({
    argumentKind: zod.literal('SetEnvironmentVariable'),
    variableID: zod.string().optional(),
    newValue: Expression.optional(),
  });

  export type Arguments = zod.infer<typeof Arguments>;
}
