import type { Step } from '@sb/remote-control/types';

/**
 * Convert the step summary to the db format
 */
export function reverseConvertStepSummary(
  steps: Step.ConvertedSummary[],
): Step.Summary[] {
  return steps.map((step) => ({
    id: step.id,
    stepKind: step.stepKind,
    description: step.description,
    steps:
      step.steps.length > 0 ? reverseConvertStepSummary(step.steps) : undefined,
  }));
}
