export enum JointType {
  Unknown = 0,
  ElbowJoint = 1,
  WristJoint = 2,
  ShoulderJoint = 3,
  WheelJoint = 4,
  TwoFingerGripper = 5,
  SafetyPowerBoard = 6,
  VacuumGripper = 7,
  EEBoard = 10,
  IOBoard = 12,
  PowerBoard = 13,
}

export type JointIdentification = {
  jointType: JointType;
  jointNumber: number;
  serialNumber: number;
};
