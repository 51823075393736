import cx from 'classnames';
import { clamp } from 'lodash';
import { useState } from 'react';

import { countDecimalPlaces, roundToDecimalPlaces } from '@sb/utilities';

import { Icon } from '../../../icons';

import type { InputProps } from './Input';
import { Input } from './Input';

const VALID_NUMBER = /^-?[0-9]*\.?[0-9]*$/;
const VALID_INTEGER = /^-?[0-9]*$/;

export interface NumberInputProps extends Omit<InputProps, 'onChange'> {
  /**
   * If `NaN` then the field will display empty
   */
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  /**
   * Maximum decimal places to show.
   * This *doesn't* affect how many decimal digits the user can type while the field is focused.
   */
  decimalPlaces?: number;
  onIncrement?: () => void;
  onDecrement?: () => void;
  /**
   * If supplied then will show as placeholder when value is `NaN`
   */
  placeholderValue?: number;
}

export function NumberInput({
  value,
  onChange,
  decimalPlaces,
  disabled,
  min,
  max,
  step,
  onIncrement,
  onDecrement,
  placeholder,
  placeholderValue,
  ...rest
}: NumberInputProps) {
  const [localValue, setLocalValue] = useState<string | null>(null);

  const roundedValue =
    decimalPlaces !== undefined
      ? roundToDecimalPlaces(value, decimalPlaces)
      : value;

  const stringValue =
    localValue ?? (Number.isNaN(value) ? '' : String(roundedValue));

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;

    const regex = decimalPlaces === 0 ? VALID_INTEGER : VALID_NUMBER;

    // check user typed in a valid number or partial number (e.g. `-` or `.`)
    if (!regex.test(newValue)) {
      return;
    }

    setLocalValue(newValue);

    if (newValue === '') {
      onChange(NaN);
    } else {
      const newValueAsNumber = Number(newValue);

      if (!Number.isNaN(newValueAsNumber)) {
        // typed in a valid number, invoke onChange
        const clampedNewValue = clamp(
          roundToDecimalPlaces(newValueAsNumber, decimalPlaces ?? Infinity),
          min ?? -Infinity,
          max ?? Infinity,
        );

        onChange(clampedNewValue);
      }
    }
  };

  const incrementDecrement = (
    direction: number,
  ): React.FormEventHandler | undefined => {
    if (disabled) {
      return undefined;
    }

    if (!step) {
      return undefined;
    }

    return () => {
      const delta = direction * step;

      const baseValue =
        Number.isNaN(roundedValue) && placeholderValue !== undefined
          ? placeholderValue
          : roundedValue;

      const newValue = (Math.round(baseValue / delta) + 1) * delta;

      // number of decimal digits in step (e.g. 0.25 -> 2; 0.1 -> 1)
      const stepDecimalPlaces = countDecimalPlaces(step);

      const clampedNewValue = clamp(
        roundToDecimalPlaces(newValue, stepDecimalPlaces),
        min ?? -Infinity,
        max ?? Infinity,
      );

      if (!Number.isNaN(clampedNewValue)) {
        onChange(clampedNewValue);
      }
    };
  };

  const handleIncrement = onIncrement ?? incrementDecrement(1);
  const handleDecrement = onDecrement ?? incrementDecrement(-1);

  return (
    <Input
      {...rest}
      placeholder={placeholder ?? placeholderValue?.toString()}
      value={stringValue}
      onChange={handleChange}
      onBlur={() => setLocalValue(null)}
      disabled={disabled}
      contentBefore={
        <>
          {rest.contentBefore}
          <Icon
            kind="minusCircleFill"
            onClick={handleDecrement}
            // clicking on button shouldn't focus field
            onMouseDown={(e) => e.stopPropagation()}
            aria-disabled={value <= (min ?? NaN)}
            className={cx(
              'peer-disabled:tw-hidden',
              !handleDecrement && 'tw-hidden',
            )}
          />
        </>
      }
      contentAfter={
        <>
          {rest.contentAfter}
          <Icon
            kind="plusCircleFill"
            onClick={handleIncrement}
            // clicking on button shouldn't focus field
            onMouseDown={(e) => e.stopPropagation()}
            aria-disabled={value >= (max ?? NaN)}
            className={cx(
              'peer-disabled:tw-hidden',
              !handleIncrement && 'tw-hidden',
            )}
          />
        </>
      }
    />
  );
}
