import * as zod from 'zod';

const RestartCamreaCommand = zod.object({
  command: zod.literal('restartCamera'),
});

const EchoTestCommand = zod.object({
  command: zod.literal('echoTest'),
});

const ServiceRestart = zod.object({
  command: zod.literal('services_restart'),
});

const ModbusRTUUartConfig = zod.object({
  buadRate: zod.number(),
  parity: zod.enum(['none', 'even', 'odd']),
  stopBits: zod.enum(['ZeroPointFive', 'One', 'OnePointFive', 'Two']),
});

const ConfigureModbusRTUUart = zod.object({
  command: zod.literal('configureModbusRTUUart'),
  configureModbusRTUUart: ModbusRTUUartConfig,
});

export type ModbusRTUUartConfig = zod.infer<typeof ModbusRTUUartConfig>;

const InstallFirmwareCommand = zod.object({
  command: zod.literal('installFirmware'),
  installFirmware: zod.object({
    joints: zod.array(zod.number()),
    force: zod.boolean().optional(),
    build: zod.string().optional(),
  }),
});

const FactoryReset = zod.object({
  command: zod.literal('factoryReset'),
});

const PowerCycleCommand = zod.object({
  command: zod.literal('powerCycle'),
  restartJoints: zod.object({
    joints: zod.array(zod.number()),
  }),
});

const HostCommandData = zod.discriminatedUnion('command', [
  RestartCamreaCommand,
  EchoTestCommand,
  InstallFirmwareCommand,
  PowerCycleCommand,
  FactoryReset,
  ServiceRestart,
  ConfigureModbusRTUUart,
]);

export type HostCommandData = zod.infer<typeof HostCommandData>;
