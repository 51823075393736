import { isEqual } from 'lodash';

import type { ArmJointConnectionStates } from '@sb/motion-planning';
import type { RoutineRunnerState } from '@sb/routine-runner';
import { six } from '@sb/utilities';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getJointConnectionState(
  state: RoutineRunnerState | null,
): ArmJointConnectionStates {
  return state?.kinematicState?.jointConnectionStates ?? six('disconnected');
}

/**
 * Get the joint angles from the live robot or Vizbot in degrees to 1 decimal place
 */
export default function useRobotJointConnectionState(
  args: UseRoutineRunnerHandleArguments,
): ArmJointConnectionStates {
  return useRobotState<ArmJointConnectionStates>(
    args,
    getJointConnectionState,
    isEqual,
  );
}
