import type { AddOffsetStep } from '@sb/remote-control/types';
import type {
  AddOffsetStep as RRAddOffsetStep,
  StepArguments,
} from '@sb/routine-runner';

import { getDistanceUnitInfo } from '../distance';

export function convertAddOffsetConfigurationToRoutineRunner(
  stepID: string,
  configuration?: AddOffsetStep.Arguments,
): StepArguments<RRAddOffsetStep> {
  if (!configuration) {
    throw new Error(`Add Offset step is not configured. stepID: ${stepID}`);
  }

  const { multiplier } = getDistanceUnitInfo(configuration.distanceUnit);

  return {
    multiplier: 1 / multiplier,
    translationX: configuration.translationX,
    translationY: configuration.translationY,
    translationZ: configuration.translationZ,
  };
}
