import type { Step, Routine } from '@sb/remote-control/types';

import { DECORATOR_STEP_LIST } from '../constants';

/** Is decorator step that doesn't have nested steps. */
export function decoratorStepValidator(
  step: Step.ConvertedSummary,
): Routine.ActionRequiredStep | undefined {
  if (DECORATOR_STEP_LIST.includes(step.stepKind) && step.steps.length === 0) {
    return {
      kind: 'invalidConfiguration',
      message: 'Tap row below to add step.',
    };
  }

  return undefined;
}
