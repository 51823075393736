import type { SetEnvironmentVariableStep } from '@sb/remote-control/types';
import type {
  SetEnvironmentVariableStep as RRSetEnvironmentVariableStep,
  StepArguments,
} from '@sb/routine-runner';

export const convertSetEnvironmentVariableConfigurationToRoutineRunner = (
  stepID: string,
  configuration?: SetEnvironmentVariableStep.Arguments,
): StepArguments<RRSetEnvironmentVariableStep> => {
  if (!configuration) {
    throw new Error(
      `SetEnvironmentVariable step is not configured. stepID: ${stepID}`,
    );
  }

  if (!configuration.variableID || !configuration.newValue) {
    throw new Error(
      `SetEnvironmentVariable has invalid configuration. stepID: ${stepID}`,
    );
  }

  return {
    variableID: configuration.variableID,
    newValue: configuration.newValue,
  };
};
