import { startCase, camelCase } from 'lodash';

export const getPageTitle = (title?: string) => {
  let pageTitle = 'Standard Bots';

  if (title) {
    pageTitle = `${title} | ${pageTitle}`;
  }

  return pageTitle;
};

// Help Article Title Processor
export const helpArticleTitler = (slug: string) => {
  if (slug) {
    return startCase(camelCase(slug));
  }

  return '';
};

export function calculatePercentToTotal(
  value: number,
  min: number,
  max: number,
): number {
  // protect against an error caused due to division by 0
  if (max === min) {
    return 0;
  }

  return ((value - min) / (max - min)) * 100;
}
