import type { CartesianPose } from '@sb/geometry';
import { applyCompoundPose, invertPose } from '@sb/geometry';
import type { RoutineRunnerState } from '@sb/routine-runner';
import { arePosesEqual } from '@sbrc/utils';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getInvertedTCPTransform(
  state: RoutineRunnerState | null,
): CartesianPose | null {
  return state
    ? applyCompoundPose(
        state.kinematicState.wristPose,
        invertPose(state.kinematicState.tooltipPoint),
      )
    : null;
}

export function useRobotInvertedTCPTransform({
  isVizbot,
}: UseRoutineRunnerHandleArguments): CartesianPose | null {
  const invertedTCPTransform = useRobotState(
    { isVizbot },
    getInvertedTCPTransform,
    arePosesEqual,
  );

  return invertedTCPTransform;
}
