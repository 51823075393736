import * as zod from 'zod';

import {
  COMMAND_FORCE_N_DEFAULT,
  COMMAND_KIND_DEFAULT,
  COMMAND_SCREW_LENGTH_MM_DEFAULT,
  COMMAND_SHANK_POSITION_MM_DEFAULT,
  COMMAND_TORQUE_NM_DEFAULT,
} from '../../constants';
import { type CommandKind, OnRobotScrewdriverCommandKind } from '../../types';

import type { ActuateScrewdriverArguments } from './Arguments';

export namespace ActuateScrewdriverStepDatabase {
  export type ActuationCommandKind = CommandKind;

  export const Arguments = zod.object({
    argumentKind: zod.literal('ActuateScrewdriver'),
    commandKind: OnRobotScrewdriverCommandKind.default(COMMAND_KIND_DEFAULT),
    targetTorque: zod.number().default(COMMAND_TORQUE_NM_DEFAULT), // STORED AS Nm (0.1-5) // 100 to 5000 Nmm
    targetForce: zod.number().default(COMMAND_FORCE_N_DEFAULT), // STORED AS N 18 to 30 N
    screwLength: zod.number().default(COMMAND_SCREW_LENGTH_MM_DEFAULT), // STORED AS MM (0-35) // 0 to 35000 muM
    shankPosition: zod.number().default(COMMAND_SHANK_POSITION_MM_DEFAULT), // 0 to 55mm
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const convertStepToRoutineRunnerArgs = (
    configuration: Arguments | void,
    stepID: string,
  ): ActuateScrewdriverArguments => {
    if (!configuration) {
      throw new Error(
        `'ActuateScrewdriver step is not configured. stepID: ${stepID}'`,
      );
    }

    return {
      gripperCommand: {
        kind: 'OnRobotScrewdriverCommand',
        commandKind: configuration.commandKind,
        targetForce: configuration.targetForce,
        screwLength: configuration.screwLength,
        targetTorque: configuration.targetTorque,
      },
    };
  };
}
