import type { CartesianPose } from '@sb/geometry';
import { isApproximatelyEqual } from '@sb/utilities';

const EPSILON = 0.0001;

const POSE_PROPS: Array<keyof CartesianPose> = [
  'i',
  'j',
  'k',
  'w',
  'x',
  'y',
  'z',
];

export function arePosesEqual(
  poseA: CartesianPose | null,
  poseB: CartesianPose | null,
): boolean {
  if (!poseA && !poseB) {
    return true;
  }

  if (!poseA || !poseB) {
    return false;
  }

  return POSE_PROPS.every((prop) =>
    isApproximatelyEqual(poseA[prop], poseB[prop], EPSILON),
  );
}
