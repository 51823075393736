import type { SafetySettings } from '@sb/remote-control/types';
import { six } from '@sb/utilities';

export function createNullSafetySettings(): SafetySettings.ConvertedResponse {
  return {
    factoryPreset: 'none',
    maxJointSpeeds: six(null),
    maxJointAccelerations: six(null),
    maxJointTorques: six(null),
    maxTooltipSpeed: null,
    slowJointAccelerations: six(null),
    slowJointTorques: six(null),
    slowJointSpeeds: six(null),
    slowTooltipSpeed: null,
    safeguardRules: [],
    collisionThresholds: six(null),
    accelerationThreshold: six(null),
    updatedAt: null,
    jerkLimit: null,
    tabletSafetyEnabled: false,
  };
}
