import { calculateOR2FG7StateFromCommand } from '../OnRobot2FG7';
import { calculateOR3FG15StateFromCommand } from '../OnRobot3FG15';

import type {
  OnRobotDualQuickChangerCommand,
  OnRobotDualQuickChangerState,
} from './types';

export function calculateORDualQuickChangerStateFromCommand(
  command: OnRobotDualQuickChangerCommand,
): Partial<OnRobotDualQuickChangerState> {
  return {
    kind: 'OnRobotDualQuickChanger',
    [command.active]:
      command.command.kind === 'OnRobot2FG7Command'
        ? calculateOR2FG7StateFromCommand(command.command)
        : calculateOR3FG15StateFromCommand(command.command),
  };
}
