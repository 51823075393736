import * as zod from 'zod';

import type { DeviceConfiguration } from '@sb/integrations/device';
import { getEndEffector } from '@sb/integrations/frontend/util';
import { OR3FG15GripKind } from '@sb/integrations/OnRobot3FG15';
import { OnRobotDualQuickChangerCommand } from '@sb/integrations/OnRobotDualQuickChanger/types';

import type { ActuateGripperArguments } from './Arguments';

const TOLERANCE_DEFAULT = 0.003; // the value which was used before the new config parameter was added
const WAIT_FOR_GRIP_DEFAULT = true;
const IS_FLEX_GRIP_DEFAULT = false;

export namespace ActuateGripperStepDatabase {
  export const Arguments = zod.object({
    argumentKind: zod.literal('ActuateGripper'),
    selectedGripper: zod
      .union([
        zod.literal('primary'),
        zod.literal('secondary'),
        zod.literal('none'),
      ])
      .default('primary'), // for dual gripper
    diameterMM: zod.number(),
    forceNewtons: zod.number(),
    gripKind: OR3FG15GripKind.default('inward'),
    payloadKg: zod.number().default(0),
    targetDiameterToleranceMeters: zod.number().default(TOLERANCE_DEFAULT),
    waitForGripToContinue: zod.boolean().default(WAIT_FOR_GRIP_DEFAULT),
    isFlexGrip: zod.boolean().default(IS_FLEX_GRIP_DEFAULT),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const convertActuateGripperConfigurationToRoutineRunner = (
    stepID: string,
    equipment: DeviceConfiguration[],
    configuration?: Arguments,
  ): ActuateGripperArguments => {
    if (!configuration) {
      throw new Error(
        `'ActuateGripper step is not configured. stepID: ${stepID}'`,
      );
    }

    const endEffector = getEndEffector(equipment);

    if (!endEffector) {
      throw new Error('Attach a gripper to utilize the Actuate Gripper step');
    }

    switch (endEffector.kind) {
      case 'OnRobot2FG7': {
        return {
          gripperCommand: {
            kind: 'OnRobot2FG7Command',
            gripKind: configuration.gripKind,
            targetForce: configuration.forceNewtons,
            targetDiameter: configuration.diameterMM / 1000,
            waitForGripToContinue: configuration.waitForGripToContinue,
            targetDiameterTolerance:
              configuration.targetDiameterToleranceMeters,
            // This is hard coded to a reasonable minimum, but could be added to FE
            // and wired through
            targetSpeed: 0.95,
          },
        };
      }

      case 'OnRobot3FG15': {
        return {
          gripperCommand: {
            kind: 'OnRobot3FG15Command',
            gripKind: configuration.gripKind,
            targetForce: configuration.forceNewtons,
            targetDiameter: configuration.diameterMM / 1000,
            targetDiameterTolerance:
              configuration.targetDiameterToleranceMeters,
            isFlexGrip: configuration.isFlexGrip,
            waitForGripToContinue: configuration.waitForGripToContinue,
          },
        };
      }

      case 'OnRobotDualQuickChanger': {
        if (configuration.selectedGripper === 'none') {
          throw new Error(`No gripper selected for step ${stepID}.`);
        }

        const { gripperCommand } =
          ActuateGripperStepDatabase.convertActuateGripperConfigurationToRoutineRunner(
            stepID,
            [endEffector.grippers[configuration.selectedGripper]],
            configuration,
          );

        const parsedSubCommand =
          OnRobotDualQuickChangerCommand.shape.command.safeParse(
            gripperCommand,
          );

        if (!parsedSubCommand.success) {
          throw new Error(
            `Sub-command given to Dual quick changer is not supported: ${parsedSubCommand.error.toString()}`,
          );
        }

        return {
          gripperCommand: {
            kind: 'OnRobotDualQuickChangerCommand',
            active: configuration.selectedGripper,
            command: parsedSubCommand.data,
          },
        };
      }
      default: {
        throw new Error(
          `Gripper ${endEffector.kind} is not supported for this step`,
        );
      }
    }
  };
}
