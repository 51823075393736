import { WaitStep } from '@sb/remote-control/types';
import type { WaitStepArguments } from '@sb/routine-runner';

import { buildConditional } from '../conditionalsBuilder';

export const convertWaitConfigurationToRoutineRunner = (
  stepID: string,
  configuration?: WaitStep.Arguments,
): WaitStepArguments => {
  if (!configuration) {
    throw new Error(`Wait step is not configured. stepID: ${stepID}`);
  }

  if (WaitStep.isConditionArguments(configuration)) {
    return {
      condition: buildConditional(JSON.parse(configuration.condition)),
    };
  }

  if (WaitStep.isMillisecondsArguments(configuration)) {
    return { milliseconds: configuration.milliseconds };
  }

  throw new Error(
    'Either milliseconds or condition must be provided to a Wait step',
  );
};
