import { pick } from 'lodash';

import type { Step } from '@sb/remote-control/types';

export const getStepSummary = (
  steps: Step.ConvertedSummary[],
): Step.Summary[] => {
  const fields: Array<keyof Step.Summary> = ['id', 'stepKind', 'description'];

  const mapStepFields = (substeps: Step.ConvertedSummary[]): Step.Summary[] => {
    return substeps.map((step) => {
      return { ...pick(step, fields), steps: mapStepFields(step.steps || []) };
    });
  };

  return mapStepFields(steps);
};
