import * as zod from 'zod';

import { getIntegrationTypes } from '@sb/integrations/register';

import AnticipatePayloadVariables from './AnticipatePayload/Variables';
import ClassifyVariables from './Classify/Variables';
import IfVariables from './If/Variables';
import LocateVariables from './Locate/Variables';
import LoopVariables from './Loop/Variables';
import LoopControlVariables from './LoopControl/Variables';
import MoveArmToVariables from './MoveArmTo/Variables';
import NetworkRequestVariables from './NetworkRequest/Variables';
import PressButtonVariables from './PressButton/Variables';
import SetEnvironmentVariableVariables from './SetEnvironmentVariable/Variables';
import SetIOVariables from './SetIO/Variables';
import type { StepKind } from './StepKind';
import WaitVariables from './Wait/Variables';
import WaitForConfirmationVariables from './WaitForConfirmation/Variables';

/**
 * An untagged union of routine step variable state.
 *
 * Any possible state data for a variable.
 */
export const RoutineStepVariables = zod.union([
  AnticipatePayloadVariables,
  ClassifyVariables,
  LocateVariables,
  IfVariables,
  LoopVariables,
  LoopControlVariables,
  MoveArmToVariables,
  NetworkRequestVariables,
  PressButtonVariables,
  SetEnvironmentVariableVariables,
  SetIOVariables,
  WaitForConfirmationVariables,
  WaitVariables,
  ...getIntegrationTypes().map(({ Variables }) => Variables),
]);

export type RoutineStepVariables = zod.infer<typeof RoutineStepVariables>;

function tag<V, S extends StepKind>(schema: zod.Schema<V>, stepKind: S) {
  return schema.and(
    zod.object({
      stepKind: zod.literal<S>(stepKind),
    }),
  );
}

/**
 * A tagged union of routine step variable state.
 *
 * Any possible state data for a variable, tagged with `{ kind: StepKind }`.
 */
export const TaggedRoutineStepVariables = zod.union([
  tag(AnticipatePayloadVariables, 'AnticipatePayload'),
  tag(ClassifyVariables, 'Classify'),
  tag(LocateVariables, 'Locate'),
  tag(IfVariables, 'If'),
  tag(LoopVariables, 'Loop'),
  tag(LoopControlVariables, 'LoopControl'),
  tag(MoveArmToVariables, 'MoveArmTo'),
  tag(PressButtonVariables, 'PressButton'),
  tag(SetEnvironmentVariableVariables, 'SetEnvironmentVariable'),
  tag(SetIOVariables, 'SetIO'),
  tag(WaitForConfirmationVariables, 'WaitForConfirmation'),
  tag(WaitVariables, 'Wait'),
  zod.object({
    stepKind: zod.literal('NetworkRequest'),
    value: zod.any(),
  }),
  zod.object({
    stepKind: zod.literal('EnvironmentVariable'),
    value: zod.any(),
  }),
  ...getIntegrationTypes().map(({ stepKind, Variables }) =>
    tag(Variables, stepKind),
  ),
]);

export type TaggedRoutineStepVariables = zod.infer<
  typeof TaggedRoutineStepVariables
>;
export type AnyVariables = RoutineStepVariables;
