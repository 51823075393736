import type { OnRobot3FG15Command, OnRobot3FG15State } from '../types';

export function calculateOR3FG15StateFromCommand(
  command: OnRobot3FG15Command,
): Partial<OnRobot3FG15State> {
  return {
    kind: 'OnRobot3FG15',
    gripKind: command.gripKind,
    targetForceNewtons: command.targetForce,
    diameterMeters: command.targetDiameter,
  };
}
