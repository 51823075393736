import type { Step, Routine } from '@sb/remote-control/types';

const checkBothGrippersDefines = (
  _step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
): Routine.ActionRequiredStep | undefined => {
  if (stepConfiguration?.stepKind !== 'ActuateGripper') return undefined;
  const { args } = stepConfiguration;
  if (!args) return undefined;

  if (args.selectedGripper === 'none') {
    return {
      kind: 'invalidConfiguration',
      message: 'Select a gripper',
      fieldId: 'selectedGripper',
    };
  }

  return undefined;
};

export const actuateGripperValidators = [checkBothGrippersDefines];
