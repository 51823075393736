import { useEffect, useState } from 'react';

import { makeNamespacedLog } from '@sb/log';
import { StandardBotsSDK } from '@sb/sb-robot-sdk';
import { loadApiAuthToken } from '@sbrc/services/feathers-client';
import { HTTP_API_ENDPOINT, globalCache } from '@sbrc/utils';

const log = makeNamespacedLog('useStandardBotsSdk');

type CombinedSdks = {
  live: StandardBotsSDK | null;
  simulated: StandardBotsSDK | null;
};

type SdkHandler = (sdks: CombinedSdks) => void;

const sdkSubscriber = globalCache('standardBotsSDK.subscriber', () => {
  const sdks: CombinedSdks = {
    live: null,
    simulated: null,
  };

  let updateHandlers: SdkHandler[] = [];

  const updateToken = () => {
    loadApiAuthToken()
      .then((payload) => {
        if (!payload) {
          throw new Error('No token');
        }

        const { expiry, token } = payload;

        sdks.live = new StandardBotsSDK({
          url: HTTP_API_ENDPOINT,
          token,
          robotKind: 'live',
        });

        sdks.simulated = new StandardBotsSDK({
          url: HTTP_API_ENDPOINT,
          token,
          robotKind: 'live',
        });

        updateHandlers.forEach((handler) => handler(sdks));

        // refresh half way through expiry period
        setTimeout(updateToken, (expiry - Date.now()) / 2);
      })
      .catch((e) => {
        log.warn('token.load.fail', 'Failed to load token', e);
        setTimeout(updateToken, 500);
      });
  };

  updateToken();

  const onUpdate = (handler: SdkHandler): (() => void) => {
    updateHandlers.push(handler);
    handler(sdks);

    return () => {
      updateHandlers = updateHandlers.filter((h) => h !== handler);
    };
  };

  return {
    sdks,
    onUpdate,
  };
});

export function useStandardBotsSdk(isVizbot: boolean): StandardBotsSDK | null {
  const [sdk, setSdk] = useState<StandardBotsSDK | null>(null);

  useEffect(() => {
    sdkSubscriber.onUpdate((sdks) => {
      setSdk(isVizbot ? sdks.simulated : sdks.live);
    });
  }, [isVizbot, setSdk]);

  return sdk;
}
