import type { RoutineVariable } from '@sb/remote-control/types';

export const getVariableDisplayNameFromRoutineState = (
  currentVariable: RoutineVariable.Information,
  variableState?: RoutineVariable.FlexibleRoutineStepVariables,
): string => {
  const value = variableState?.[currentVariable.variableName];

  if (
    currentVariable.variableKind !== 'boolean' &&
    currentVariable.variableKind !== 'enum'
  ) {
    return String(value || 'N/A');
  }

  // Get the display name
  const variableValue = (
    currentVariable.values as RoutineVariable.EnumValue[]
  ).find((variable) => {
    return variable.value === value;
  });

  return variableValue?.displayName ?? 'N/A';
};
