import cx from 'classnames';
import { Children, useState } from 'react';

import { isTabItemElement } from './TabItem';

interface TabsProps {
  children: React.ReactNode;
  defaultTab?: string;
  tabListClassName?: string;
  tabPanelContainerClassName?: string;
  onChange?: (id: string) => void;
  selected?: string;
  fillElevated?: boolean;
}

let uniqueCounter = 0;

export function Tabs({
  children,
  defaultTab,
  tabListClassName,
  tabPanelContainerClassName,
  onChange,
  selected,
  fillElevated,
}: TabsProps) {
  const [uniqueID] = useState(() => {
    uniqueCounter += 1;

    return uniqueCounter;
  });

  const items = Children.toArray(children).filter(isTabItemElement);

  const [uncontrolledSelectedID, setUncontrolledSelectedID] = useState<
    string | undefined
  >(() => {
    if (defaultTab && items.some((i) => i.props.id === defaultTab)) {
      return defaultTab;
    }

    return items[0]?.props.id;
  });

  const selectedID = selected ?? uncontrolledSelectedID;

  return (
    <>
      <div className={cx('tw-flex', tabListClassName)} role="tablist">
        {items.map(({ props }) => {
          return (
            <button
              key={props.id}
              type="button"
              id={`tab-${uniqueID}-${props.id}`}
              className={cx(
                'tw-button-32',
                'tw-px-16',
                'tw-font-medium',
                'disabled:tw-cursor-not-allowed',
                props.id === selectedID &&
                  !fillElevated &&
                  'tw-bg-fill-primary',
                props.id === selectedID &&
                  fillElevated &&
                  'tw-surface-elevated',
              )}
              disabled={props.isDisabled}
              role="tab"
              aria-selected={props.id === selectedID}
              aria-controls={`panel-${uniqueID}-${props.id}`}
              onClick={() => {
                onChange?.(props.id);
                setUncontrolledSelectedID(props.id);
              }}
            >
              <span
                className={cx(
                  'tw-font-mikro',
                  'tw-text-label-secondary',
                  props.id === selectedID && 'tw-text-orange',
                )}
              >
                {props.label}
              </span>
            </button>
          );
        })}
      </div>
      {items.map(({ props }) => {
        return (
          <div
            key={props.id}
            id={`panel-${uniqueID}-${props.id}`}
            className={tabPanelContainerClassName}
            role="tabpanel"
            aria-labelledby={`tab-${uniqueID}-${props.id}`}
            hidden={props.id !== selectedID}
          >
            {props.panel}
          </div>
        );
      })}
    </>
  );
}
