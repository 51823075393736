import { OR_3FG15_SOURCE_OFFSET_METERS } from '../constants';
import type { OnRobot3FG15Command } from '../types';

/**
 * Calculates the diameter in meters of an OR3FG15 when in the state
 * described by the parameters.
 */
export function calculateOR3FG15Diameter({
  rawDiameterMeters,
  fingertipDiameterMeters,
  gripKind,
}: {
  rawDiameterMeters: number;
  fingertipDiameterMeters: number;
  gripKind: OnRobot3FG15Command['gripKind'];
}): number {
  const rawMillis = rawDiameterMeters * 1_000;

  const modifier =
    fingertipDiameterMeters * 1_000 + OR_3FG15_SOURCE_OFFSET_METERS;

  return (
    (gripKind === 'inward' ? rawMillis - modifier : rawMillis + modifier) /
    1_000
  );
}
