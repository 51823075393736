import type { User } from '@sb/remote-control/types';

import { getCurrentUserID } from '../auth';

import { getUsersService } from './utils';

export const createUser = async (data: User.Create): Promise<string> => {
  const response = await getUsersService().create({
    email: data.email,
    invitedBy: getCurrentUserID(),
    isUserVerified: true,
    isProfileSetup: true,
    name: data.name,
    hasAccountPermission: true,
    hasControlPanelPermission: data.isControlPanelUser,
    hasConnectToRobotPermission: true,
  });

  return response.id;
};
