import type { AnticipatePayloadStep } from '@sb/remote-control/types';
import type { AnticipatePayloadStepArguments } from '@sb/routine-runner';

export const convertAnticipatePayloadConfigurationToRoutineRunner = (
  stepID: string,
  configuration?: AnticipatePayloadStep.Arguments,
): AnticipatePayloadStepArguments => {
  if (!configuration) {
    throw new Error(
      `AnticipatePayload step is not configured. stepID: ${stepID}`,
    );
  }

  const { mass } = configuration;

  return { payload: { mass } };
};
