import * as zod from 'zod';

import { CameraIntegration } from '@sb/integrations/camera/types';

import { RegionOfInterest } from '../../vision/VisionInterface';

export default zod.object({
  classes: zod.array(
    zod.object({ name: zod.string(), storageID: zod.string() }),
  ),
  fallbackValue: zod.string(),
  regionOfInterest: RegionOfInterest,
  confidence: zod.number(),
  camera: CameraIntegration,
});
