import type { Step } from '@sb/remote-control/types';

import { patchBuilder } from '../utils';

import { getRoutinesService } from './utils';

interface StepConfigurationUpdateArguments {
  description?: string;
  mainLoopStepID?: string | null;
  routineID: string;
  stepArgs?: Partial<Step.Arguments>;
  stepID: string;
  isFullyConfigured?: boolean;
}

/**
 * A routine document stores the steps configuration
 * in the `stepConfigurations` field.
 *
 * This function updates the step configuration for a single step
 * while preserving the configuration for other steps.
 */
export async function updateRoutineStepConfiguration({
  description,
  mainLoopStepID,
  routineID,
  stepArgs,
  stepID,
  isFullyConfigured,
}: StepConfigurationUpdateArguments): Promise<void> {
  const changes = patchBuilder();

  if (isFullyConfigured) {
    // After this step is configured, then it doesn't require an update anymore.
    changes.unset(`actionRequiredByStepID.${stepID}`);
  }

  if (stepArgs) {
    // will be set to current time server side
    changes.set('configurationUpdatedAt', 'now');

    // Partially update keys to avoid overriding missing key-value pairs.
    Object.entries(stepArgs).forEach(([key, value]) => {
      if (value === undefined) {
        changes.unset(`stepConfigurations.${stepID}.args.${key}`);
      } else {
        changes.set(`stepConfigurations.${stepID}.args.${key}`, value);
      }
    });
  }

  if (description !== undefined) {
    if (description) {
      changes.set(`stepConfigurations.${stepID}.description`, description);
    } else {
      changes.unset(`stepConfigurations.${stepID}.description`);
    }
  }

  // Update the mainLoop configuration when available.
  if (mainLoopStepID !== undefined) {
    changes.set('mainLoopStepID', mainLoopStepID);
  }

  await getRoutinesService().patch(routineID, changes.buildPatch());
}
