import type { ClassifyStep } from '@sb/remote-control/types';
import type { ClassifyStepArguments } from '@sb/routine-runner';

export const convertClassifyConfigurationToRoutineRunner = (
  stepID: string,
  configuration?: ClassifyStep.Arguments,
): ClassifyStepArguments => {
  if (!configuration) {
    throw new Error(`Classify step is not configured. stepID: ${stepID}`);
  }

  if (!configuration.fallbackValue) {
    throw new Error(`Classify step requires a fallback value: ${stepID}`);
  }

  // TODO: Add support for other camera types and method
  return {
    // camera: configuration.camera,
    regionOfInterest: configuration.regionOfInterest,
    // method: configuration.method,
    classes: configuration.classes,
    confidence: configuration.confidence,
    camera: configuration.camera,
    fallbackValue: configuration.fallbackValue,
  };
};
