import type { GripperRange } from '../gripper-general';

import type { OR2FG7GripKind } from './constants';
import {
  OR_2FG7_GRIP_KINDS_DEFAULT,
  OUTWARD_MOUNT_INWARD_GRIP_WIDTH_RANGE_METERS,
  OUTWARD_MOUNT_OUTWARD_GRIP_WIDTH_RANGE_METERS,
} from './constants';

interface DefaultWidthRangeCalcProps {
  gripKind?: OR2FG7GripKind;
}

// Defaults to most common usecase: outward mount + 7mm fingertip offset
export function calculateOR2FG7DefaultWidthRangeMeters({
  gripKind = OR_2FG7_GRIP_KINDS_DEFAULT,
}: DefaultWidthRangeCalcProps): GripperRange {
  return gripKind === 'inward'
    ? OUTWARD_MOUNT_INWARD_GRIP_WIDTH_RANGE_METERS
    : OUTWARD_MOUNT_OUTWARD_GRIP_WIDTH_RANGE_METERS;
}
