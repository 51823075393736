import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function EthernetIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 14" {...props}>
      <path d="M5.7002 1.90002C5.97634 1.90002 6.2002 2.12388 6.2002 2.40002V3.90002C6.2002 4.17617 5.97634 4.40002 5.7002 4.40002C5.42405 4.40002 5.2002 4.17617 5.2002 3.90002V2.40002C5.2002 2.12388 5.42405 1.90002 5.7002 1.90002Z" />
      <path d="M8.80029 2.40002C8.80029 2.12388 8.57644 1.90002 8.30029 1.90002C8.02415 1.90002 7.80029 2.12388 7.80029 2.40002V3.90002C7.80029 4.17617 8.02415 4.40002 8.30029 4.40002C8.57644 4.40002 8.80029 4.17617 8.80029 3.90002V2.40002Z" />
      <path d="M10.9004 1.90002C11.1765 1.90002 11.4004 2.12388 11.4004 2.40002V3.90002C11.4004 4.17617 11.1765 4.40002 10.9004 4.40002C10.6242 4.40002 10.4004 4.17617 10.4004 3.90002V2.40002C10.4004 2.12388 10.6242 1.90002 10.9004 1.90002Z" />
      <path d="M3.6001 2.40002C3.6001 2.12388 3.37624 1.90002 3.1001 1.90002C2.82396 1.90002 2.6001 2.12388 2.6001 2.40002V3.90002C2.6001 4.17617 2.82396 4.40002 3.1001 4.40002C3.37624 4.40002 3.6001 4.17617 3.6001 3.90002V2.40002Z" />
      <path d="M0.5 10C0.223858 10 0 9.77614 0 9.5V0.5C0 0.223858 0.223858 0 0.5 0H13.5C13.7761 0 14 0.223858 14 0.5V9.5C14 9.77614 13.7761 10 13.5 10H11V13C11 13.2761 10.7761 13.5 10.5 13.5H3.5C3.22386 13.5 3 13.2761 3 13V10H0.5ZM4 9V12.5H10V9H13V1H1V9H4Z" />
    </Icon>
  );
}
