import cx from 'classnames';
import { useId } from 'react';

import { Select } from '../form';
import type { SelectProps } from '../form';

import { SettingsGroupItem } from './SettingsGroupItem';

export interface SettingsGroupSelectItemProps extends SelectProps {
  label?: React.ReactNode;
  secondaryLabel?: React.ReactNode;
  isSeparated?: boolean;
}

export function SettingsGroupSelectItem({
  label,
  secondaryLabel,
  className,
  isSeparated,
  ...rest
}: SettingsGroupSelectItemProps) {
  const id = useId();

  return (
    <SettingsGroupItem className={className} isSeparated={isSeparated}>
      <label className="tw-flex-auto tw-shrink-0" htmlFor={id}>
        {label}
      </label>

      {secondaryLabel && (
        <span className="tw-text-label-secondary">{secondaryLabel}</span>
      )}

      <Select
        id={id}
        variant="Flat"
        size={36}
        className={cx(
          'tw-flex-auto',
          '[&>select]:tw-text-right',
          // needed in Safari
          '[&>select]:[text-align-last:right]',
          'tw-text-label-secondary',
        )}
        {...rest}
      />
    </SettingsGroupItem>
  );
}
