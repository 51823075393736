import * as zod from 'zod';

import type { CustomGripper } from './CustomGripper/implementation/CustomGripper';
import { CustomGripperConfiguration } from './CustomGripper/types/CustomGripperConfiguration';
import { CustomIOConfiguration } from './CustomIO/types/CustomIOConfiguration';
import type { EwellixLiftTLT } from './EwellixLiftTLT/implementation';
import {
  EwellixLiftTLTCommand,
  EwellixLiftTLTConfiguration,
  EwellixLiftTLTState,
} from './EwellixLiftTLT/types';
import { GripperStateNonNull } from './gripper-general';
import { InvalidConfiguration } from './InvalidConfiguration/types';
import { ModbusTCPServerCommand } from './ModbusTCPServer/command';
import type { ModbusTCPServer } from './ModbusTCPServer/implementation/ModbusTCPServer';
import { ModbusTCPServerConfiguration } from './ModbusTCPServer/types';
import { NoGripperConfiguration } from './NoGripper/types';
import type { OnRobot2FG7 } from './OnRobot2FG7/implementation/OnRobot2FG7';
import {
  OnRobot2FG7Command,
  OnRobot2FG7Configuration,
} from './OnRobot2FG7/types';
import type { OnRobot3FG15 } from './OnRobot3FG15/implementation/OnRobot3FG15';
import {
  OnRobot3FG15Command,
  OnRobot3FG15Configuration,
} from './OnRobot3FG15/types';
import type { OnRobotDualQuickChanger } from './OnRobotDualQuickChanger/implementation/OnRobotDualQuickChanger';
import {
  OnRobotDualQuickChangerCommand,
  OnRobotDualQuickChangerConfiguration,
} from './OnRobotDualQuickChanger/types';
import type { OnRobotScrewdriver } from './OnRobotScrewdriver/implementation/OnRobotScrewdriver';
import {
  OnRobotScrewdriverCommand,
  OnRobotScrewdriverConfiguration,
} from './OnRobotScrewdriver/types';
import type { OnRobotVGP20 } from './OnRobotVGP20/implementation/OnRobotVGP20';
import {
  OnRobotVGP20Command,
  OnRobotVGP20Configuration,
} from './OnRobotVGP20/types';
import type { WristCamera } from './WristCamera/implementation/WristCamera';
import { WristCameraConfiguration } from './WristCamera/types';

export const DeviceCommand = zod.discriminatedUnion('kind', [
  OnRobot3FG15Command,
  OnRobot2FG7Command,
  OnRobotDualQuickChangerCommand,
  OnRobotScrewdriverCommand,
  OnRobotVGP20Command,
  EwellixLiftTLTCommand,
  ModbusTCPServerCommand,
]);

export type DeviceCommand = zod.infer<typeof DeviceCommand>;

export const DeviceConfiguration = zod.discriminatedUnion('kind', [
  OnRobotDualQuickChangerConfiguration,
  OnRobotScrewdriverConfiguration,
  OnRobotVGP20Configuration,
  OnRobot2FG7Configuration,
  OnRobot3FG15Configuration,
  CustomGripperConfiguration,
  EwellixLiftTLTConfiguration,
  NoGripperConfiguration,
  CustomIOConfiguration,
  InvalidConfiguration,
  ModbusTCPServerConfiguration,
  WristCameraConfiguration,
]);

export type DeviceConfiguration = zod.infer<typeof DeviceConfiguration>;

// typecheck: this ensures all Configuration types have a non-optional `name` property.
// ts will error if `name` is optional or missing:
// "Type 'string | undefined' is not assignable to type 'string'. ts(2322)"
((name: DeviceConfiguration['name']): string => name)('');

// only one right now but may become a union in the future
export const DynamicBaseState = EwellixLiftTLTState;
export type DynamicBaseState = zod.infer<typeof DynamicBaseState>;

export const DeviceState = zod.union([GripperStateNonNull, DynamicBaseState]);
export type DeviceState = zod.infer<typeof DeviceState>;

export type DeviceKind = DeviceConfiguration['kind'];

export type AnyDevice =
  | EwellixLiftTLT
  | OnRobot2FG7
  | OnRobot3FG15
  | OnRobotDualQuickChanger
  | OnRobotScrewdriver
  | OnRobotVGP20
  | CustomGripper
  | ModbusTCPServer
  | WristCamera;

export type NetworkDeviceRequest = ModbusTCPServer;
export type NetworkRequestCommand = ModbusTCPServerCommand;
