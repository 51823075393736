import type { StepValidator } from '../types';

export const locateValidator: StepValidator = (
  _step,
  stepConfiguration,
  { space },
) => {
  const args = stepConfiguration?.args;

  if (args?.argumentKind !== 'Locate') {
    return undefined;
  }

  if (!args.planeID) {
    return {
      kind: 'invalidConfiguration',
      message: 'No workplane selected',
    };
  }

  if (
    !space.some((item) => item.id === args.planeID && item.kind === 'plane')
  ) {
    return {
      kind: 'invalidConfiguration',
      message: 'Selected workplane is not valid',
    };
  }

  return undefined;
};
