import type { SafetySettingsData } from '@sb/feathers-types';
import { JOINT_NUMBERS } from '@sb/motion-planning';
import type { SafetySettings } from '@sb/remote-control/types';

import { getSafetySettingsService } from './utils';

export async function updateSafetySettings(
  robotID: string,
  safetySettings: SafetySettings.ConvertedResponse,
): Promise<void> {
  const dbSettings: Omit<SafetySettingsData, 'id' | 'updatedAt'> = {
    robotID,
    factoryPreset: safetySettings.factoryPreset,
    maxJointSpeeds: [],
    maxJointAccelerations: [],
    maxJointTorques: [],
    maxTooltipSpeed: safetySettings.maxTooltipSpeed,
    slowJointSpeeds: [],
    slowJointAccelerations: [],
    slowJointTorques: [],
    slowTooltipSpeed: safetySettings.slowTooltipSpeed,
    safeguardRules: safetySettings.safeguardRules.filter(
      (rule) => rule.kind !== 'none',
    ),
    collisionThresholds: [],
    accelerationThreshold: [],
    jerkLimit: safetySettings.jerkLimit,
    tabletSafetyEnabled: safetySettings.tabletSafetyEnabled,
  };

  for (const j of JOINT_NUMBERS) {
    dbSettings.maxJointSpeeds[j] = safetySettings.maxJointSpeeds[j];

    dbSettings.maxJointAccelerations[j] =
      safetySettings.maxJointAccelerations[j];

    dbSettings.maxJointTorques[j] = safetySettings.maxJointTorques[j];

    dbSettings.slowJointSpeeds[j] = safetySettings.slowJointSpeeds[j];

    dbSettings.slowJointAccelerations[j] =
      safetySettings.slowJointAccelerations[j];

    dbSettings.slowJointTorques[j] = safetySettings.slowJointTorques[j];

    dbSettings.collisionThresholds[j] = safetySettings.collisionThresholds[j];

    dbSettings.accelerationThreshold[j] =
      safetySettings.accelerationThreshold[j];
  }

  // create method runs create-or-patch in safetySettings service
  await getSafetySettingsService().create(dbSettings);
}
