import cx from 'classnames';

import type { ComponentColorOrDefault, ComponentSize } from '../../utility';
import { useComponentColorClassName } from '../../utility';
import { BUTTON_SIZE_CLASSES } from '../button/buttonSize';

import { BADGE_COLOR_CLASSES } from './badgeColor';

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: ComponentSize;
  color?: ComponentColorOrDefault;
}

export function Badge({ size = 32, color, className, ...rest }: BadgeProps) {
  const colorClassName = useComponentColorClassName(color, BADGE_COLOR_CLASSES);

  return (
    <div
      className={cx(className, BUTTON_SIZE_CLASSES[size], colorClassName)}
      {...rest}
    />
  );
}
