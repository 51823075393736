import { v4 as uuid } from 'uuid';

import type { Step } from '@sb/remote-control/types';

import { STEP_NAME_BY_STEP_KIND } from './constants';

interface ConvertStepSummaryOptions {
  /**
   * In some cases we need to generate a new stepID instead of using the
   * current one (i.e. when using a routine template).
   */
  shouldReplaceStepID?: boolean;
}

export const convertStepSummary = (
  steps: Step.Summary[],
  options?: ConvertStepSummaryOptions,
): Step.ConvertedSummary[] => {
  let stepPosition = 0;

  const mapSteps = (
    substeps: Step.Summary[],
    parentSteps: Step.ParentStep[],
  ): Step.ConvertedSummary[] => {
    return substeps.map((step) => {
      stepPosition += 1;

      const stepID = options?.shouldReplaceStepID ? uuid() : step.id;

      /**
       * Set the current step as the parent one when calling
       * the recursive `mapSteps` function.
       */
      const parentStepSummary: Step.ParentStep = {
        id: stepID,
        stepKind: step.stepKind,
      };

      return {
        description: step.description,
        id: stepID,
        name: STEP_NAME_BY_STEP_KIND[step.stepKind],
        parentSteps,
        stepKind: step.stepKind,
        stepPosition,
        steps: mapSteps(step.steps ?? [], [...parentSteps, parentStepSummary]),
      };
    });
  };

  return mapSteps(steps, []);
};
