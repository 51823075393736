import { format } from './format';
import { Severity, allSeverities, testSeverities } from './types';

// if running in test mode (under jest), only log errors and warnings
let logSeverities = new Set(
  process.env.JEST_WORKER_ID === undefined ? allSeverities : testSeverities,
);

export const setLogSeverities = (severities: readonly Severity[]) => {
  logSeverities = new Set(severities);
};

/**
 * Create a namespaced key for logging
 * Usage:
 *  const ns = namespace('updaterbot.install');
 *  info(ns`daemon.down`, 'Stopping daemon', { services: [...] });
 *  ...
 *  info(ns`daemon.up`, 'Starting daemon', { services: [...] });
 */
export const namespace =
  (ns: string) =>
  (literals: string | readonly string[], ..._args: any[]) => {
    if (literals.length !== 1) {
      throw new Error(
        'namespace can only be used with a single string literal',
      );
    }

    if (typeof literals[0] !== 'string') {
      throw new Error(
        'namespace can only be used with a single string literal',
      );
    }

    return `${ns}.${literals[0]}`;
  };

export const log = (
  severity: Severity,
  key: string,
  message: string,
  context: any,
) => {
  if (!logSeverities.has(severity)) {
    return;
  }

  const formatted = format.format(severity, key, message, context);
  const formatArray = Array.isArray(formatted) ? formatted : [formatted];
  // eslint-disable-next-line no-console
  console.log(...formatArray);
};

const makeSeverityLogger = (severity: Severity, namespaceString?: string) => {
  const logNs = namespaceString ? `${namespaceString}.` : '';

  return (key: string, message: string, context?: any) =>
    log(severity, `${logNs}${key}`, message, context);
};

/** Log error that is fatal to the operation, or the serivce/application. */
export const error = makeSeverityLogger(Severity.Error);
/** Log issue that can potentially cause application issues, but for which we automatically recover. */
export const warn = makeSeverityLogger(Severity.Warning);
/** Log generally useful information to log (service start/stop, configuration assumptions, etc). Generally used to understand normal operations while diagnosing an issue, or gathering metrics. */
export const info = makeSeverityLogger(Severity.Information);
/** Log diagnostic data. */
export const debug = makeSeverityLogger(Severity.Debug);

export const makeLogNamespace = (logNamespace: string) => ({
  error: makeSeverityLogger(Severity.Error, logNamespace),
  warn: makeSeverityLogger(Severity.Warning, logNamespace),
  info: makeSeverityLogger(Severity.Information, logNamespace),
  debug: makeSeverityLogger(Severity.Debug, logNamespace),
});
