import { getService } from '../utils';

export async function requestSoftwareInstall(joints: number[]): Promise<void> {
  const dataService = getService('softwareUpdate')();

  await dataService.create({
    isSoftwareInstallRequested: true,
    requestFirmwareInstallData: {
      joints,
    },
  });
}
