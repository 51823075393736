import { getService } from '../utils';

import { blobToDataURL } from './blobToDataURL';

interface UploadFileToStorageArguments {
  file: Blob;
}

interface UploadFileToStorageResponse {
  id: string;
  downloadURL: string;
}

export async function uploadFileToStorage({
  file,
}: UploadFileToStorageArguments): Promise<UploadFileToStorageResponse> {
  const storageService = getService('storage')();

  storageService.timeout = 30_000;

  const uri = await blobToDataURL(file);
  const result = await storageService.create({ uri }, {});
  const id = result._id;

  return {
    id,
    downloadURL: `/storage/${id}`,
  };
}
