import type { Equipment } from '@sb/remote-control/types';

import { getEquipmentService } from './utils';

export async function updateEquipment(
  id: string,
  equipmentItem: Equipment.EditableFields,
): Promise<void> {
  await getEquipmentService().patch(id, equipmentItem);
}
