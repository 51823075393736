import type { Step } from '@sb/remote-control/types';

/**
 * Convert the step configurations to the db format
 */
export function reverseConvertStepConfigurations(
  stepConfigurations: Record<string, Step.ConvertedConfiguration>,
): Record<string, Step.Configuration> {
  const result: Record<string, Step.Configuration> = {};

  for (const [id, value] of Object.entries(stepConfigurations)) {
    result[id] = {
      args: value.args,
      description: value.description,
    };
  }

  return result;
}
