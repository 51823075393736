import { Select } from '../form';
import type { SelectProps } from '../form';

import { SettingsGroupItem } from './SettingsGroupItem';

export interface SettingsGroupFullWidthSelectItemProps extends SelectProps {
  isSeparated?: boolean;
}

export function SettingsGroupFullWidthSelectItem({
  className,
  isSeparated,
  ...rest
}: SettingsGroupFullWidthSelectItemProps) {
  return (
    <SettingsGroupItem className={className} isSeparated={isSeparated}>
      <Select variant="Flat" size={36} className="tw-flex-1" {...rest} />
    </SettingsGroupItem>
  );
}
